import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {MiniAvatar} from '../components/ModularComponents';

const NotificationsItem = ({originUsername,notificationsText}) => {
	return  <div className="d-flex align-items-center p-3" style={{color:'#033b39',backgroundColor:'#f0fbfa',marginBottom:1}}>
				{originUsername!=='admin'&&<MiniAvatar />}
				<div>
					{originUsername!=='admin'&&<span style={{fontWeight:'bold',marginLeft:4,marginRight:4}}>{originUsername}</span>}
					<span>{notificationsText}</span>
				</div>
			</div>
}

export const Notifications = () => {
	const notifications = [{originUsername:'julie',notificationsText:'sent a connection request!'},
						   {originUsername:'admin',notificationsText:'Please, remember to verify your email!'}]

	return <Container className="pt-3">
				<Card style={{marginBottom:10}}>
	                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
	                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Notifications</Card.Text>
	                </Card.Header>
	                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:12,color:'#0AC5BF'}}>
	                    {notifications.map((values,index) => {
	                    	return <NotificationsItem {...values}/>
	                    })}
	                </Card.Body>
	            </Card>
			</Container>
}