import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import {Container,Stack,Card,Button,Row,Col,Image} from 'react-bootstrap';
import {useNavigate,useLocation,Outlet} from 'react-router-dom';
import {SubGroupCard} from '../components/ModularComponents';
import recreation from '../assets/recreation.jpg';
import sexuality from '../assets/sexuality.jpg';
import lifestyle from '../assets/lifestyle.jpg';
import eroticism from '../assets/eroticism.jpg';
import podcastsImage from '../assets/podcastsImage.png';
import charity from '../assets/charity.png';
import hobbiesAndCrafts from '../assets/hobbiesAndCrafts.png';
import travel from '../assets/travel.png';
import technology from '../assets/technology.png';
import sports from '../assets/sports.png';
import petsAndAnimals from '../assets/petsAndAnimals.png';
import outdoorsAndAdventures from '../assets/outdoorsAndAdventures.png';
import music from '../assets/music.png';
import newAge from '../assets/newAge.png';
import moviesComediesFilmsPlays from '../assets/moviesComediesFilmsPlays.png';
import healthAndWellbeing from '../assets/healthAndWellbeing.png';
import gaming from '../assets/gaming.png';
import games from '../assets/games.png';
import foodAndDrinks from '../assets/foodAndDrinks.png';
import fitness from '../assets/fitness.png';
import fashion from '../assets/fashion.png';
import environmentAndSustainability from '../assets/environmentAndSustainability.png';
import dance from '../assets/dance.png';
import bookClub from '../assets/bookClub.png';
import artAndCulture from '../assets/artAndCulture.png';
import anime from '../assets/anime.png';
import '../css/groups.css';

export const Groups = () => {
	const location = useLocation();
	const navigate = useNavigate();
    const { pathname } = location;
	const [switchValue, setSwitchValue] = useState(false);
	const themes = ['Reaction', 'Sexuality', 'Lifestyle', 'Eroticism'];
	const [privacyStatus, setPrivacyStatus] = useState('Public');

	useEffect(() => {
		if(pathname === '/app/groups'){
			setPrivacyStatus('Public');
			setSwitchValue(false);
		}else if(pathname === '/app/groups/sexuality'){
			setPrivacyStatus('Public');
			setSwitchValue(false);
		}else if(pathname === '/app/groups/lifestyle'){
			setPrivacyStatus('Public');
			setSwitchValue(false);
		}else if(pathname === '/app/groups/eroticism'){
			setPrivacyStatus('Private');
			setSwitchValue(true);
		}
	},[pathname, setPrivacyStatus, setSwitchValue])
	

	return 	<Container>
				<Row className="rounded-3 align-items-center mt-2" style={{color:'#0AC5BF', border:'1px solid #0ac5bf',overflow:'hidden'}}>
					<Col onClick={() => navigate('/app/groups')} xs={3} className="groups-tab" style={{backgroundColor:((pathname === '/app/groups')?'#0AC5BF':'transparent')}}>
						<div style={{color:((pathname === '/app/groups')?'white':'')}}>Recreation</div>
					</Col>
					<Col onClick={() => navigate('/app/groups/sexuality')} xs={3} className="groups-tab" style={{backgroundColor:(pathname === '/app/groups/sexuality'?'#0AC5BF':'transparent')}}>
						<div style={{color:((pathname === '/app/groups/sexuality')?'white':'')}}>Sexuality</div>
					</Col>
					<Col onClick={() => navigate('/app/groups/lifestyle')} xs={3} className="groups-tab" style={{backgroundColor:(pathname === '/app/groups/lifestyle'?'#0AC5BF':'transparent')}}>
						<div style={{color:((pathname === '/app/groups/lifestyle')?'white':'')}}>Lifestyle</div>
					</Col>
					<Col onClick={() => navigate('/app/groups/eroticism')} xs={3} className="groups-tab" style={{backgroundColor:(pathname === '/app/groups/eroticism'?'#0AC5BF':'transparent')}}>
						<div style={{color:((pathname === '/app/groups/eroticism')?'white':'')}}>Eroticism</div>
					</Col>
				</Row>
				<Row>
					<Card className="group-header">
						<Card.Body className="cover-image" style={{backgroundColor:privacyStatus === 'Public'?'#0AC5BF':'#f7451e'}}>
							<Button className="group-profile-option-btn"><i className="bi bi-three-dots-vertical"></i></Button>
						</Card.Body>
						<Card.Body className="group-profile">
							<div>
								{(pathname === '/app/groups')&&<Card.Text className="group-page-title">Recreation</Card.Text>}
								{(pathname === '/app/groups/sexuality')&&<Card.Text className="group-page-title">Sexuality</Card.Text>}
								{(pathname === '/app/groups/lifestyle')&&<Card.Text className="group-page-title">Lifestyle</Card.Text>}
								{(pathname === '/app/groups/eroticism')&&<Card.Text className="group-page-title">Eroticism</Card.Text>}
								<Card.Text className="group-privacy-status">{privacyStatus==='Public'?<i className="bi bi-people-fill"></i>:<i className="bi bi-lock-fill"></i>} {privacyStatus}</Card.Text>
								<Card.Text className="group-privacy-status" style={{color:privacyStatus==='Public'?'#0AC5BF':'#f7451e'}}>{privacyStatus==='Public'?"SFW":"NSFW"}</Card.Text>							
								<Card.Text className="group-description">Description</Card.Text>
							</div>
							<div className="d-flex align-items-center justify-content-center"></div>
							<Row className="d-flex align-items-center justify-content-center">
								<Button className="join-group-btn"><i className="bi bi-plus" style={{fontSize:20}}></i> Join Group</Button>
							</Row>
						</Card.Body>
					</Card>
				</Row>
				<Row className="d-flex align-items-stretch justify-content-stretch rounded-3 mt-2" style={{color:'#0AC5BF', border:'1px solid #0ac5bf',overflow:'hidden'}}>
					<Col xs={4} className="alt-groups-tab bottom-border">
						<div>Feed</div>
					</Col>
					<Col xs={4} className="alt-groups-tab bottom-border">
						<div>Media</div>
					</Col>
					<Col xs={4} className="alt-groups-tab bottom-border no-border-right">
						<div>Documents</div>
					</Col>
					<Col xs={4} className="alt-groups-tab">
						<div>Members</div>
					</Col>
					<Col xs={4} className="alt-groups-tab">
						<div>Send Invites</div>
					</Col>
					<Col xs={4} className="alt-groups-tab d-flex no-border-right">
						<div>Messages</div>
					</Col>
				</Row>
				<Outlet/>
			</Container>
}