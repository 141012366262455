import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton, ProfileListItem} from '../../components/ModularComponents';
import '../../css/profile.css';

export const BuzzSuggestedFollow = () => {
    const members = [{username:'jenni',location:'Nairobi, Kenya',gender:'female',age:25,profileType:'single'},
					 {username:'julie',location:'Nakuru, Kenya',gender:'female',age:23,profileType:'single'},
					 {username:'oscar',location:'Kiambu, Kenya',gender:'male',age:28,profileType:'single'},
					 {username:'romio-juliette',location:'Mombasa, Kenya',gender:'male-female',age:'31-26',profileType:'couple'}];

    return (
        <Container className="p-0">
            <div style={{paddingLeft:20,paddingRight:20,fontSize:11}}>
                {members.map(({username, location, gender, age, profileType}, index) => {
                    return <ProfileListItem username={username} location={location} gender={gender} isCouple={profileType==="couple"?true:false}/>
                })}
            </div>
        </Container>
    );
}

