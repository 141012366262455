export const africanCountriesWithCodes = {
    'Algeria': 'DZ',
    'Angola': 'AO',
    'Benin': 'BJ',
    'Botswana': 'BW',
    'Burkina Faso': 'BF',
    'Burundi': 'BI',
    'Cabo Verde': 'CV',
    'Cameroon': 'CM',
    'Central African Republic': 'CF',
    'Chad': 'TD',
    'Comoros': 'KM',
    'Democratic Republic of the Congo': 'CD',
    'Djibouti': 'DJ',
    'Egypt': 'EG',
    'Equatorial Guinea': 'GQ',
    'Eritrea': 'ER',
    'Eswatini': 'SZ',
    'Ethiopia': 'ET',
    'Gabon': 'GA',
    'Gambia': 'GM',
    'Ghana': 'GH',
    'Guinea': 'GN',
    'Guinea-Bissau': 'GW',
    'Ivory Coast': 'CI',
    'Kenya': 'KE',
    'Lesotho': 'LS',
    'Liberia': 'LR',
    'Libya': 'LY',
    'Madagascar': 'MG',
    'Malawi': 'MW',
    'Mali': 'ML',
    'Mauritania': 'MR',
    'Mauritius': 'MU',
    'Morocco': 'MA',
    'Mozambique': 'MZ',
    'Namibia': 'NA',
    'Niger': 'NE',
    'Nigeria': 'NG',
    'Republic of the Congo': 'CG',
    'Rwanda': 'RW',
    'Sao Tome and Principe': 'ST',
    'Senegal': 'SN',
    'Seychelles': 'SC',
    'Sierra Leone': 'SL',
    'Somalia': 'SO',
    'South Africa': 'ZA',
    'South Sudan': 'SS',
    'Sudan': 'SD',
    'Tanzania': 'TZ',
    'Togo': 'TG',
    'Tunisia': 'TN',
    'Uganda': 'UG',
    'Zambia': 'ZM',
    'Zimbabwe': 'ZW'
};

export const postData = [
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/men/1.jpg",
            username: "johndoe"
        },
        createdAt: "2 hours ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 10,
        comments: 5,
        shares: 2,
        image: "https://picsum.photos/500/300?random=1"
    },
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/women/2.jpg",
            username: "janesmith"
        },
        createdAt: "5 hours ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 15,
        comments: 8,
        shares: 3,
        image: "https://picsum.photos/500/300?random=2"
    },
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/men/3.jpg",
            username: "mikejohnson"
        },
        createdAt: "1 day ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 20,
        comments: 12,
        shares: 4,
        image: "https://picsum.photos/500/300?random=3"
    },
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/women/4.jpg",
            username: "sarahwilliams"
        },
        createdAt: "1 day ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 25,
        comments: 15,
        shares: 5,
        image: "https://picsum.photos/500/300?random=4"
    }
];

export const commentsData = [
  {"id": 12, "username": "sara", "comment": "Good morning!", "avatar": "https://randomuser.me/api/portraits/women/12.jpg"},
  {"id": 13, "username": "john", "comment": "Loving this weather!", "avatar": "https://randomuser.me/api/portraits/men/13.jpg"},
  {"id": 14, "username": "linda", "comment": "Had a great day today!", "avatar": "https://randomuser.me/api/portraits/women/14.jpg"},
  {"id": 15, "username": "david", "comment": "Just finished a great book.", "avatar": "https://randomuser.me/api/portraits/men/15.jpg"},
  {"id": 16, "username": "emma", "comment": "Looking forward to the weekend!", "avatar": "https://randomuser.me/api/portraits/women/16.jpg"},
  {"id": 17, "username": "michael", "comment": "Enjoying some quality time with family.", "avatar": "https://randomuser.me/api/portraits/men/17.jpg"},
]



export const emojis = [
  {"id": 1, "label": "grinning", "emoji": "😀"},
  {"id": 2, "label": "grin", "emoji": "😁"},
  {"id": 3, "label": "joy", "emoji": "😂"},
  {"id": 4, "label": "rolling_on_the_floor_laughing", "emoji": "🤣"},
  {"id": 5, "label": "smile", "emoji": "😊"},
  {"id": 6, "label": "blush", "emoji": "😊"},
  {"id": 7, "label": "heart_eyes", "emoji": "😍"},
  {"id": 8, "label": "kissing_heart", "emoji": "😘"},
  {"id": 9, "label": "thinking", "emoji": "🤔"},
  {"id": 10, "label": "sunglasses", "emoji": "😎"},
  {"id": 11, "label": "wink", "emoji": "😉"},
  {"id": 113, "label": "face_with_steam_from_nose", "emoji": "😤"},
  {"id": 114, "label": "angry_face", "emoji": "😠"},
  {"id": 103, "label": "star_struck", "emoji": "🤩"},
  {"id": 105, "label": "shushing_face", "emoji": "🤫"},
  {"id": 106, "label": "face_with_hand_over_mouth", "emoji": "🤭"},
  {"id": 108, "label": "nerd_face", "emoji": "🤓"},
  {"id": 109, "label": "face_with_raised_eyebrow", "emoji": "🤨"},
  {"id": 110, "label": "face_screaming_in_fear", "emoji": "😱"},
  {"id": 115, "label": "face_with_symbols_on_mouth", "emoji": "🤬"},
  {"id": 120, "label": "face_palm", "emoji": "🤦"},
  {"id": 136, "label": "nauseated_face", "emoji": "🤢"},
  {"id": 137, "label": "vomiting_face", "emoji": "🤮"},
  {"id": 138, "label": "sneezing_face", "emoji": "🤧"},
  {"id": 130, "label": "sleeping_face", "emoji": "😴"},
  {"id": 131, "label": "drooling_face", "emoji": "🤤"},
  {"id": 132, "label": "zipper_mouth_face", "emoji": "🤐"},
  {"id": 133, "label": "face_with_medical_mask", "emoji": "😷"},
  {"id": 134, "label": "face_with_thermometer", "emoji": "🤒"},
  {"id": 135, "label": "face_with_head_bandage", "emoji": "🤕"},
  {"id": 16, "label": "pray", "emoji": "🙏"},
  {"id": 17, "label": "muscle", "emoji": "💪"},
  {"id": 201, "label": "100", "emoji": "💯"},
  {"id": 202, "label": "eggplant", "emoji": "🍆"},
  {"id": 203, "label": "peach", "emoji": "🍑"},
  {"id": 204, "label": "fire", "emoji": "🔥"},
  {"id": 205, "label": "water_droplets", "emoji": "💦"},
  {"id": 206, "label": "clapping_hands", "emoji": "👏"},
  {"id": 207, "label": "raising_hands", "emoji": "🙌"},
  {"id": 208, "label": "ok_hand", "emoji": "👌"},
  {"id": 209, "label": "thumbs_up", "emoji": "👍"},
  {"id": 210, "label": "thumbs_down", "emoji": "👎"},
  {"id": 211, "label": "winking_face", "emoji": "😉"},
  {"id": 212, "label": "smirking_face", "emoji": "😏"},
  {"id": 213, "label": "sweat_drops", "emoji": "💦"},
  {"id": 214, "label": "eyes", "emoji": "👀"},
  {"id": 215, "label": "money_bag", "emoji": "💰"},
  {"id": 216, "label": "dollar_sign", "emoji": "💲"},
  {"id": 217, "label": "money_with_wings", "emoji": "💸"},
  {"id": 218, "label": "chart_increasing", "emoji": "📈"},
  {"id": 219, "label": "chart_decreasing", "emoji": "📉"},
  {"id": 220, "label": "trophy", "emoji": "🏆"},
  {"id": 221, "label": "medal", "emoji": "🏅"},
  {"id": 222, "label": "sparkles", "emoji": "✨"},
  {"id": 223, "label": "boom", "emoji": "💥"},
  {"id": 224, "label": "balloon", "emoji": "🎈"},
  {"id": 225, "label": "confetti_ball", "emoji": "🎊"},
  {"id": 226, "label": "party_popper", "emoji": "🎉"},
  {"id": 227, "label": "gift", "emoji": "🎁"},
  {"id": 228, "label": "ribbon", "emoji": "🎀"},
  {"id": 229, "label": "microphone", "emoji": "🎤"},
  {"id": 230, "label": "guitar", "emoji": "🎸"},
  {"id": 231, "label": "musical_note", "emoji": "🎵"},
  {"id": 232, "label": "notes", "emoji": "🎶"},
  {"id": 233, "label": "cinema", "emoji": "🎦"},
  {"id": 234, "label": "popcorn", "emoji": "🍿"},
  {"id": 236, "label": "crown", "emoji": "👑"},
  {"id": 237, "label": "prince", "emoji": "🤴"},
  {"id": 238, "label": "princess", "emoji": "👸"},
  {"id": 239, "label": "man_with_turban", "emoji": "👳‍♂️"},
  {"id": 240, "label": "woman_with_turban", "emoji": "👳‍♀️"},
  {"id": 241, "label": "man_with_beard", "emoji": "🧔"},
  {"id": 242, "label": "woman_with_headscarf", "emoji": "🧕"},
  {"id": 243, "label": "alien", "emoji": "👽"},
  {"id": 244, "label": "ghost", "emoji": "👻"},
  {"id": 245, "label": "skull", "emoji": "💀"},
  {"id": 246, "label": "pile_of_poo", "emoji": "💩"},
  {"id": 247, "label": "nail_polish", "emoji": "💅"},
  {"id": 248, "label": "lipstick", "emoji": "💄"},
  {"id": 249, "label": "kiss_mark", "emoji": "💋"},
  {"id": 250, "label": "lips", "emoji": "👄"},
  {"id": 251, "label": "red_heart", "emoji": "❤️"},
  {"id": 252, "label": "broken_heart", "emoji": "💔"},
  {"id": 253, "label": "sparkling_heart", "emoji": "💖"},
  {"id": 254, "label": "heart_with_arrow", "emoji": "💘"},
  {"id": 255, "label": "heart_with_ribbon", "emoji": "💝"},
  {"id": 256, "label": "revolving_hearts", "emoji": "💞"},
  {"id": 257, "label": "two_hearts", "emoji": "💕"},
  {"id": 258, "label": "couple_with_heart", "emoji": "💑"},
  {"id": 259, "label": "couplekiss", "emoji": "💏"},
  {"id": 260, "label": "ring", "emoji": "💍"}
]



