import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import '../css/cover.css';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, Image } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import logo from '../assets/logo.png';
import image001 from '../assets/image001.jpg';
import brownBg from '../assets/brown-background.jpg';
import image002 from '../assets/websiteImage.png';
import image003 from '../assets/image003.png';
import bImage from '../assets/bImage.png';
import welcomeImage from '../assets/welcomeImage.png';
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';
import {ScrollToTop} from '../components/ScrollToTop';

export const Welcome = () => {
  const navigate = useNavigate();

  return  <>
          <ScrollToTop/>
          <div style={{fontFamily:'Merriweather'}}>
              <div style={{backgroundColor:'black'}}>
                {/* Transparent Navbar */}
                <BlackNavBar loggedIn={false}/>
                <div className="cover-image-container" style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
                  <img
                    src="cover-image.jpg"
                    alt="Cover"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <Container fluid className="d-flex align-items-center justify-content-center" 
                              style={{position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',}}>
                        <Row>
                          <Col>
                            <Container className="d-flex align-items-center justify-content-center">
                              <Card style={{borderWidth:0,backgroundColor:'transparent',color:'white'}}>
                                <Image src={welcomeImage} style={{width:245,alignSelf:'center'}}/>
                                <p style={{color:'#fed700',
                                            textAlign:'center',
                                            fontSize:32,
                                            fontWeight:'bold',
                                            fontStyle:'italic'}}>A Social Club for the Adventurous</p>
                                <p style={{textAlign:'center',
                                          color:'white',
                                          fontStyle:'italic',
                                          fontWeight:'bold',
                                          fontSize:18}}>Safer Pleasurable Experiences</p>
                                <p style={{textAlign:'center',
                                          color:'#0AC5BF',
                                          fontStyle:'italic',
                                          fontWeight:'bold',
                                          fontSize:20}}>Dream It, Live It</p>
                              </Card>
                            </Container>
                          </Col>
                        </Row>
                  </Container>
                </div>
              </div>
              <Container>
                <Row className="d-flex align-items-center">
                  <Col xs={12} md={6}>
                    <Card style={{ width: '100%', marginTop:20, marginBottom:20, borderColor:'transparent' }}>
                      <Card.Body>
                        <p style={{ color: '#0AC5BF', fontStyle: 'italic', fontSize:18, fontWeight:'bold', textAlign:'justify'}}>
                        Do you desire to explore new activities or indulge in pleasurable pursuits but lack like-minded people to share them with?
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>Join us as an individual, couple, or group to explore what brings you pleasure and joy, and discover new ways to play alongside like-minded adults in Africa!</p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        Welcome to our vibrant and inclusive social club, where we celebrate the diverse and ever-evolving nature of human desires, sexualities, and interests.
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        Driven by curiosity, a thirst for new experiences, a spirit of exploration, or simply the desire to connect and play, our social club warmly welcomes individuals of all genders, sexualities, desires, lifestyles, and relationship statuses or structures.
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        Here, you can pursue various interests and desires, find like-minded companions for recreational activities, and explore different aspects of human connection and pleasure without judgement or shame.
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        We are committed to providing a safer and empowering space for open-minded adults in Africa, prioritizing play, pleasure, and the celebration of diversity and exploration. Join us to embrace novelty, variety, and adventure in a respectful and supportive social club.
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="p-4 d-none d-md-block" xs={12} md={6}>
                    <Image src={image002} fluid/>
                  </Col>
                </Row>
              </Container>
              <div className="d-flex align-items-center justify-content-center pb-4">
                  <Button onClick={() => navigate('/signup')} 
                          className="valuesBtn"
                          style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                                  color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                                  borderWidth:3, alignSelf:'center'}}>Your Adventure Starts Here</Button>
              </div>
              <div className="d-block d-md-none" style={{backgroundColor:'black'}}>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <Image src={image002} fluid/>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div style={{backgroundColor:'black',padding:'0px 10px'}}>
                <Container>
                  <h1 className="pt-4 d-block d-md-none" style={{fontSize:'2em',color:'#FED700', fontStyle:'italic',textAlign:'center'}}>Play and Pleasure</h1>
                  <h1 className="pt-4 d-none d-md-block" style={{fontSize:'2em',color:'#FED700', fontStyle:'italic'}}>Play and Pleasure</h1>        
                  <Row>
                    <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <h1 style={{fontSize:'10em',color:'#0AC5BF', fontStyle:'normal'}}>01</h1>
                      <h4><strong>Like-minded Companionship</strong></h4>
                      <p>
                      At Shap, we celebrate the joy of shared experiences and the power of connecting with others who have diverse interests and passions. Whether you're exploring new activities or enjoying familiar pleasures, our social club offers a welcoming space for open-minded individuals to engage authentically.
                      </p>
                      <p>  
                      Join us to connect with like-minded peers who value play and pleasure as essential parts of a joyful life. We believe that feeling good and connected enriches our well-being, contributing to happiness, health, and a sense of belonging. Whether you're savoring a small indulgence or passionately pursuing a hobby, our social club supports your journey toward fulfillment on your terms.
                      </p>
                      <p>
                      We welcome you to join us in creating a vibrant social club where each person's unique experiences are respected and celebrated. Together, let's embrace the power of shared joy and connection to enhance our lives.
                      </p>  
                    </Col>
                    <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <h1 style={{fontSize:'10em',color:'#0AC5BF',fontStyle:'normal'}}>02</h1>
                      <h4><strong>Explore Erotic Pleasures</strong></h4>
                      <p>
                      "When sexual activity is pleasurable, freely chosen, and intrinsically motivated, it aligns with most definitions of leisure activity," as stated by Liza Berdychevsky. Engaging in satisfying erotic activity has been associated with greater overall enjoyment of life, improved quality of life, and increased well-being.
                    </p>
                    <p>
                    Embracing the concept of eroticism as leisure seamlessly fits within the sex-positive and pleasure-positive culture that advocates for the promotion of pleasure, erotic diversity, autonomy, freedom, empowerment, and fulfilling erotic lives.
                    </p> 
                    <p>
                    We warmly invite you to join our social club, thoughtfully designed to cater to a wide range of interests, sexualities, and desires. Here, you can connect more deeply with your sexuality and explore diverse activities that bring you pleasure. Just like engaging in a favorite hobby, we encourage you to embrace your desires with curiosity and enthusiasm as you navigate your unique path to erotic happiness.
                      </p>
                    </Col>
                    <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <h1 style={{fontSize:'10em',color:'#0AC5BF',fontStyle:'normal'}}>03</h1>
                      <h4><strong>Mutual Enjoyment</strong></h4>
                      <p>
                      Mutual enjoyment is at the heart of everything we do. Our members curate experiences that are mutually satisfying in a judgment-free, no-pressure, accepting environment. Everyone is encouraged to confidently express their needs, uncertainties, boundaries, preferences, curiosities, and desires.
                      </p>
                      <p> 
                      Respect for each other's comfort and consent is paramount, ensuring all interactions are grounded in mutual respect and understanding. Through active listening and ongoing dialogue, our members cater to their own pleasures and preferences while prioritizing the desires and comfort of everyone involved. This approach allows for the refinement and adaptation of experiences, creating moments of shared pleasure, joy, and fulfillment.
                      </p>
                      <p>
                      Whether online or in person, we invite you to join us as we explore new places, try new things, and forge lasting connections. Together, we embrace a journey of exploration and discovery, where mutual enjoyment is not just a goal but a shared commitment to creating meaningful and enjoyable experiences for everyone involved.
                      </p>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-center pb-4">
                    <Button onClick={() => navigate('/signup')} 
                            className="valuesBtn"
                            style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                                    color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                                    borderWidth:3, alignSelf:'center'}}>Live a Life of Adventure, Join Now</Button>
                  </div>
                </Container>
              </div>
              <Container className="pt-4" style={{padding:'0px 20px'}}>
                <Row>
                  <Col style={{ textAlign: 'justify',fontStyle:'italic' }}>
                    <h4 style={{borderBottom:'1px dashed #444', color:'#0AC5BF', display:'inline-block'}}><strong>Our Approach</strong></h4>
                    <p>Our social club warmly welcomes open-minded adults aged 25 and above in Africa. We celebrate individuals who value human connection, personal growth, and discretion. Our social club is dedicated to curating mutually satisfying adventures and experiences, fostering a vibrant and inclusive environment for exploration and connection. We strive to create a space where members can practice mindfulness, take personal responsibility, and engage in meaningful and respectful interactions. Join us in embracing a journey of self-discovery and shared experiences.</p>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="d-flex align-items-center">
                  <Col xs={12} md={6}>
                    <Card style={{ width: '100%', marginTop:20, marginBottom:20, borderColor:'transparent' }}>
                      <Card.Body>
                        <p style={{ color: '#0AC5BF', fontStyle: 'italic', fontSize:18, fontWeight:'bold', textAlign:'justify'}}>
                        Do you desire to explore new activities or indulge in pleasurable pursuits but lack like-minded people to share them with?
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>Join us as an individual, couple, or group to explore what brings you pleasure and joy, and discover new ways to play alongside like-minded adults in Africa!</p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        Welcome to our vibrant and inclusive social club, where we celebrate the diverse and ever-evolving nature of human desires, sexualities, and interests.
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        Driven by curiosity, a thirst for new experiences, a spirit of exploration, or simply the desire to connect and play, our social club warmly welcomes individuals of all genders, sexualities, desires, lifestyles, and relationship statuses or structures.
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        Here, you can pursue various interests and desires, find like-minded companions for recreational activities, and explore different aspects of human connection and pleasure.
                        </p>
                        <p style={{fontStyle:'italic', textAlign:'justify'}}>
                        We are committed to providing a safe and empowering space for open-minded adults in Africa, prioritizing play, pleasure, and the celebration of diversity and exploration. Join us to embrace novelty, variety, and adventure in a respectful and supportive social club.
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="p-4 d-none d-md-block" xs={12} md={6}>
                    <Image src={image002} fluid/>
                  </Col>
                </Row>
              </Container>
              <div className="d-flex align-items-center justify-content-center pb-4">
                  <Button onClick={() => navigate('/signup')} 
                          className="valuesBtn"
                          style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                                  color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                                  borderWidth:3, alignSelf:'center'}}>Get Started</Button>
              </div>
              <div className="d-block d-md-none" style={{backgroundColor:'black'}}>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <Image src={image002} fluid/>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div style={{backgroundColor:'black',padding:'0px 10px'}}>
                <Container>
                  <h1 className="pt-4 d-block d-md-none" style={{fontSize:'2em',color:'#FED700', fontStyle:'italic',textAlign:'center'}}>Play and Pleasure</h1>
                  <h1 className="pt-4 d-none d-md-block" style={{fontSize:'2em',color:'#FED700', fontStyle:'italic'}}>Play and Pleasure</h1>        
                  <Row>
                    <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <h1 style={{fontSize:'10em',color:'#0AC5BF', fontStyle:'normal'}}>01</h1>
                      <h4><strong>Like-minded Companionship</strong></h4>
                      <p>
                      At Shap, we celebrate the joy of shared experiences and the power of connecting with others who have diverse interests and passions. Whether you're exploring new activities or enjoying familiar pleasures, our social club offers a welcoming space for open-minded individuals to engage authentically.
                      </p>
                      <p>  
                      Join us to connect with like-minded peers who value play and pleasure as essential parts of a joyful life. We believe that feeling good and connected enriches our well-being, contributing to happiness, health, and a sense of belonging. Whether you're savoring a small indulgence or passionately pursuing a hobby, our social club supports your journey toward fulfillment on your terms.
                      </p>
                      <p>
                      We welcome you to join us in creating a vibrant social club where each person's unique experiences are respected and celebrated. Together, let's embrace the power of shared joy and connection to enhance our lives.
                      </p>  
                    </Col>
                    <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <h1 style={{fontSize:'10em',color:'#0AC5BF',fontStyle:'normal'}}>02</h1>
                      <h4><strong>Explore Erotic Pleasures</strong></h4>
                      <p>
                      "When sexual activity is pleasurable, freely chosen, and intrinsically motivated, it aligns with most definitions of leisure activity," as stated by Liza Berdychevsky. Engaging in satisfying erotic activity has been associated with greater overall enjoyment of life, improved quality of life, and increased well-being.
                     </p>
                     <p>
                     Embracing the concept of eroticism as leisure seamlessly fits within the sex-positive and pleasure-positive culture that advocates for the promotion of pleasure, erotic diversity, autonomy, freedom, empowerment, and fulfilling erotic lives.
                     </p> 
                     <p>
                     We warmly invite you to join our social club, thoughtfully designed to cater to a wide range of interests, sexualities, and desires. Here, you can connect more deeply with your sexuality and explore diverse activities that bring you pleasure. Just like engaging in a favorite hobby, we encourage you to embrace your desires with curiosity and enthusiasm as you navigate your unique path to erotic happiness.
                      </p>
                    </Col>
                    <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <h1 style={{fontSize:'10em',color:'#0AC5BF',fontStyle:'normal'}}>03</h1>
                      <h4><strong>Mutual Enjoyment</strong></h4>
                      <p>
                      Mutual enjoyment is at the heart of everything we do. Our members curate experiences that are mutually satisfying in a judgment-free, no-pressure, accepting environment. Everyone is encouraged to confidently express their needs, uncertainties, boundaries, preferences, curiosities, and desires.
                       </p>
                       <p> 
                       Respect for each other's comfort and consent is paramount, ensuring all interactions are grounded in mutual respect and understanding. Through active listening and ongoing dialogue, our members cater to their own pleasures and preferences while prioritizing the desires and comfort of everyone involved. This approach allows for the refinement and adaptation of experiences, creating moments of shared pleasure, joy, and fulfillment.
                      </p>
                      <p>
                      Whether online or in person, we invite you to join us as we explore new places, try new things, and forge lasting connections. Together, we embrace a journey of exploration and discovery, where mutual enjoyment is not just a goal but a shared commitment to creating meaningful and enjoyable experiences for everyone involved.
                      </p>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-center pb-4">
                    <Button onClick={() => navigate('/signup')} 
                            className="valuesBtn"
                            style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                                    color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                                    borderWidth:3, alignSelf:'center'}}>Live a Life of Adventure, Join Now</Button>
                  </div>
                </Container>
              </div>
              <Container className="pt-4" style={{padding:'0px 20px'}}>
                <Row>
                  <Col style={{ textAlign: 'justify',fontStyle:'italic' }}>
                    <h4 style={{borderBottom:'1px dashed #444', color:'#0AC5BF', display:'inline-block'}}><strong>Our Approach</strong></h4>
                    <p>Our social club warmly welcomes open-minded adults aged 25 and above in Africa. We celebrate individuals who value human connection, personal growth, and discretion. Our social club is dedicated to curating mutually satisfying adventures and experiences, fostering a vibrant and inclusive environment for exploration and connection. We strive to create a space where members can practice mindfulness, take personal responsibility, and engage in meaningful and respectful interactions. Join us in embracing a journey of self-discovery and shared experiences.</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
                    <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>01</h1>
                    <h4><strong>Personalized Profiles</strong></h4>
                    <p>
                    Craft your personal profile thoughtfully, expressing your preferences, desires, and interests to connect meaningfully with like-minded members. Customize the visibility of your profile to suit your comfort level by adjusting your privacy settings. A detailed profile empowers both you and others to make informed decisions about potential connections, fostering compatibility and shared interests. Let your profile authentically reflect who you are, facilitating seamless connections with members who resonate with your desires and preferences. Join Shap, where personal profiles open doors to new connections and adventures.</p>
                  </Col>
                  <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
                    <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>02</h1>
                    <h4><strong>Interest Groups</strong></h4>
                    <p>
                    Discover and join interest-based groups to connect with like-minded members who share your diverse interests. Engage in enriching discussions, share experiences, and gain valuable insights in a supportive and judgment-free environment. Our groups offer options tailored to all comfort levels and preferences, including Safe for Work (SFW) and Not Safe for Work (NSFW). Whether you're exploring new passions or delving deeper into familiar topics, our social club provides a space for open dialogue and meaningful connections. Join us and embark on a journey of exploration, discovery, and connection in an inclusive environment where every member's voice is valued and respected.
                    </p>
                  </Col>
                  <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
                    <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>03</h1>
                    <h4><strong>Discussion Forums</strong></h4>
                    <p>
                    Enter our inclusive environment where you can freely express yourself and explore meaningful topics in a safe, non-judgmental space. Engage in open conversations, share your thoughts, ask questions, and tell your stories. Enjoy the option for one-on-one conversations with connected members, unlimited messaging, and the immediacy of real-time communication through voice messages, audio calls, and video calls. These features foster deeper connections and personal discussions. Join us and embark on a journey of meaningful interactions and fulfilling connections.
                    </p>
                  </Col>
                  <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
                    <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>04</h1>
                    <h4><strong>Activities</strong></h4>
                    <p>
                    Immerse yourself in our vibrant social club, where activities unite members for real-life adventures. Get verified to join our regular in-person meetups and gain the opportunity to organize your own unique experiences. From casual gatherings to thrilling outings, you can host a variety of events tailored to your preferences. Let's embark on a journey from laid-back to exhilarating experiences, forging connections and making memories together. Join us and be part of a social club where real-life interactions pave the way for exciting and unforgettable adventures.
                    </p>
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-center pb-4">
                  <Button onClick={() => navigate('/signup')} 
                          className="valuesBtn"
                          style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                                  color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                                  borderWidth:3, alignSelf:'center'}}>Find your people, Join Now</Button>
                </div>
              </Container>
              <div className="pt-4" style={{backgroundColor:'black',padding:'0px 10px'}}>
                <Container>
                  <Row>
                    <Col xs={12} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <h2 style={{ color: '#fed700', fontStyle: 'italic' }}>
                      Embrace Your Desires: Design a Life of True Fulfillment
                      </h2>
                      <p>
                      Have you ever felt encaged by your own life, like you've built a life designed to please others while neglecting your own desires? 
                      </p>
                      <p>Play and pleasure can sometimes lead to internal conflicts, especially when they clash with other aspects of our private and public identities. Societal expectations often make us feel judged when our desires diverge.
                      </p>
                      <p>
                      These pressures, whether subtle or overt, dictate what our relationships should look like, whom we should be with, and what we should desire, often disconnecting us from our true passions. However, reconnecting with these aspects of ourselves can be incredibly rewarding, liberating and life-enhancing.
                      </p>
                      <p>
                      As noted by Gigi Engle, every fantasy falls into one of three categories: those we keep to ourselves, those we share with partners to spice things up, and those we aspire to explore.
                        </p>
                        <p>
                        <span style={{color:'#fed700',fontWeight:'bold',cursor:'pointer'}} onClick={() => window.location.assign('https://sexualhealthalliance.com/justin-lehmiller-science-of-fantasy') }>Dr. Justin Lehmiller identifies seven common erotic themes:</span> multipartner fantasies, BDSM activities, novelty, adventure, and variety, taboo and forbidden sex, passion, romance, and intimacy, nonmonogamy and partner sharing, erotic flexibility, and gender bending. If you're into any of these, you're in good company.
                         </p>
                         <p>
                         At our social club, you can freely explore all your fantasies in a safe, non-judgmental environment. We provide a space where you can express yourself openly and engage with like-minded adults in Africa. Trying new experiences brings fresh perspectives and revitalizes our lives.
                         </p>
                         <p>
                         This openness liberates us from conventional thinking, allowing us to embrace the diverse richness of human experience. Shap is here to empower you to design the wildest and most fulfilling life you can imagine. 
                      </p>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-center pb-4">
                    <Button onClick={() => navigate('/signup')} 
                            className="valuesBtn"
                            style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                                    color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                                    borderWidth:3, alignSelf:'center'}}>Live it like you dream it, Join Now</Button>
                  </div>
                </Container>
              </div>
              <Container className="pt-4">
                <Row>
                  <Col xs={12} md={6} style={{ textAlign: 'justify', fontStyle:'italic' }}>
                    <h1 style={{ color: '#0AC5BF', fontStyle: 'normal', fontWeight:'bold' }}>
                      Our values
                    </h1>
                    <div className="mb-4">
                      <p style={{fontSize:24, lineHeight:'1em', fontStyle:'italic', fontWeight:'bold', marginBottom:0}}>Behind every username is a human being. Let's be closer AF</p>
                    </div>
                    <p style={{fontStyle:'italic'}}>
                    <strong>C</strong>-Consent, Communication & Consideration
                      <br/>
                      <strong>L</strong>-Look out for each other
                      <br/>
                      <strong>O</strong>-Open-Minded
                      <br/>
                      <strong>S</strong>-Safety
                      <br/>
                      <strong>E</strong>-Empathy
                      <br/>
                      <strong>R</strong>-Respect
                      <br/>
                      <strong>A</strong>-Authentic
                      <br/>
                      <strong>F</strong>-Feedback
                    </p>
                  </Col>
                  <Col xs={12} md={6} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                    <Card style={{border:'0px solid white'}}>
                      <Card.Body>
                        <Image src={image003} fluid/>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-center pb-4">
                  <Button onClick={() => navigate('/our-story')} 
                          className="valuesBtn"
                          style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                                  color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                                  borderWidth:3, alignSelf:'center'}}>Our Values in Detail</Button>
                </div>
              </Container>
              <div style={{backgroundColor:'black'}}>
                <Container>
                  <Row>
                    <Col xs={12} md={6} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <Card className="d-flex justify-content-center align-items-center" 
                            style={{border:'0px solid white',backgroundColor:'transparent'}}>
                        <Card.Body>
                          <h4 style={{color:'white'}}>Dream it, live it!</h4>
                          <Button onClick={() => navigate('/signup')} 
                                  className="valuesBtn"
                                  style={{width:'100%',maxWidth:500,backgroundColor:'#000', 
                                          color:'white', borderColor:'white', borderRadius:20, 
                                          borderWidth:3, alignSelf:'center'}}>Get Started</Button>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
                      <Card className="d-flex justify-content-center align-items-center" 
                            style={{border:'0px solid white',backgroundColor:'transparent'}}>
                        <Card.Body>
                          <h4 style={{color:'white'}}>Work/Invest with us!</h4>
                          <Button onClick={() => navigate('/text/workWithUs')} 
                                  className="valuesBtn"
                                  style={{width:'100%',maxWidth:500,backgroundColor:'#000', 
                                          color:'white', borderColor:'white', borderRadius:20, 
                                          borderWidth:3, alignSelf:'center'}}>Get Started</Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Footer/>
          </div>
          </>
}