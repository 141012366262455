import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import '../css/cover.css';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, Image } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import logo from '../assets/logo.png';
import image001 from '../assets/image001.jpg';
import image003 from '../assets/websiteImage.png';
import brownBg from '../assets/brown-background.jpg';
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';

export const OurStory = () => {
	const navigate = useNavigate();

	return 	<div>
		<div style={{backgroundColor:'black',paddingLeft:20,paddingRight:20}}>
	      {/* Transparent Navbar */}
	      <BlackNavBar loggedIn={false}/>
	      <div className="cover-image-container d-flex align-items-center justify-content-center" style={{ height: '100vh', overflow: 'hidden' }}>
	        <img
	          src="cover-image.jpg"
	          alt="Cover"
	          style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover',top: '0', left: '0', zIndex:-1 }}
	        />
	        <Container style={{borderColor:'red',borderWidth:2}} className="d-flex align-items-center justify-content-center">
				<Card style={{borderWidth:0,backgroundColor:'transparent'}}>	
					<Row>
						<Col xs={12} md={6}>
							<Container>
						        <h2 style={{color:'#fed700'}}>Our Story</h2>
						        <h2 style={{color:'#0AC5BF'}}>SHAP - Sexual Health and Pleasure</h2>
						        <p style={{color:'white', textAlign:'justify'}} >“Sexual health is the art of balancing sexual safety and responsibility with the lifelong curiosity of pleasure, exploring sexual interests, and remaining curious about the ever-changing sources of sexual pleasure.” The Harvey Institute.</p>
						        <Button className="gradient-border-button" onClick={()=>navigate("/signup")} style={{borderWidth:0, backgroundColor:'transparent', color:'#fed700',padding:'15px 40px 15px 40px'}}>
						          Join Now
						        </Button>
						    </Container>
						</Col>
					</Row>
				</Card>
		    </Container>
	      </div>
	  </div>
	  <Container >
	  	<Card style={{ width: '100%', borderColor:'transparent' }}>
	      <Card.Body className="pb-0">
	        <Card.Title style={{color:'#0AC5BF', fontWeight:'bold'}}>Our Objectives</Card.Title>
	        <Card.Text>
	          <p style={{textAlign:'justify'}}>The World Health Organization (WHO) defines <span style={{color:'#0AC5BF',fontWeight:'bold', cursor:'pointer'}} onClick={() => window.location.assign('https://www.who.int/health-topics/sexual-health#tab=tab_2') }>sexual health</span> as a state of physical, emotional, mental, and social well-being in relation to sexuality. It’s not merely the absence of disease, dysfunction, or infirmity.</p>
	          <p style={{textAlign:'justify'}}>Sexual health requires a positive and respectful approach to sexuality and sexual relationships, as well as the possibility of having pleasurable and safer sexual experiences free of coercion, discrimination, and violence. For sexual health to be attained and maintained, the sexual rights of all persons must be respected, protected and fulfilled.</p>
	        </Card.Text>
			<Card.Text style={{color:'#0AC5BF', fontWeight:'bold'}}>Shap: Embracing Sexual Health and Pleasure in Africa</Card.Text>
	        <Card.Text style={{fontWeight:'bold'}}>Shap emerged from the recognition of the need to:</Card.Text>
	       	<ul>
	       		<li>Break down the stigma surrounding sexuality and sexual relationships</li>
	       		<li>Embrace and celebrate pleasure in all its forms, extending far beyond the confines of penetrative sex culminating in orgasms, and substances.</li>
	       		<li>Explore diverse desires (sexual and non-sexual) in a shame-free, judgment-free, and no-pressure environment</li>
	       		<li>Create safer and more inclusive spaces in Africa where adults can connect with themselves and like-minded individuals</li>
	       	</ul>
	       	<Card.Text style={{fontWeight:'bold'}}>Our goal is to promote sexual health and pleasure by :</Card.Text>
	       	<ul style={{marginBottom:0}}>
	       		<li>Providing a safer, inclusive, and empowering space for members to gather, explore, and experiment with their sexuality and sexual relationships, and for pleasurable experiences</li>
				<li>Emphasizing personal agency and mindfulness, advocating for individuals to take responsibility for their lives, make autonomous decisions, pursue happiness, and support others in their journeys. Each person has the power to give their life meaning and make it fulfilling.</li>
	       		<li>
					<p>Encouraging our members to embrace the <span style={{color:'#0AC5BF',fontWeight:'bold', cursor:'pointer'}} onClick={() => window.location.assign('https://www.theharveyinstitute.com/six-principles-of-sexual-health') }>six principles of sexual health:</span> Consent, Non-exploitation, Protection from STIs and unintended pregnancy, Honesty, Shared values, and Mutual Pleasure</p>
				</li>
	       	</ul>
	      </Card.Body>
	    </Card>
	  </Container>
	  <Container>
	  	<Card style={{ width: '100%', marginBottom:20, borderColor:'transparent' }}>
	      <Card.Body>
	        <Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Our Social Club</Card.Title>
	        <Card.Text>
	          <p style={{textAlign:'justify'}}>Shap is a private members’ social club where we prioritize approaching and interacting with other members first and foremost as fellow human beings. We consciously set aside any preconceived notions or stereotypes, choosing instead to see each individual as a complex and unique person deserving of respect and understanding.</p>
	          <p style={{textAlign:'justify'}}>By focusing on our shared humanity and treating each other with empathy and kindness, we create a space where everyone can feel comfortable being themselves. Let’s engage in conversations that are free from judgment and full of curiosity and openness, seeking to learn from each other’s diverse perspectives and experiences.</p>
	          <p style={{textAlign:'justify'}}>In valuing each other as humans first, we can cultivate a sense of belonging and acceptance within our social club, where differences are celebrated and embraced as opportunities for growth and connection. Together, let’s build a social club where mutual respect and genuine human connection are at the core of all our interactions.</p>
	        </Card.Text>
			<div className="d-flex align-items-center justify-content-center">
	       		<Button onClick={() => navigate('/')} style={{backgroundColor:'#000', color:'#fed700', paddingBottom:5, paddingTop:5, paddingLeft:20, paddingRight:20, borderColor:'#fed700', borderRadius:20, borderWidth:3, alignSelf:'center'}}>More about Our Space</Button>
	      	</div>
	      </Card.Body>
	    </Card>
	  </Container>
	  <div style={{backgroundColor:'black'}}>
		  <Container className="mb-0" style={{backgroundColor:'black', paddingTop:40}}>
		  	<Card style={{backgroundColor:'black', width: '100%', marginTop:20, marginBottom:0, borderWidth:0}}>
			  	<Row style={{color:'#0AC5BF',fontWeight:'bold',marginBottom:10}}>
			  		<h2 style={{fontWeight:'bold',color:'#fed700'}}>Our Values</h2>
			  	</Row>
			  	<Row xs={1} md={1} lg={2} xxl={3} className="align-items-stretch">
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Consent, Communication & Consideration</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Consent, communication, and consideration are the cornerstones of our interactions. Let's embrace these values wholeheartedly to ensure a safer, respectful, and enjoyable environment for everyone.</p>
								<p style={{textAlign:'justify'}}><em><strong>Consent</strong></em> is at the heart of all our interactions, and we follow the FRIES model: Freely given, Reversible, Informed, Enthusiastic, and Specific. Consent means more than just the absence of "no"; it’s about creating an enthusiastic "YES!" through mutual agreement and understanding.</p>
								<p style={{textAlign:'justify'}}> <em><strong>Communication</strong></em> is key to fostering openness, honesty, and transparency. We encourage every member to express their needs, desires, thoughts, and feelings freely. This includes setting boundaries and communicating expectations clearly and without hesitation.</p>
								<p style={{textAlign:'justify'}}> <em><strong>Consideration</strong></em> involves attentiveness, kindness, and respect for each other’s needs, desires, boundaries, and preferences. By being considerate, we cultivate a supportive and thoughtful environment where everyone feels valued and respected.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Look out for each other</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}> Let's proactively care for each other's well-being and safety, creating a supportive environment where every member feels valued and cared for. This commitment not only builds trust and security but also ensures that everyone can fully embrace their experiences, fostering a close-knit social club where mutual support thrives.</p>
								<p style={{textAlign:'justify'}}>Our social club actively promotes bystander intervention and a culture of accountability. We encourage members to hold each other responsible for their actions and to intervene if they witness any behavior that could endanger someone’s safety or well-being. This includes promptly reporting any suspicious or inappropriate conduct to ensure the ongoing safety and comfort of all members.</p>
								<p style={{textAlign:'justify'}}>We provide resources and support for members who may need assistance or guidance. Whether through peer support groups, access to professional support, or informational materials, we are here to help each other navigate any challenges as we pursue meaningful and fulfilling lives. Rooted in understanding, kindness, and a commitment to collective well-being, our social club strives to create a safer and supportive environment where every member can thrive.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Open Minded</Card.Title>
				          	<Card.Text>
				          		<p style={{textAlign:'justify'}}>We celebrate diversity and nurture a non-judgmental atmosphere where everyone is embraced and welcomed. Our commitment to open-mindedness and acceptance encompasses the freedom to decide how to live (without causing harm to oneself or others). It is up to each of us to give our lives meaning and make them fulfilling. We profoundly respect the intrinsic worth of every individual, regardless of differences, and actively challenge assumptions and stereotypes.</p>
								<p style={{textAlign:'justify'}}>Being open-minded is crucial as it allows us to learn from each other, grow as individuals, and enrich our social club. Embracing different perspectives helps us understand diverse sexual orientations, gender identities, relationship structures, desires, lifestyles, and interests, fostering empathy and strengthening our bonds.</p>
								<p style={{textAlign:'justify'}}>Through inclusivity, we create a safer space where individuals can freely express themselves without fear of discrimination or prejudice. Together, we build a social club that values and supports every member, promoting authentic self-expression and mutual respect.</p>
						    </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Safety</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>At our social club, the safety and well-being of every member are of utmost importance. We believe in fostering an environment where everyone feels safer and respected. While we prioritize looking out for each other, it is essential for individuals to cultivate self-awareness and personal responsibility. Each member is encouraged to understand the motivations behind their actions and ensure they come from a place of positivity and respect.</p>
								<p style={{textAlign:'justify'}}> We support the freedom to decide how to live and pursue happiness, as long as it does not cause harm to oneself or others. Recognizing that not everyone may uphold these values, it is up to us to be vigilant and ensure our safety. Members are encouraged to be proactive in managing risks and exercise caution, particularly when meeting new people. Trusting one's instincts and using good judgment are essential components of personal safety. Members are empowered to listen to their intuition and respond appropriately to any signs of discomfort or suspicion.</p>
								<p style={{textAlign:'justify'}}> Open and honest communication is also vital. By honestly expressing their needs, desires, thoughts, feelings, and expectations, and taking their time to establish trust through consistent interaction, members foster an atmosphere of mutual understanding and respect.</p>	
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Empathy</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Empathy embodies the profound ability to understand and appreciate others' perspectives alongside our own. As Jesse Jackson wisely said, "Never look down on anybody unless you're helping him up." This ethos underscores our commitment to treating every member with kindness, respect, and compassion, valuing their unique viewpoints and experiences.</p>
								<p style={{textAlign:'justify'}}>Our purpose is to serve and demonstrate unwavering compassion towards others. We strive to cultivate a safer haven where members feel empowered to express themselves freely and openly. This culture of acceptance encourages individuals to embrace their vulnerabilities, knowing they will receive understanding and compassion from their peers.</p>
								<p style={{textAlign:'justify'}}>Through our commitment to empathy, we not only strengthen interpersonal bonds but also ensure that every member feels acknowledged and valued. This contributes to a social club built on mutual support and solidarity. In our club, empathy isn't just a value; it's a guiding principle that shapes how we engage with one another.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Respect</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>We require all members to engage in respectful conversations, treating each other with dignity and consistently embracing consent. Our core belief is to treat every individual with kindness, consideration, and respect, irrespective of differences or disagreements. Our social club fosters a culture of respecting personal boundaries, autonomy, and agency, urging members to honor each other’s choices and preferences without judgment or coercion.</p>
								<p style={{textAlign:'justify'}}>Maintaining respect for one another’s boundaries, values, and opinions is essential for upholding a safer and empowering environment. We encourage open, empathetic dialogue and active listening to ensure everyone feels heard and valued. By promoting mutual respect, we create a space where every member can express themselves freely and authentically.</p>
								<p style={{textAlign:'justify'}}>We actively confront harmful attitudes and behaviors while advocating for a culture of inclusivity, acceptance, and affirmation. Our commitment to respect extends to supporting and uplifting each other, recognizing that our diverse experiences and perspectives enrich our social club. Together, we strive to build a welcoming and supportive environment where everyone feels safer and empowered.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Authentic</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>
								At our core, we are dedicated to creating a space where members can freely express themselves without reservation. We believe that authenticity is not only liberating but also essential for personal growth and fulfillment. When we embrace our true selves, we thrive, finding joy and satisfaction in our actions, choices, and relationships.</p>
								<p style={{textAlign:'justify'}}>Living authentically involves embracing our true selves and acknowledging the diverse facets that make us unique. By nurturing a culture of authenticity, we offer members the opportunity to explore their identities, share their truths, and connect with others on a deeper level.</p>
								<p style={{textAlign:'justify'}}>In this supportive environment, we encourage each member to celebrate their individuality and the individuality of others. Through genuine acceptance and understanding, we foster meaningful connections and a social club where everyone feels valued and respected.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Feedback</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Within our social club, we deeply appreciate the power of feedback in fostering personal and collective growth. We value both positive affirmations and constructive criticism, recognizing that they provide invaluable insights to propel us forward. By sharing our perspectives, offering suggestions, and praising each other's strengths and contributions, we cultivate a culture of open communication and mutual respect.</p>
								<p style={{textAlign:'justify'}}>Let’s actively engage in offering feedback, knowing that positive comments uplift and motivate us, while constructive criticism guides us towards further refinement. Creating a supportive space where everyone feels heard and valued is our priority. Together, we can build an environment that encourages personal development and collective excellence.</p>
								<p style={{textAlign:'justify'}}>Embracing the opportunity to give and receive feedback graciously allows us to empower one another. Through this exchange, we can reach new heights of excellence within ourselves and our social club, fostering a social club where everyone thrives.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Fun</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Pleasure and enjoyment are central components of overall well-being. Our social club offers members the opportunity to organize and participate in a diverse array of social events and activities designed to foster laughter, joy, exploration, and playfulness. From light meetups to themed parties, there is always something exciting happening at SHAP! We encourage members to embrace their spontaneity and sense of adventure, letting go of inhibitions and fully immersing themselves in their experiences.</p>
								<p style={{textAlign:'justify'}}>Whether you’re seeking quality leisure time, private group activities, or intimate gatherings, there’s something for everyone to enjoy here. Our social club is built on inclusivity and mutual respect, ensuring that each member feels welcomed and valued. We believe in creating an atmosphere where everyone can come together to build lasting friendships and celebrate the joy of shared experiences.</p>
								<p style={{textAlign:'justify'}}>Let’s come together to create unforgettable memories and celebrate the sheer delight of having fun in a safer and supportive environment. Join us as we embark on new adventures and make the most of every moment. Together, we can make SHAP a place where happiness and connection thrive.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			    </Row>
			    <Row className="d-flex align-items-center justify-content-center" style={{paddingTop:20, paddingBottom:20}}>
			    	<div className="d-flex align-items-center justify-content-center">
			    		<Button onClick={() => navigate('/signup')} className="valuesBtn" style={{width:'100%',maxWidth:500,backgroundColor:'#000', color:'#fed700', borderColor:'#fed700', borderRadius:20, borderWidth:3, alignSelf:'center'}}>Our values align, join us today!</Button>
			    	</div>
			    </Row>
			</Card>
		  </Container>
		</div>
      <Footer/>
    </div>
}