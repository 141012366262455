import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Row,Col,Image} from 'react-bootstrap';
import patronImage from '../assets/patronImage.png';

export const Patron = () => {

	return  <div>
				<Container className="pt-4">
				<h2 style={{fontStyle:'italic',fontWeight:'bold',color: '#0ac5bf'}}>Patron</h2>
					<Image src={patronImage} fluid/>
					<p className="mb-0 pb-4 pt-4">We are on a mission to build a vibrant and inclusive social club dedicated to providing a safer and empowering space for open-minded adults across Africa. Our goal is to cultivate an environment where individuals can pursue various desires, find like-minded companions for recreational activities, and engage in erotic exploration without fear of judgment, shame, or harm.</p>
				</Container>
				<div className="pt-4" style={{backgroundColor:'black'}}>
					<Container>
						<p style={{color:'white', textAlign:'justify'}}>We are actively seeking passionate patrons who offer services that are inclusive, friendly, sensitive to our members interests, experiences, and relationships and who resonate with our vision and values to help shape this unique social club. We invite you to partner with us to support our members in their endeavors and&nbsp; in creating unforgettable experiences for our members.</p>
						<Row>
							<Col xs={12} md={6}>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Investor</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Event Planner</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Experiential Curator</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Event Host and Scene Curator</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Content Creator</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Owner of a unique venue or Boutique accomodation</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sex Educator</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Laboratory Service Provider</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sex Therapist</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Intimacy Coach</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sexuality Counselor</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sexual Wellness Practitioner</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Compliance Specialist</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Google Ads Expert and SEO Expert</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Private Security</p>
							</Col>
							<Col xs={12} md={6}>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sensualist</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Tantra Practitioner</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sacred Intimacy Coach</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sensual Healer</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Kink Specialist</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> BDSM expert</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Sexological Bodywork Practitioner</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Erotic Embodiment Coach</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Somatic Psychotherapist Specializing in sexuality</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Mind Body Practitioner Specializing in sexuality</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Pleasure Coach</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Erotic Artist and Photographer</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Erotic Dance/Movement Therapist</p>
								<p style={{color:'#fed700'}}><i className="bi bi-star-fill" 
										style={{color:'#fed700',
												marginRight:4}}></i> Adult Play Item Designer or Manufacturer</p>
							</Col>
						</Row>
						<p className="mb-0 pb-10 pt-4" style={{color:'white', textAlign:'justify'}}>If you aspire to become a patron, don’t hesitate to reach out to us by completing the contact form below. Join us in promoting sexual health, pleasure, exploration, healing, and personal growth through a combination of educational, therapeutic, and experiential approaches tailored to individual needs and goals.</p>
					</Container>
				</div>
			</div>
}