import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, NavDropdown, Dropdown } from 'react-bootstrap';
import logo from '../assets/logo.png';
import { Link, useNavigate, useLocation } from "react-router-dom";
import {MiniAvatar} from './ModularComponents';
import '../css/navbar.css'
import { useAuth } from '../hooks/useAuth';
import { useOutsideClick } from '../hooks/useOutsideClick';

export const NavBar = ({showNavBtn=true}) => {
	const navigate = useNavigate();
	const [showProfileDrowpdown, setShowProfileDropdown] = useState(false);
	const { logout, loggedIn } = useAuth();
	const profileMenuRef = useRef(null);

	const exitProfileMenu = () => {
        console.log('You clicked outside of me!');
        setShowProfileDropdown(false);
    };

    useOutsideClick(profileMenuRef, exitProfileMenu);


	const handleLogout = () => {
		logout();
		navigate('/login');
	}

	const onClickAvatar = () => {
		//navigate("/app/profile");
		setShowProfileDropdown(!showProfileDrowpdown)
	}

	return 	<Navbar bg="white" expand="lg" variant="light" fixed="top" style={{fontFamily:'Merriweather'}}>
		      	<Container>
			        <Navbar.Brand href="/">
			        	<Link to="/">
				        	<div style={{overflow:'hidden',borderRadius:25,width:50,height:50,borderWidth:3}}>
					          <img
					            src={logo}
					            width="50"
					            height="50"
					            className="d-inline-block align-top"
					            alt="Logo"
					          />
					        </div>
					    </Link>
			        </Navbar.Brand>
			        <Container className="d-block d-sm-block d-md-none d-xl-none"  style={{width:0}}/>
			        <Container className="d-none d-sm-none d-md-block d-xl-block"  style={{width:0}}/>
			        {showNavBtn&&<>
			          {!loggedIn&&<div className="d-flex" style={{alignSelf:'center',paddingLeft:0,paddingRight:0}}>
			            <Button onClick={() => navigate('/signup')} variant="outline-light" className="ml-2 gradient-border-button" style={{borderColor:'#fed700',borderWidth:0, width:105}}>
			            	<Link to="/signup" style={{color:'#fed700',textDecoration:'none'}}>Join Now</Link>
			            </Button>
			            <Button onClick={() => navigate('/login')} variant="outline-light" className="ml-2 gradient-border-button" style={{borderColor:'#fed700',marginLeft:10,borderWidth:0, width:105}}>
			            	<Link to="/login" style={{color:'#fed700',textDecoration:'none'}}>Login</Link>
			            </Button>
			          </div>}
			          {loggedIn&&<div className="nav-items-container d-none d-md-flex d-flex align-items-center justify-content-center">
			            <Link class="nav-items" onClick={()=>navigate("/app/discover")}>Discover</Link>
			            <Link class="nav-items" onClick={()=>navigate("/app/groups")}>Groups</Link>
			            <Link class="nav-items" onClick={()=>navigate("/app/forums")}>Forum</Link>
			            <Link class="nav-items" onClick={()=>navigate("/app/members")}>Members</Link>
			            <Link class="nav-items" onClick={()=>navigate("/app/activities")}>Activities</Link>
			            <Link class="nav-items" onClick={()=>navigate("/app/buzz")}>Buzz</Link>
			            <Button class="nav-items" style={{color:'#fed700',borderColor:'#fed700'}} variant="outline-light" className="ml-2">Quick Exit</Button>
			          </div>}
			        </>}
			        {loggedIn&&<div className="d-flex align-items-center justify-content-center">
			        	<div>
			        		<MiniAvatar onClick={onClickAvatar}/>
			        		{showProfileDrowpdown&&<div ref={profileMenuRef} style={{padding:'5px 5px',backgroundColor:'#b5edeb',width:140,position:'absolute',zIndex:888,marginLeft:-100,marginTop:5, borderRadius:8 }}>
			        			<div style={{cursor:'pointer'}} onClick={()=>navigate("/app/profile")}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}>
			        					<i className="bi bi-person" style={{marginRight:4}}></i>Profile</p>
			        			</div>
			        			<div style={{cursor:'pointer'}}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}><i className="bi bi-gear" style={{marginRight:4}}></i>Settings</p>
			        			</div>
			        			<div style={{cursor:'pointer'}}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}><i className="bi bi-receipt" style={{marginRight:4}}></i>Subscription</p>
			        			</div>
			        			<div style={{marginTop:5,borderTop:'1px solid #0ac5bf',cursor:'pointer'}} onClick={()=>handleLogout()}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}><i className="bi bi-box-arrow-right" style={{marginRight:4}}></i>Logout</p>
			        			</div>
			        		</div>}
			        	</div>
			        </div>}
			    </Container>
		    </Navbar>
}

export const BlackNavBar = ({showNavBtn=true}) => {
	const navigate = useNavigate();
	const location = useLocation();
    const { pathname } = location;
	const { logout, loggedIn, user } = useAuth();
	const [showProfileDrowpdown, setShowProfileDropdown] = useState(false);
	const profileMenuRef = useRef(null);

	const exitProfileMenu = () => {
        console.log('You clicked outside of me!');
        setShowProfileDropdown(false);
    };

    useOutsideClick(profileMenuRef, exitProfileMenu);

	const handleLogout = () => {
		logout();
		navigate('/login');
	}


	const onClickAvatar = () => {
		//navigate("/app/profile");
		setShowProfileDropdown(!showProfileDrowpdown)
	}

	return 	<Navbar bg="black" expand="lg" variant="dark" fixed="top" style={{fontFamily:'Merriweather'}}>
		      	<Container>
			        <Navbar.Brand href="/">
			        	<Link to="/">
				        	<div style={{overflow:'hidden',borderRadius:25,width:50,height:50,borderWidth:3}}>
					          <img
					            src={logo}
					            width="50"
					            height="50"
					            className="d-inline-block align-top"
					            alt="Logo"
					          />
					        </div>
					    </Link>
			        </Navbar.Brand>
			        <Container className="d-block d-sm-block d-md-none d-xl-none"  style={{width:0}}/>
			        <Container className="d-none d-sm-none d-md-block d-xl-block"  style={{width:0}}/>
			        {showNavBtn&&<>
			          {!loggedIn&&<div className="d-flex" style={{alignSelf:'center',paddingLeft:0,paddingRight:0}}>
			            <Button onClick={() => navigate('/signup')} variant="outline-light" className="ml-2 gradient-border-button" style={{borderColor:'#fed700',borderWidth:0, width:105}}>
			            	<Link to="/signup" style={{color:'#fed700',textDecoration:'none'}}>Join Now</Link>
			            </Button>
			            <Button onClick={() => navigate('/login')} variant="outline-light" className="ml-2 gradient-border-button" style={{borderColor:'#fed700',marginLeft:10,borderWidth:0, width:105}}>
			            	<Link to="/login" style={{color:'#fed700',textDecoration:'none'}}>Login</Link>
			            </Button>
			          </div>}
			          {loggedIn&&<div className="nav-items-container d-none d-md-flex d-flex align-items-center justify-content-center">
			            <Link class="nav-items mr-14" to="/app/discover" style={{color:(pathname === '/app/discover'?'#0ac5bf':'')}}>Discover</Link>
			            <Link class="nav-items mr-4" to="/app/groups" style={{color:(pathname === '/app/groups'?'#0ac5bf':'')}}>Groups</Link>
			            <Link class="nav-items mr-4" to="/app/forums" style={{color:(pathname === '/app/forums'?'#0ac5bf':'')}}>Forum</Link>
			            <Link class="nav-items mr-4" to="/app/members" style={{color:(pathname === '/app/members'?'#0ac5bf':'')}}>Members</Link>
			            <Link class="nav-items mr-4" to="/app/buzz" style={{color:(pathname === '/app/buzz'?'#0ac5bf':'')}}>Buzz</Link>
			            <Button class="nav-items" style={{color:'#fed700',borderColor:'#fed700'}} variant="outline-light" className="ml-2">Quick Exit</Button>
			          </div>}
			        </>}
			        {loggedIn&&<div className="d-flex align-items-center justify-content-center">
			        	<div onClick={( )=> navigate("/app/chats")}
			        		style={{color:'#b5edeb',marginRight:10,cursor:'pointer'}}>
			        		<i className="bi bi-envelope"></i>
			        	</div>
			        	<div onClick={( )=> navigate("/app/notifications")}
			        		style={{color:'#b5edeb',marginRight:10,cursor:'pointer'}}>
			        		<i className="bi bi-bell"></i>
			        	</div>
			        	<div>
			        		<MiniAvatar onClick={onClickAvatar}/>
			        		{showProfileDrowpdown&&<div ref={profileMenuRef} style={{padding:'5px 5px',backgroundColor:'#b5edeb',width:140,position:'absolute',zIndex:888,marginLeft:-100,marginTop:5, borderRadius:8 }}>
			        			<div style={{cursor:'pointer'}} onClick={( )=> navigate("/app/profile")}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}>
			        					<i className="bi bi-person" style={{marginRight:4}}></i>Profile</p>
			        			</div>
			        			{(user?.role === "admin")&&<div style={{cursor:'pointer'}} onClick={( )=> navigate("/app/admin")}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}>
			        					<i class="bi bi-grid-1x2" style={{marginRight:4}}></i>Admin Space</p>
			        			</div>}
			        			<div style={{cursor:'pointer'}} onClick={( )=> navigate("/app/settings")}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}><i className="bi bi-gear" style={{marginRight:4}}></i>Settings</p>
			        			</div>
			        			<div style={{cursor:'pointer'}} onClick={( )=> navigate("/app/subscription")}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}><i className="bi bi-receipt" style={{marginRight:4}}></i>Subscription</p>
			        			</div>
			        			<div style={{marginTop:5,borderTop:'1px solid #0ac5bf',cursor:'pointer'}} onClick={()=>handleLogout()}>
			        				<p className="m-2 p-0" style={{color:'#033b39',fontWeight:'bold',fontSize:13}}><i className="bi bi-box-arrow-right" style={{marginRight:4}}></i>Logout</p>
			        			</div>
			        		</div>}
			        	</div>
			        </div>}
			    </Container>
		    </Navbar>
}

export const TransparentNavBar = ({loggedIn=false, showNavBtn=true}) => {
	const navigate = useNavigate();

	return 	<Navbar bg="transparent" expand="lg" variant="dark" fixed="top">
		      	<Container>
			        <Navbar.Brand href="/">
			        	<div style={{overflow:'hidden',borderRadius:25,width:50,height:50}}>
				          <img
				            src={logo}
				            width="50"
				            height="50"
				            className="d-inline-block align-top"
				            alt="Logo"
				          />
				        </div>
			        </Navbar.Brand>
			        <Navbar.Toggle aria-controls="basic-navbar-nav" />
			        {showNavBtn&&<Navbar.Collapse id="basic-navbar-nav" className="d-none d-md-block justify-content-end">
			          {!loggedIn&&<Nav className="ml-auto">
			            <Nav.Link style={{paddingRight:20}}>
			            	<Link to="/" style={{textDecoration:'none',color:'white'}}>About</Link>
			            </Nav.Link>
			            <Button variant="outline-light" className="ml-2" style={{borderColor:'#fed700'}}>
			            	<Link to="/auth/login" style={{color:'#fed700',textDecoration:'none'}}>Login</Link>
			            </Button>
			          </Nav>}
			          {loggedIn&&<Nav className="ml-auto">
			            <Nav.Link onClick={()=>navigate("/app/home")} style={{paddingRight:20}}>Home</Nav.Link>
			            <Nav.Link onClick={()=>navigate("/app/groups")} style={{paddingRight:20}}>Groups</Nav.Link>
			            <Nav.Link onClick={()=>navigate("/app/forums")} style={{paddingRight:20}}>Forum</Nav.Link>
			            <Nav.Link onClick={()=>navigate("/app/members")} style={{paddingRight:20}}>Members</Nav.Link>
			            <Nav.Link onClick={()=>navigate("/app/activities")} style={{paddingRight:20}}>Activities</Nav.Link>
			            <Nav.Link onClick={()=>navigate("/app/buzz")} style={{paddingRight:20}}>Buzz</Nav.Link>
			            <Button style={{color:'#fed700',borderColor:'#fed700'}} variant="outline-light" className="ml-2">Quick Exit</Button>
			          </Nav>}
			        </Navbar.Collapse>}
			    </Container>
		    </Navbar>
}