import React, {useState,useEffect,useRef, useCallback, useMemo} from 'react';
import {Container,Stack, DropdownButton, Dropdown, Nav, Row, Col, Modal, Image, Badge, Card, Button, Form, InputGroup, Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {getRange, getYear, getMonth, getDate} from '../modules.js';
import DatePicker from "react-datepicker";
import {emojis, commentsData} from "../data.js";
import '../css/datepicker.css';
import '../css/switch.css';

export const Switch = (onLabel, offLabel, switchValue, setSwitchValue) => {
    //const [switchValue, setSwitchValue] = useState(false);
    return <div className="switch-container" onClick={() => setSwitchValue(!switchValue)}>
                <div className="switch-background d-flex align-items-center" style={{backgroundColor:(switchValue?'#b5edeb':'transparent')}}>
                    {!switchValue&&<>
                        <div className="switch-circle"></div>
                        <span style={{color:'#0AC5BF',marginLeft:22,fontSize:11}}>{onLabel}</span>
                    </>}
                    {switchValue&&<>
                        <div className="switch-circle" style={{marginLeft:35,fontSize:11}}></div>
                        <span style={{color:'#089d98',fontSize:10,marginLeft:4}}>{offLabel}</span>
                    </>}
                </div>
            </div>
}

export const ProfileListItem = ({username, location, avatarArray, gender, isCouple}) => {
    return  <div className="d-flex align-items-center mb-2">
                <MiniAvatar couple={isCouple} gender={gender}/>
                <div className="ml-2" style={{marginLeft:4}}>
                    <div>{username}</div>
                    <div style={{color:'#444'}}>{location}</div>
                </div>
            </div>
}

export const SelectableTags = ({label="default label", selected=false, setSelected}) => {
    return  <div    onClick={setSelected} 
                    style={{backgroundColor:(selected?'#0ac5bf':'#b5edeb'),
                            padding:'4px 7px',
                            borderRadius:4,
                            cursor:'pointer',
                            fontSize:11,
                            marginRight:3}}
                    className="flex-shrink-0 mb-1 mr-1">
                <>{label}</>
            </div>
}


export const MyCheckBox = ({cbState=false, setCbState}) => {
    return  <div className="flex-shrink-0" 
                onClick={() => setCbState(!cbState)} 
                style={{width:30,height:30,borderRadius:15,textAlign:'center',
                        backgroundColor:(cbState===true?'#0ac5bf':'transparent'),
                        color:'white',fontSize:28,border:'2px solid #0ac5bf',
                        display:'flex',alignItems:'center',justifyContent:'center',
                        cursor:'pointer',marginRight:5}}>
                {cbState&&<i className="bi bi-check"></i>}
            </div>
}

export const MyRadioInputs = ({label="Gender at birth", keyArr=['male','female', 'other'], valueArr=[false,false,false], onSelect, textStyle={color:'#999'}, labelStyle={color:'#888'}, btnStyle={width:20,height:20,borderRadius:10} }) => {
    const update = (index) => {
        let arr = valueArr;
        for (let i = 0; i < arr.length; i++){ 
            arr[i] = false;
        }
        arr[index] = true;
        onSelect([...arr])
    }

    return  <>
            <label style={labelStyle}>{label}</label>
            <Row className="d-flex align-items-center mt-3 mb-3" style={{width:'100%'}}>
                {keyArr.map((value, index) => <Col xs={12} md={4} className="d-flex align-items-center">
                    <div className="flex-shrink-0" 
                         onClick={() => update(index)} 
                         style={{backgroundColor:'white',
                                 color:'#0ac5bf',fontSize:9,border:'3px solid #0ac5bf',
                                 display:'flex', alignItems:'center',justifyContent:'center',
                                 cursor:'pointer',marginRight:5, padding:'3px', ...btnStyle}}>
                        {valueArr[index]&&<i className="bi bi-circle-fill"></i>}
                    </div>
                    <span style={textStyle}>{value}</span>
                </Col>)}
            </Row>
            </>
}

export const AltMyRadioInputs = ({label="Gender at birth", keyArr=['male','female', 'other'], valueArr=[false,false,false], onSelect, textStyle={color:'white'}, labelStyle={color:'#888'}, btnStyle={width:20,height:20,borderRadius:10} }) => {
    const update = (index) => {
        let arr = valueArr;
        for (let i = 0; i < arr.length; i++){ 
            arr[i] = false;
        }
        arr[index] = true;
        onSelect([...arr])
    }

    return  <>
            <label style={labelStyle}>{label}</label>
            <Row className="d-flex align-items-center mt-3 mb-3" style={{width:'100%'}}>
                {keyArr.map((value, index) => <Col xs={4} md={3} className="d-flex align-items-center">
                    <div className="flex-shrink-0" 
                         onClick={() => update(index)} 
                         style={{textAlign:'center',
                                 backgroundColor:valueArr[index]?'#0ac5bf':'transparent',
                                 color:'white',fontSize:9,border:'3px solid #0ac5bf',
                                 display:'flex',alignItems:'center',justifyContent:'center',
                                 cursor:'pointer',marginRight:5, ...btnStyle}}>
                        {valueArr[index]&&<i className="bi bi-circle-fill"></i>}
                    </div>
                    <span style={textStyle}>{value}</span>
                </Col>)}
            </Row>
            </>
}

export const ProfileAvatar = ({imageUrl, onClick, couple=false,gender="male-female",isOnline=true}) => {
    const [_width, setWidth] = useState(80)
    const genderColorCodes = (_gender) => {
        let color;
        if(_gender === 'male'){
            color = '#0d6efd';
        }else if(_gender === 'female'){
            color = 'purple';
        }else{
            color = '#ccc';
        }
        return color;
    }

    useEffect(() => {
        if(couple){
            setWidth(140);
        }else{
            setWidth(80);
        }
    },[couple])

    return  <div style={{width:_width,height:80,marginTop:-40}} className="flex-shrink-0">
                <div className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{cursor:'pointer',
                            width: '80px',
                            height: '80px',
                            backgroundColor:'#b5edeb',
                            borderRadius: '50%',
                            position: 'relative',
                            border:gender?`2px solid ${genderColorCodes(gender?.split('-')[0])}`:''}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:38, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                    {isOnline && <i class="bi bi-circle-fill" style={{color:'green',position:'absolute',top:5,right:0,fontSize:15}}></i>}
                </div>
                {couple&&<>
                <div className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{width:'80px',
                             height:'80px', 
                             backgroundColor:'#b5edeb',
                             borderRadius:'50%',
                             marginLeft:55,marginTop:-80,
                             position:'absolute',
                             border:gender?`2px solid ${genderColorCodes(gender?.split('-')[1])}`:''}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:38, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                    {isOnline && <i class="bi bi-circle-fill" style={{color:'green',position:'absolute',top:5,right:0,fontSize:15}}></i>}
                </div>
                </>}
            </div>
}

export const MiniAvatar = ({imageUrl, onClick, couple=false,gender}) => {
    const genderColorCodes = (_gender) => {
        let color;
        if(_gender === 'male'){
            color = '#0d6efd';
        }else if(_gender === 'female'){
            color = 'purple';
        }else{
            color = '#ccc';
        }
        return color;
    }
    return  <div style={{width:(couple?70:40),height:40}}>
                <div onClick={onClick} className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{cursor:'pointer',
                            width: '40px',
                            height: '40px',
                            backgroundColor:'#b5edeb',
                            borderRadius: '50%',
                            position: 'relative',
                            border:gender?`2px solid ${genderColorCodes(gender?.split('-')[0])}`:''}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:22, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                </div>
                {couple&&<>
                <div onClick={onClick} className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{width:'40px',
                             height:'40px', 
                             backgroundColor:'#b5edeb',
                             borderRadius:'50%',
                             marginLeft:30,marginTop:-40,
                             position:'absolute',
                             border:gender?`2px solid ${genderColorCodes(gender?.split('-')[1])}`:''}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:22, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                </div>
                </>}
            </div>

}

export const Avatar = ({imageUrl, onClick, couple=false, gender}) => {
    const genderColorCodes = (_gender) => {
        let color;
        if(_gender === 'male'){
            color = '#0d6efd';
        }else if(_gender === 'female'){
            color = 'purple';
        }else{
            color = '#ccc';
        }
        return color;
    }
    return  <div style={{width:(couple?105:60),height:60}}>
                <div onClick={onClick} className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{width: '60px',
                             height: '60px',
                             backgroundColor: '#b5edeb', 
                             borderRadius: '50%', 
                             position: 'relative',
                             border:gender?`2px solid ${genderColorCodes(gender?.split('-')[0])}`:''}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:28, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                </div>
                {couple&&<>
                <div onClick={onClick} className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{width:'60px', 
                             height:'60px', 
                             backgroundColor:'#b5edeb', 
                             borderRadius:'50%', 
                             marginLeft:45, top:0, 
                             position:'absolute',
                             border:gender?`2px solid ${genderColorCodes(gender?.split('-')[1])}`:''}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:28, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                </div>
                </>}
            </div>
}

export const LgAvatar = ({imageUrl,couple=false,gender}) => {
    const genderColorCodes = (_gender) => {
        let color;
        if(_gender === 'male'){
            color = '#0d6efd';
        }else if(_gender === 'female'){
            color = 'purple';
        }else{
            color = '#ccc';
        }
        return color;
    }
    return  <div style={{width:(couple?165:100),height:100}}>
                <div className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{width:'100px', 
                             height:'100px', 
                             backgroundColor:'#b5edeb', 
                             borderRadius:'50%', 
                             position:'relative',
                             border:`2px solid ${genderColorCodes(gender?.split('-')[0])}`}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:38, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                </div>
                {couple&&<>
                <div className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" 
                     style={{width: '100px', 
                            height: '100px', 
                            backgroundColor: '#b5edeb', 
                            borderRadius: '50%', 
                            position: 'absolute',
                            marginLeft:65,marginTop:-100,
                            border:`2px solid ${genderColorCodes(gender?.split('-')[1])}`}}>
                    {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:38, color:'#0ac5bf'}}></i>}
                    {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
                </div>
                </>}
            </div>
}

export const XxlgAvatar = ({imageUrl}) => {
    return  <div className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" style={{width: '200px', height: '200px', backgroundColor: '#b5edeb', borderRadius: '50%', position: 'relative'}}>
                {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:94, color:'#0ac5bf'}}></i>}
                {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
            </div>
}

export const CardButton  = ({className="bi-hand-thumbs-up",label="", onPickReaction, onClick}) => {
	return <Button className="d-flex" style={{borderWidth:0,
                            color:'#0ac5bf',
                            backgroundColor:'transparent',
                            fontSize: '0.7rem'}} onClick={onClick}>
                <i onClick={onPickReaction} className={`bi ${className}`}></i>
                <span style={{marginLeft:3}}>{label}</span>
            </Button>
}

export const CommentItem = ({data}) => {
    return  <div style={{fontSize:11}} className="mb-2 d-flex align-items-center">
                <MiniAvatar imageUrl={data?.avatar}/>
                <div style={{marginLeft:4}}>
                    <div>
                        <span style={{marginRight:4}}><b>{data?.username}</b></span><span>{data?.comment}</span>
                    </div>
                    <div>
                        <span style={{marginRight:10,color:'#0ac5bf',cursor:'pointer'}}><i className="bi bi-reply"></i></span>
                        <span style={{marginRight:10,color:'#0ac5bf',cursor:'pointer'}}><i className="bi bi-emoji-smile"></i></span>
                        <span style={{color:'#0ac5bf',cursor:'pointer'}}><i className="bi bi-flag"></i></span>
                    </div>
                </div>
            </div>
}

export const PostCard = ({post}) => {
    const [isLiked, setIsLiked] = useState(false);
    const [reactions, setReactions] = useState(post.likes);
    const [comments, setComments] = useState(post.comments);
    const [shares, setShares] = useState(post.shares);
    const [showReactionlist, setShowReactionlist] = useState(false);
    const [showCommentSection, setShowCommentSection] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [rpt1, setRpt1] = useState(false);
    const [rpt2, setRpt2] = useState(false);
    const [rpt3, setRpt3] = useState(false);
    const reactionArr = emojis;

    const onPickReaction = useCallback(() => {
        setShowReactionlist(!showReactionlist)
    },[showReactionlist, setShowReactionlist]);

    const target = useRef(null);

    return  <Card className="mb-1">
                <Card.Header className="d-flex align-items-center" style={{backgroundColor:'transparent',borderBottomWidth:0}}>
                    <Avatar imageUrl={post.user.avatar} />
                    <div style={{marginLeft:5}}>
                        <div style={{fontStyle:16,fontWeight:'bold'}}>{post.user.username}</div>
                        <div className="text-muted" style={{fontSize:11}}>
                            <span>{post.createdAt}</span>
                            <span style={{marginLeft:4}}><i className="bi bi-globe-europe-africa"></i></span>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body className="card-body pr-3 pl-3 pt-0 pb-0">
                    <Card.Text class="pt-0 pb-0 mb-1" style={{fontSize:13,paddingLeft:10,paddingRight:10}}>{post.content}</Card.Text>
                    {post.image &&<Container class="pt-0 pb-0 mt-0 mb-0" style={{paddingLeft:10,paddingRight:10}} className="d-flex align-items-center justify-content-center" fluid>
                        <Image src={post.image} fluid />
                    </Container>}
                </Card.Body>
                <Card.Footer className="d-flex" style={{backgroundColor:'transparent',borderTopWidth:0}}>
                    <>  
                        <CardButton ref={target} className="bi-emoji-smile-fill" label={`${reactions} reactions`} onPickReaction={onPickReaction}/>
                        {showReactionlist&&<div style={{border:'1px solid #0ac5bf',padding:'5px 5px',backgroundColor:'white',width:150,maxHeight:150, overflow:'scroll', display:'flex', flexWrap:'wrap', position:'absolute',zIndex:88888888,marginTop:30, borderRadius:8 }}>
                            <>{reactionArr.map((value, index) => (<div style={{cursor:'pointer'}}>
                                <span onClick={() => setShowReactionlist(false)} className="m-2 p-0" style={{color:'#0ac5bf',fontWeight:'bold',fontSize:13}}>{value.emoji}</span>
                            </div>))}</>
                        </div>}
                    </>
                    <>
                        <CardButton onClick={() => setShowCommentSection(!showCommentSection)} 
                                    className="bi-chat-left-text" label={`${commentsData.length} Comments`}/>
                        {showCommentSection&&<Modal show={showCommentSection} onHide={() => setShowCommentSection(false)} centered>
                            <Modal.Header closeButton>
                              <Modal.Title style={{fontSize:14}}>Comments ({commentsData.length})</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {commentsData.map((data,index) => {
                                    return <CommentItem data={data}/>
                                })}
                            </Modal.Body>
                            <Modal.Footer>
                              <Form.Control placeholder="Comments"
                                            aria-label="write your comment..."
                                            aria-describedby="basic-addon1"
                                            style={{fontSize:12}}
                                            />
                            </Modal.Footer>
                        </Modal>}
                    </>
                    <>
                        <CardButton onClick={()=>setShowReportModal(!showReportModal)} className="bi-flag" label="Report"/>
                        {showReportModal&&<Modal show={showReportModal} onHide={() => setShowReportModal(false)} centered>
                            <Modal.Header closeButton>
                              <Modal.Title style={{fontSize:14}}>Report</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex align-items-center mb-1">
                                    <span>
                                        <MyCheckBox cbState={rpt1} setCbState={setRpt1} />
                                    </span>
                                    <span>Obscene/abusive language</span>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <span>
                                        <MyCheckBox cbState={rpt2} setCbState={setRpt2} />
                                    </span>
                                    <span>Off topic/Spamming</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span>
                                        <MyCheckBox cbState={rpt3} setCbState={setRpt3} />
                                    </span>
                                    <span>Trolling, not contributing constructively...</span>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary">Cancel</Button>
                                <Button style={{backgroundColor:'#0ac5bf',borderColor:'#0ac5bf'}} variant="primary">Report</Button>
                            </Modal.Footer>
                        </Modal>}
                    </>
                </Card.Footer>
            </Card>
}

export const CreatePostInput = ({CurrentUserData={username:'eli'}, disablePrivacy}) => {
    const [publik, setPublik] = useState(true);

    return <Card className="mb-1">
                <Card.Body className="p-0">
                    <InputGroup style={{borderRadius: 0}}>
                        <div class="d-flex" style={{width:55,height:55,justifyContent:'center',alignItems:'center'}}>
                            <MiniAvatar />
                        </div>
                        <Form.Control 	as="textarea"
                                        placeholder={`What's on your mind ${CurrentUserData?.username}?`}
                                        aria-label="Username"
                                        style={{ resize: 'none', borderWidth:0, fontSize:13 }}
                                        aria-describedby="basic-addon1"/>
                        <DropdownButton variant="outline-none" className="privacyBtn" title={publik?<i class="bi bi-globe-europe-africa" 
                                                                            style={{color:'#0AC5BF'}}></i>
                                                                        :<i class="bi bi-lock"
                                                                            style={{color:'#0AC5BF'}}></i>}
                                        id="input-group-dropdown-1"
                                        disabled={disablePrivacy}
                                        style={{color:'#0AC5BF',borderWidth:0,borderColor:'#eee',backgroundColor:'transparent'}}>
                            <Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-globe-europe-africa"></i> All member</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-people-fill"></i> All member</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPublik(false)}><i class="bi bi-lock"></i> Only me</Dropdown.Item>
                        </DropdownButton>
                        <Button variant="outline-secondary" style={{borderWidth:0}}>
                            <i className="bi bi-paperclip" style={{fontSize:16}}></i>
                        </Button>
                        <Button variant="outline-secondary" style={{borderWidth:0,fontSize:16}}>
                            Post
                        </Button>
                    </InputGroup>
                </Card.Body>
            </Card>
}

export const SubGroupCard = ({podcastsImage="",label="label", privacyStatus=false}) => {
    return  <Card className="mt-2" 
                    style={{padding:0,borderWidth:0,
                            overflow:'hidden',backgroundColor:false?'#cef3f2':'black'}}>
                <div style={{height:150}}>
                    <Image src={podcastsImage} style={{height:150,marginLeft:'-50%'}}/>
                </div>
                <div style={{width:'100%',height:60, display:'flex',justifyContent:'center'}}>
                    <div style={{position:'absolute',
                                backgroundColor:'white',
                                marginLeft:10, 
                                width:330,
                                height:40,
                                marginTop:-20,
                                borderRadius:5}}>
                        <div style={{position:'absolute',
                                    backgroundColor:(!privacyStatus?'#0AC5BF':'#f7451e'),
                                    color:'white',
                                    borderTopRightRadius:4,
                                    borderTopLeftRadius:4,
                                    top:-16,right:0,zIndex:888,
                                    fontSize:11,
                                    padding:'0px 3px'}}>{!privacyStatus?"Public":"Private"}</div>
                        <div style={{width:'100%',textAlign:'center',height:'100%',padding:'8px 0'}}>{label}</div>
                        <div style={{position:'absolute',
                                    backgroundColor:'white',
                                    width:15,height:15,
                                    transform:'rotate(45deg)',
                                    bottom:-4,left:10,zIndex:888}}/>
                    </div>
                </div>
            </Card>
}

export const ForumCard = ({title="Title",label="label",description="description",discussions}) => {
    return <Card className="mt-2" 
                    style={{padding:0,borderWidth:0,
                            overflow:'hidden',backgroundColor:'#efefef'}}>
                <div style={{minHeight:120, maxHeight:170, backgroundColor:'black',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <h1 style={{color:'#fed700'}}>{title}</h1>
                </div>
                <div style={{width:'100%',height:30, display:'flex',justifyContent:'center'}}>
                    <div style={{position:'absolute',
                                backgroundColor:'white',
                                marginLeft:10, 
                                width:330,
                                height:40,
                                marginTop:-20,
                                borderRadius:5}}>
                        <div style={{width:'100%',textAlign:'center',height:'100%',padding:'8px 0',color:'#0AC5BF'}}>{label}</div>
                        <div style={{position:'absolute',
                                    backgroundColor:'white',
                                    width:15,height:15,
                                    transform:'rotate(45deg)',
                                    bottom:-4,left:10}}/>
                    </div>
                </div>
                <div style={{width:'100%',minHeight:30,display:'flex',justifyContent:'center'}}>
                    <div style={{width:300,fontSize:11}}>
                        <div style={{fontStyle:'italic'}}>{description}</div>
                        <div style={{color:'#999',paddingBottom:10}}>{discussions.length>0?`${discussions.length} discussions`:'No discussions'}</div>
                    </div>
                </div>
            </Card>
}



export const CustomDatePicker = ({dateValue="01/01/1999", setDateValue}) => {
 
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(dateValue);
  const years = getRange(1924,2000,1);
  const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const monthsNotation = useMemo(() => ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"], []);

  useEffect(() =>{
    //console.log(`${monthsNotation[getMonth(startDate)]}-${getDate(startDate)<10?'0':''}${getDate(startDate)}${getYear(startDate)}`);
    setDateValue(`${monthsNotation[getMonth(startDate)]}-${getDate(startDate)<10?'0':''}${getDate(startDate)}-${getYear(startDate)}`);
  },[setDateValue, monthsNotation, startDate]);

  useEffect(() => {
    console.log(dateValue);
  },[dateValue])



  return (
    <>
        <DatePicker inline renderCustomHeader={({date,changeYear,changeMonth,decreaseMonth,increaseMonth,prevMonthButtonDisabled,nextMonthButtonDisabled}) => (
            <>
                <div style={{margin:10,display:"flex",justifyContent:"center"}}>
                    <Button style={{backgroundColor:'#b5edeb',borderColor:'transparent',marginRight:10}} 
                            onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        <i class="bi bi-chevron-left" style={{color:'#033b39'}}></i>
                    </Button>
                    <select value={getYear(date)} 
                            style={{marginRight:5,backgroundColor:'#b5edeb',borderColor:'transparent',borderRadius:4,color:'#033b39'}}
                            onChange={({ target: { value } }) => changeYear(value)}>
                            {years.map((option) => (<option key={option} value={option}>{option}</option>))}
                    </select>
                    <select value={months[getMonth(date)]} 
                            style={{marginLeft:5,backgroundColor:'#b5edeb',borderColor:'transparent',borderRadius:4,color:'#033b39'}}
                            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                            {months.map((option) => (<option key={option} value={option}>{option}</option>))}
                    </select>
                    <Button style={{backgroundColor:'#b5edeb',borderColor:'transparent',marginLeft:10}} 
                            onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        <i class="bi bi-chevron-right" style={{color:'#033b39'}}></i>
                    </Button>
                </div>
            </>
        )}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        className="custom-datepicker"/>
    </>
  );
};

export const MemberProfileCard = ({avatar="", username="eli", age="32", firstMove="open", interaction="eroticism", location="Nairobi, Kenya", gender="male", profileType="single"}) => {
    const genderColorCodes = (_gender) => {
        let color;
        if(_gender === 'male'){
            color = '#0d6efd';
        }else if(_gender === 'female'){
            color = 'purple';
        }else{
            color = '#ccc';
        }
        return color;
    }
    return <Card className="mt-3" style={{color:'white',minWidth:340,maxWidth:410,overflow:'hidden'}}>
                        <div style={{minHeight:100,maxHeight:120,background:'#999',overflow:'hidden',display:'grid',gap:10,gridTemplateColumns:'repeat(auto-fill, minmax(20px, 1fr))'}}>
                            {Array.from({ length: 100 },(value, index) => {
                                let angle = Math.random() * 360;
                                return <i key={index} 
                                            className="bi bi-image" 
                                            style={{transform:`rotate(${angle}deg)`,color:'#888'}}></i>
                             })}
                        </div>
                        <div className="profile-card-container d-flex align-items-center justify-content-center" 
                                style={{backgroundColor:'black',minHeight:160}}>
                            <div className="inner-container" 
                                    style={{position:'absolute',
                                            minHeight:160,
                                            marginTop:-100,
                                            width:'100%',
                                            padding:'0 10px',
                                            }}>
                                <div className="d-flex align-items-center" style={{minHeight:140}}>
                                    <div className="d-flex" style={{alignSelf:'start',marginTop:20}}>
                                        <LgAvatar couple={profileType==='couple'?true:false} gender={gender} />
                                    </div>
                                    <div style={{marginLeft:5,alignSelf:'end',height:65}}>
                                        <div style={{fontSize:12,fontWeight:'bold'}}>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">{username.split('-')[0]} {profileType==='couple'?' &':''}</div>
                                                {profileType!=='couple'&&<i class="bi bi-patch-check-fill" style={{color:'#fed700',marginLeft:4}}></i>}
                                            </div>
                                            {profileType==='couple'&&<div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">{username.split('-')[1]}</div>
                                            <i class="bi bi-patch-check-fill" style={{color:'#fed700',marginLeft:4}}></i>
                                            <div className="flex-shrink-0" style={{border:`2px solid ${genderColorCodes(gender?.split('-')[0])}`,
                                                                                    color:genderColorCodes(gender?.split('-')[0]),
                                                                                    width:20,
                                                                                    height:20,
                                                                                    borderRadius:10,
                                                                                    textAlign:'center',
                                                                                    fontSize:10,marginLeft:4}}>{(typeof age === "string")?age.split('-')[0]:age}</div>
                                            <div className="flex-shrink-0" style={{border:`2px solid ${genderColorCodes(gender?.split('-')[1])}`,
                                                                                    color:genderColorCodes(gender?.split('-')[1]),
                                                                                    width:20,
                                                                                    height:20,
                                                                                    borderRadius:10,
                                                                                    textAlign:'center',
                                                                                    fontSize:10,marginLeft:4}}>{(typeof age === "string")?age.split('-')[1]:age}</div>
                                            </div>}
                                        </div>
                                        <div className="d-flex align-items-center" 
                                             style={{fontSize:12,fontWeight:'bold',marginTop:5}}>
                                            <div className="flex-shrink-0" style={{color:'#fed700',
                                                        border:'2px solid #fed700',
                                                        borderRadius:8,
                                                        padding:'0 5px',
                                                        cursor:'pointer'}}>
                                                <i class="bi bi-person-plus-fill" style={{color:'#fed700',marginLeft:4}}></i>
                                                <>Connect</>
                                            </div>
                                            <div className="flex-shrink-0" style={{color:'#fed700',
                                                        border:'2px solid #fed700',
                                                        borderRadius:8,
                                                        padding:'0 5px',
                                                        marginLeft:5,
                                                        cursor:'pointer'}}>
                                                <i class="bi bi-person-plus-fill" style={{color:'#fed700',marginLeft:4}}></i>
                                                <>Follow</>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{fontSize:12,marginLeft:5,marginTop:5}}>
                                    <div style={{color:'#ccc'}}>
                                        <b style={{color:'#fed700'}}>First move:</b><>{firstMove}</>
                                    </div>
                                    <div style={{color:'#ccc'}}>
                                        <b style={{color:'#fed700'}}>Interaction:</b><>{interaction}</>
                                    </div>
                                    <div style={{color:'#ccc'}}>
                                        <i class="bi bi-geo-alt-fill" style={{color:'#fed700'}}></i><>{location}</>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
}

