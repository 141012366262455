import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack,Row,Col,Card,Image,Form,InputGroup, Button} from 'react-bootstrap';
import {SelectableTags, LgAvatar, MemberProfileCard } from '../components/ModularComponents';
import {useNavigate} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useOutsideClick } from '../hooks/useOutsideClick';
import headerImage from '../assets/websiteImage.png';


export const Members = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [rangeValue, setRangeValue] = useState(50);
	const [tag1, setTag1] = useState(false);
	const [tag2, setTag2] = useState(false);
	const [tag3, setTag3] = useState(false);
	const [tag4, setTag4] = useState(false);
	const [tag5, setTag5] = useState(false);
	const [tag6, setTag6] = useState(false);
    const members = [{username:'jenni',location:'Nairobi, Kenya',gender:'female',age:25,profileType:'single'},
					 {username:'julie',location:'Nakuru, Kenya',gender:'female',age:23,profileType:'single'},
					 {username:'oscar',location:'Kiambu, Kenya',gender:'male',age:28,profileType:'single'},
					 {username:'romio-juliette',location:'Mombasa, Kenya',gender:'male-female',age:'31-26',profileType:'couple'}]

	return 	<div>
				<div className="w-100 p-0 col-12 d-flex align-items-center" 
					 style={{backgroundColor:'black',
								color:'white',
								height:'80vh'}}>
					<Container className="d-flex align-items-center justify-content-center">
						<Row>
							<Col xs={12} md={6}>
								<Card style={{backgroundColor:'black',fontSize:11}}>
									<Card.Body className="pb-0 d-flex align-items-center flex-wrap">
										<SelectableTags label="Show Basic Info" selected={tag1} setSelected={()=>setTag1(!tag1)}/>
										<SelectableTags label="Show Recreation Info" selected={tag2} setSelected={()=>setTag2(!tag2)}/>
										<SelectableTags label="Show Erotic Play Info" selected={tag3} setSelected={()=>setTag3(!tag3)}/>
										<SelectableTags label="Show Erotic Adventure Info" selected={tag4} setSelected={()=>setTag4(!tag4)}/>
										<SelectableTags label="Show Sexual Health Info" selected={tag5} setSelected={()=>setTag5(!tag5)}/>
										<SelectableTags label="Show Recreational Championship" selected={tag6} setSelected={()=>setTag6(!tag6)}/>
									</Card.Body>
									<Card.Body>
										<InputGroup className="mb-3">
									      <Form.Label style={{color:'#ccc',fontSize:11}}>Kilometers radius: {rangeValue}km</Form.Label>
									      <Form.Range value={rangeValue} onChange={(e) => setRangeValue(e.target.value)} />
									    </InputGroup>
									    <InputGroup className="mb-3">
									    	<Form.Control placeholder="City/Town" style={{fontSize:11}}/>
									    </InputGroup>
									    <InputGroup className="mb-3">
									    	<Form.Select aria-label="Default select example" style={{fontSize:11}}>
										      <option>Turn Ons</option>
										      <option value="1">0-6 Low Sensitivity to turn ons.</option>
										      <option value="1">7-13 Medium Sensitivity to turn ons.</option>
										      <option value="1">14-24 High Sensitivity to turn ons.</option>
										    </Form.Select>
									    	<Form.Select aria-label="Default select example" style={{fontSize:11}}>
										      <option>Turn Offs</option>
										      <option value="1">0-6 Low Sensitivity to turn offs.</option>
										      <option value="1">7-13 Medium Sensitivity to turn offs.</option>
										      <option value="1">14-24 High Sensitivity to turn offs.</option>
										    </Form.Select>
									    </InputGroup>
									    <Button className="w-100 gradient-border-button" onClick={()=>navigate("/signup")} style={{borderWidth:0, backgroundColor:'transparent', color:'#fed700',padding:'15px 40px 15px 40px'}}>
								          Search
								        </Button>	
									</Card.Body>
								</Card>
							</Col>
							<Col xs={12} md={6} className="mt-4 d-flex align-items-center d-none d-md-block">
								<Image src={headerImage} fluid/>
							</Col>
						</Row>
					</Container>
				</div>
				<Container>
					<Row>
						{members.map((value, index) => {
							let _username = value.username;
							let _location = value.location;
							let _gender = value.gender;
							let _age = value.age
							let _profile_type = value.profileType;
							
							return 	<Col xs={12} md={6} xl={4}>
			        					<MemberProfileCard  username={_username} 
			        										location={_location} 
			        										gender={_gender} 
			        										age={_age}
			        										profileType={_profile_type}/>
			        				</Col>
						})}
        			</Row>
      			</Container>
			</div>
}