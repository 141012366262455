import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack,Row,Col,Card,Image,Form,InputGroup, Button} from 'react-bootstrap';
import {SelectableTags, LgAvatar, MemberProfileCard,SubGroupCard,ForumCard} from '../components/ModularComponents';
import {useNavigate} from 'react-router-dom';
import headerImage from '../assets/websiteImage.png';
import podcastsImage from '../assets/podcastsImage.png';
import sexualOrientation from '../assets/sexualOrientation.png';
import kink from '../assets/kink.png';


export const Discover = () => {
  const navigate = useNavigate();
  const [tag1, setTag1] = useState(false);
  const [tag2, setTag2] = useState(false);
  const [tag3, setTag3] = useState(false);
  const [tag4, setTag4] = useState(false);
  const [tag5, setTag5] = useState(false);
  const [tag6, setTag6] = useState(false);
  const members = [{username:'jenni',location:'Nairobi, Kenya',gender:'female',age:25,profileType:'single'},
                   {username:'julie',location:'Nakuru, Kenya',gender:'female',age:23,profileType:'single'},
                   {username:'romio-juliette',location:'Mombasa, Kenya',gender:'male-female',age:'31-26',profileType:'couple'}];
  const groups = [{title:'Podcasts/Vlogs',image:podcastsImage},
                  {title:'Sexual Orientation', image:sexualOrientation},
                  {title:'Kink', image:kink, privacyStatus:true}];
  const forums = [{title:'Myths',subtitle:'Debunk myths and misconception',description:'',discussions:[]},
                  {title:'Random',subtitle:'Diverse conversations',description:'For discussions that don\'t fit into specific forum categories but are still relevant and valuable to the circle.',discussions:[]},
                  {title:'Humor',subtitle:'Funny or lighthearted',description:'',discussions:[]}];


  return  <div>
        <div className="w-100 p-0 col-12 d-flex align-items-center" 
           style={{backgroundColor:'black',
                   color:'white',
                   height:'80vh'}}>
          <Container className="d-flex align-items-center justify-content-center">
            <Row>
              <Col xs={12} md={6} className="d-flex align-items-center">
                <h1 style={{color:'#fed700'}}>Discover, Explore, Experience...</h1>
              </Col>
              <Col xs={12} md={6} className="mt-4 d-flex align-items-center">
                <Image src={headerImage} fluid/>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="pt-4 pb-4">
          <Card style={{border:'0px solid transparent'}}>
            <h4 style={{color:'#0AC5BF'}}>Forums</h4>
            <Row style={{minHeight:30}}>
              {forums.map(({title, subtitle, description, discussions},index) => {
                return  <Col xs={12} md={6} xl={4}>
                      <ForumCard title={title} label={subtitle} description={description} discussions={discussions.length}/>
                    </Col>
              })}
            </Row>
            <div style={{textAlign:'right',
                         color:'#0AC5BF',
                         cursor:'pointer',
                         marginTop:5,
                         padding:4}}
                  onClick={() => navigate('/app/forums')}>View more...</div>
          </Card>
          <Card style={{border:'0px solid transparent'}}>
            <h4 style={{color:'#0AC5BF'}}>Groups</h4>
            <Row style={{minHeight:30}}>
              {groups.map(({title, image, privacyStatus},index) => {
                return  <Col xs={12} md={6} xl={4}>
                      <SubGroupCard label={title} podcastsImage={image} privacyStatus={privacyStatus}/>
                    </Col>
              })}
            </Row>
            <div onClick={() => navigate('/app/groups')} style={{textAlign:'right',color:'#0AC5BF',cursor:'pointer',marginTop:5,padding:4}}>View more...</div>
          </Card>
          <Card style={{border:'0px solid transparent'}}>
            <h4 style={{color:'#0AC5BF'}}>Featured Members</h4>
            <Row className="members-container">
              {members.map((value, index) => {
                let _username = value.username;
                let _location = value.location;
                let _gender = value.gender;
                let _age = value.age
                let _profile_type = value.profileType;
                
                return  <Col xs={12} md={6} xl={4}>
                          <MemberProfileCard  username={_username} 
                                    location={_location} 
                                    gender={_gender} 
                                    age={_age}
                                    profileType={_profile_type}/>
                        </Col>
              })}
            </Row>
            <div onClick={() => navigate('/app/members')} style={{textAlign:'right',color:'#0AC5BF',cursor:'pointer',marginTop:5,padding:4}}>View more...</div>
          </Card>
        </Container>
      </div>
}