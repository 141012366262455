import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {MiniAvatar} from '../components/ModularComponents';
import {UserSignupChart} from '../components/UsersChart';
import {GenderRatioChart} from '../components/GenderChart';
import {MonthlyGenderRegistrationChart} from '../components/RegistrationByGenderChart.js';

const ReportedUserItem = ({username, reason}) => {
	return 	<div className="p-2 mb-2" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer'}}>
				<div className="d-flex align-items-center mb-2">
					<MiniAvatar/>
					<div style={{marginLeft:4}}> <span>{username}</span> </div>
					<div style={{display:'flex',alignItems:'center',justifyContent:'end',width:'100%'}}>
						<div style={{width:170}}>
							<b>{reason}</b>
						</div>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-end" style={{color:'red'}}>
					<span style={{color:'orange',marginRight:10}}><i className="bi bi-exclamation-octagon"></i> Send Warning</span>
					<span style={{color:'red'}}><i className="bi bi-dash-circle"></i> Terminate</span>
				</div>
			</div>
}

export const AdminDashboard = () => {
	const userData = [0, 0, 0, 0, 0, 2, 10, 0, 0, 0, 0, 0];
	const genderData = [300, 450, 5];
	const registrationData = {male: [20, 30, 50, 40, 60, 70, 90, 100, 110, 130, 140, 150],
							  female: [25, 35, 45, 55, 65, 75, 85, 95, 105, 115, 125, 135],
							  other: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]};
	const reportedUsers = [{username:'julie', reason:'Obscene/abusive language'},
						   {username:'jackie', reason:'Off topic/Spamming'},
						   {username:'ryan', reason:'Trolling, not contributing constructively...'}];

	return <Container className="pt-3">
				<Card style={{marginBottom:10}}>
	                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
	                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Admin Dashboard</Card.Text>
	                </Card.Header>
	                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:12,color:'#033b39'}}>
	                    <div className="p-2 mb-2 d-flex align-items-center" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer'}}>
            				<span><i className="bi bi-people"></i> Total number of users: <b>11</b></span>
            			</div>
            			<div className="p-2 mb-2 d-flex align-items-center" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer'}}>
            				<span><i className="bi bi-people"></i> Active weekly users: <b>7</b></span>
            			</div>
            			<div className="p-2 mb-2 d-flex align-items-center" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer'}}>
            				<span><i className="bi bi-people"></i> Online users: <b>3</b></span>
            			</div>
            			<div className="d-flex align-items-center justify-content-center">
            				<Row className="d-flex justify-content-center">
		            			<Col xs={12}>
		            				<MonthlyGenderRegistrationChart data={registrationData}/>
		            			</Col>
		            			<Col xs={12} style={{maxWidth:600,marginTop:20}}>
		            				<GenderRatioChart data={genderData}/>
		            			</Col>
		            		</Row>
	            		</div>
	                </Card.Body>
	            </Card>
	            <Card style={{marginBottom:10}}>
	                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
	                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Reported Users</Card.Text>
	                </Card.Header>
	                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:12,color:'#033b39'}}>
	                    {reportedUsers.map(({username, reason},index) => <ReportedUserItem username={username} reason={reason}/>)}
	                </Card.Body>
	            </Card>
			</Container>
}