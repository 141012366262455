import React from 'react';
import {OurStory} from "./layouts/OurStory";
import {Signup} from "./layouts/Signup";
import {Login} from "./layouts/Login";
import {Checkout} from "./layouts/Checkout";
import {Subscription} from "./layouts/Subscription";
import {Profile} from "./layouts/Profile";
import {Timeline} from './layouts/profilePages/Timeline';
import {Info} from './layouts/profilePages/Info';
import {Connections} from './layouts/profilePages/Connections';
import {Followers} from './layouts/profilePages/followers';
import {Circles} from './layouts/profilePages/Circles';
import {GroupsJoined} from './layouts/profilePages/Groups';
import {ForumsJoined} from './layouts/profilePages/Forums';
import {Albums} from './layouts/profilePages/Albums';
import {Documents} from './layouts/profilePages/Documents';
import {Settings} from "./layouts/Settings";
import {Forums} from "./layouts/Forums";
import {Buzz} from "./layouts/Buzz";
import {OnlineUsers} from "./layouts/OnlineUsers";
import {BuzzSuggestedFollow} from "./layouts/BuzzPages/SuggestedFollow";
import {Groups} from "./layouts/Groups";
import {Recreation} from "./layouts/GroupsPages/Recreation";
import {Sexuality} from "./layouts/GroupsPages/Sexuality";
import {Lifestyle} from "./layouts/GroupsPages/Lifestyle";
import {Eroticism} from "./layouts/GroupsPages/Eroticism";
import {AdminDashboard} from "./layouts/AdminDashboard";
import {Welcome} from './layouts/Welcome';
import {Discover} from './layouts/Discover';
import {Members} from './layouts/Members';
import {Patron} from './layouts/Patron';
import {Activities} from "./layouts/Activities";
import {Chats} from "./layouts/Chats";
import {Inbox} from "./layouts/chats/inbox";
import {Compose} from "./layouts/chats/compose";
import {Conversation} from "./layouts/chats/conversation";
import {Notifications} from "./layouts/Notifications";
import {PageLayout} from "./layouts/PageLayout";
import {AppLayout} from "./layouts/AppLayout";
import {Terms, Guidelines} from './layouts/Terms-Guidelines';
import {TheErotic} from './layouts/TheErotic';
import {PrivacyPolicy} from './layouts/PrivacyPolicy';
import {RegistrationReq} from './layouts/RegistrationReq';
import ErrorPage from "./layouts/error-page";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { AuthProvider } from "./context/AuthContext";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/our-story",
    element: <OurStory />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: <Signup/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "checkout",
    element: <Checkout/>,
    errorElement: <ErrorPage />,
  },
  { path: "/text",
    element: <PageLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "terms",
        element: <Terms/>,
      },
      {
        path: "guidelines",
        element: <Guidelines/>,
      },
      {
        path: "privacyPolicy",
        element: <PrivacyPolicy/>,
      },
      {
        path: "registrationReq",
        element: <RegistrationReq/>
      },
      {
        path: "workWithUs",
        element: <Patron />,
      },
      {
        path: "theErotic",
        element: <TheErotic />,
      }
    ],
  },
  { path: "/app",
    element: <AppLayout showNavBtn={true} loggedIn={true}/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "profile",
        element: <Profile/>,
        children: [
          { index:true, element:  <Timeline/> },
          { path: "info", element: <Info/> },
          { path: "followers", element: <Followers/> },
          { path: "connections", element: <Connections/> },
          { path: "circles", element: <Circles/> },
          { path: "forums", element: <ForumsJoined /> },
          { path: "groups", element: <GroupsJoined/> },
          { path: "albums", element: <Albums/> },
          { path: "docs", element: <Documents/> },
        ]
      },
      {
        path: "forums",
        element: <Forums/>,
      },
      {
        path: "buzz",
        element: <Buzz/>,
        children: [
          { index:true, element:  <Timeline/> },
          { path: "connectedUsers", element: <OnlineUsers/> },
          { path: "members", element: <BuzzSuggestedFollow/> }
        ]
      },
      {
        path: "groups",
        element: <Groups/>,
        children: [
          { index:true, element:  <Recreation/> },
          { path:'sexuality', element:  <Sexuality/> },
          { path:'lifestyle', element:  <Lifestyle/> },
          { path:'eroticism', element:  <Eroticism/> },
        ]
      },
      {
        path: "members",
        element: <Members/>,
      },
      {
        path: "discover",
        element: <Discover/>,
      },
      {
        path: "activities",
        element: <Activities/>,
      },
      {
        path: "chats",
        element: <Chats/>,
        children:[
          {index:true, element: <Inbox/>},
          {path:'compose', element: <Compose/>},
          {path:'conversation', element: <Conversation/>},
        ]
      },
      {
        path: "notifications",
        element: <Notifications/>,
      },
      {
        path: "settings",
        element: <Settings/>,
      },
      {
        path: "subscription",
        element: <Subscription/>,
      },
      {
        path: "admin",
        element: <AdminDashboard/>,
      },
    ],
  },
]);


function App() {

  return  <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
}

export default App;