import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton, ProfileListItem} from '../../components/ModularComponents';
import '../../css/profile.css';

export const Circles = () => {
    const circles = [{id:1, name:'Close friends',count:8},{id:1, name:'Aquaintances',count:32}];

    return (
        <Container className="p-0">
            <Card style={{marginBottom:10}}>
                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Circles ({circles.length})</Card.Text>
                </Card.Header>
                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:12,color:'#0AC5BF'}}>
                    {circles.map(({name, count}, index) => {
                    	return 	<div className="p-2 mb-2 d-flex align-items-center" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer'}}>
                    				<span><i className="bi bi-people-fill"></i> {name} ({count})</span>
                    				<span style={{marginLeft:'auto',color:'#999'}}><i className="bi bi-trash"></i></span>
                    			</div>
                    })}
                </Card.Body>
            </Card>
        </Container>
    );
}

