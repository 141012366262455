import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, DropdownButton, Dropdown, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo.png';
import { Link, useNavigate } from "react-router-dom";
import {BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';
import {MyRadioInputs} from '../components/ModularComponents';
import {dateToSeconds, validateAll} from '../modules.js';
//import CreditCardInput from 'react-credit-card-input';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Cards from 'react-credit-cards-2';

const CreditCardInput2 = ({ data }) => {
	const [state, setState] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    
    setState((prev) => ({ ...prev, [name]: value }));
  }

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  }

  const handleCCNumberChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    inputValue = inputValue.match(/.{1,4}/g)?.join(' ') || '';

    setState((prev) => ({ ...prev, number: inputValue }));
  };

  const handleExpiryChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4); // Limit input to 4 digits
    }

    if (inputValue.length >= 3) {
      inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2); // Insert slash after MM
    }

    setState((prev) => ({ ...prev, expiry: inputValue }));
  };

  const inputstyle = {padding:10,width:'100%',maxWidth:290,marginBottom:4,borderRadius:8,border:'1px solid #bbb'}
  const inputstyle2 = {padding:10,width:'100%',maxWidth:145,borderRadius:8,border:'1px solid #bbb'}

  return (
    <div>
      <Cards
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
      />
      <div className="d-flex align-items-center justify-content-center">
        <form className="mt-2 mb-2">
          <div>
            <input
              type="number"
              name="number"
              placeholder="Card Number"
              value={state.number}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              style={inputstyle}
            />
          </div>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={state.name}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              style={inputstyle}
            />
          </div>
          <div className="d-flex align-items-center">
            <input type="text"  
                   id="monthYear" 
                   name="expiry" 
                   maxlength="5" 
                   placeholder="MM/YY"
                   value={state.expiry}
                   onChange={handleExpiryChange}
                   onFocus={handleInputFocus}
                   style={inputstyle2}/>
            <input
              type="number"
              name="cvc"
              placeholder="CVC"
              value={state.cvc}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              style={inputstyle2}
            />
          </div>
        </form>
      </div>
    </div>
  );
};


export const Checkout = () => {
  const [paymentMethod, setPaymentMethod] = useState([true,false]);
  const [paymentMethod1, setPaymentMethod1] = useState([true,false]);
  const [paymentMethod2, setPaymentMethod2] = useState([true,false]);
  const [amount, setAmount] = useState('0.00');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  return (<>
    <BlackNavBar loggedin={true} showNavBtn={false} />
    <Container className="pb-4 pt-2" style={{marginTop:80}}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs={12} md={6}>
          <Card className="mb-2">
            <Card.Body>
              <Card.Text>Select and pay for your subsciption below in order to access to the site.</Card.Text>
            </Card.Body>
          </Card>
          <Card style={{border:'1px solid transparent'}}>
            <Card.Body className="pt-0 pb-0 mt-0 mb-0">
              <MyRadioInputs label="Payment method" keyArr={['Paypal', 'Local getway']} valueArr={paymentMethod} onSelect={(arr) => setPaymentMethod([...arr])} textStyle={{color:'#999'}}/>
            </Card.Body>
          </Card>
          {paymentMethod[0]&&<Card>
            <Card.Body>
              <InputGroup className="mt-3">
                <Form.Control placeholder="Select subsciption"
                              aria-label="subsciption"
                              aria-describedby="basic-addon1"
                              as="select"
                              onChange={(e) => setAmount(e.target.value)}>
                  <option style={{ color: 'gray' }} selected disabled>Select subsciption</option>
                  <option value={10.00}>Diamond(60% off) $10 for 12 months</option>
                  <option value={15.00}>Ruby(40% off) $15 for 6 months</option>
                  <option value={20.00}>Emerald(20% off) $20 for 3 months</option>
                  <option value={25.00}>Sapphire $25 monthly</option>
                </Form.Control>
              </InputGroup>
              <InputGroup className="mt-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="bi bi-currency-dollar"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Amount"
                  aria-label="amount"
                  aria-describedby="basic-addon1"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <InputGroup.Text id="basic-addon1">USD</InputGroup.Text>
              </InputGroup>
              <MyRadioInputs label="" keyArr={['Paypal', 'Card']} valueArr={paymentMethod1} onSelect={(arr) => setPaymentMethod1([...arr])} textStyle={{color:'#999'}}/>
              {paymentMethod1[1]&&<CreditCardInput2/>}
              <div className="mt-4">
                {paymentMethod1[0]&&<Button style={{backgroundColor:'#FCBB32',border:'1px solid #FCBB32',width:'100%',padding:'10px 10px'}} onClick={() => {}}>
                    <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="PayPal" />
                </Button>}
                {paymentMethod1[1]&&<Button className="mt-3 d-flex align-items-center justify-content-center" 
                        style={{backgroundColor:'#2C2E2F',border:'1px solid #2C2E2F',width:'100%',padding:'5px 10px',fontSize:18}} onClick={() => {}}>
                    <i class="bi bi-credit-card" style={{marginRight:5,fontSize:24}}></i>
                    <>Pay With Debit or Credit Card</>
                </Button>}
              </div>
            </Card.Body>
          </Card>}
          {paymentMethod[1]&&<Card>
            <Card.Body>
              <InputGroup className="mt-3">
                <Form.Control placeholder="Select subsciption"
                              aria-label="subsciption"
                              aria-describedby="basic-addon1"
                              as="select"
                              onChange={(e) => setAmount(e.target.value)}>
                  <option style={{ color: 'gray' }} selected disabled>Select subsciption</option>
                  <option value={10.00}>Diamond(60% off) $10 for 12 months</option>
                  <option value={15.00}>Ruby(40% off) $15 for 6 months</option>
                  <option value={20.00}>Emerald(20% off) $20 for 3 months</option>
                  <option value={25.00}>Sapphire $25 monthly</option>
                </Form.Control>
              </InputGroup>
              <InputGroup className="mt-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="bi bi-currency-dollar"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Amount"
                  aria-label="amount"
                  aria-describedby="basic-addon1"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <InputGroup.Text id="basic-addon1">USD</InputGroup.Text>
              </InputGroup>
              <MyRadioInputs label="" keyArr={['Mpesa', 'Card']} valueArr={paymentMethod2} onSelect={(arr) => setPaymentMethod2([...arr])} textStyle={{color:'#999'}}/>
              {paymentMethod2[0]&&<PhoneInput defaultCountry="KE"
                                              placeholder="07XXXXXXXX"
                                              value={phoneNumber}
                                              onChange={setPhoneNumber}/>}
              {paymentMethod2[1]&&<CreditCardInput2/>}
              <div className="mt-4">
                {paymentMethod2[0]&&<Button style={{backgroundColor:'#3aa335',border:'1px solid #3aa335',width:'100%',padding:'10px 10px'}} onClick={() => {}}>
                    <>Lipa Na Mpesa</>
                </Button>}
                {paymentMethod2[1]&&<Button className="mt-3 d-flex align-items-center justify-content-center" 
                        style={{backgroundColor:'#2C2E2F',border:'1px solid #2C2E2F',width:'100%',padding:'5px 10px',fontSize:18}} onClick={() => {}}>
                    <i class="bi bi-credit-card" style={{marginRight:5,fontSize:24}}></i>
                    <>Pay With Card</>
                </Button>}
              </div>
            </Card.Body>
          </Card>}
        </Col>
      </Row>
    </Container>
    <Footer/>
  </>);
};

