import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, DropdownButton, Dropdown, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo.png';
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {NavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';
import {africanCountriesWithCodes} from '../data';
import signupImg from '../assets/signupImage.png';
import '../css/gradientBtn.css';
import {MyCheckBox,AltMyRadioInputs,CustomDatePicker} from '../components/ModularComponents';
import {dateToSeconds, validateAll} from '../modules.js';

const africanCountries = [
	'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi',
	'Cabo Verde', 'Cameroon', 'Central African Republic', 'Chad', 'Comoros',
	'Democratic Republic of the Congo', 'Djibouti', 'Egypt', 'Equatorial Guinea',
	'Eritrea', 'Eswatini', 'Ethiopia', 'Gabon', 'Gambia', 'Ghana', 'Guinea',
	'Guinea-Bissau', 'Ivory Coast', 'Kenya', 'Lesotho', 'Liberia', 'Libya',
	'Madagascar', 'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Morocco',
	'Mozambique', 'Namibia', 'Niger', 'Nigeria', 'Republic of the Congo',
	'Rwanda', 'Sao Tome and Principe', 'Senegal', 'Seychelles', 'Sierra Leone',
	'Somalia', 'South Africa', 'South Sudan', 'Sudan', 'Tanzania', 'Togo',
	'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
  ];


export const Signup = () => {
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [country, setCountry] = useState('');
	const [city, setCity] = useState({});
	const [gender_at_birth, setGenderAtBirth] = useState('');
	const [date_of_birth, setDateOfBirth] = useState(915148800);
	const navigate = useNavigate();
	const { login } = useAuth();
	const [togglePassphaseView, setTogglePhraseView] = useState(false);
	const [cbState, setCbState] = useState(false);
	const [cbState1, setCbState1] = useState(false);
	const [cbState2, setCbState2] = useState(false);
	const [valueArr, onSelect] = useState([false, false, false]);
	const [dateValue, setDateValue] = useState("Jan-01-1999");
	const [showCalendar, setShowCalendar] = useState(false);
	const dateOfBirthInputRef = useRef(null);

	const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [dateOfBirthError, setDateOfBirthError] = useState('');
    const [genderAtBirthError, setGenderAtBirthError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [cityError, setCityError] = useState('');
    const [cbError, setCbError] = useState('');
    const [cb1Error, setCb1Error] = useState('');
    const [cb2Error, setCb2Error] = useState('');

	const handleDobFocus = () => {
        setShowCalendar(true);
        dateOfBirthInputRef.current.blur();
    };

    const handleCloseModal = () => setShowCalendar(false);

	const handleSignup = async() => {
		let obj = {username, email, password, country, city:city, gender_at_birth, date_of_birth};
		console.log(JSON.stringify(obj));
		let validations = validateAll(username, email, password, date_of_birth, gender_at_birth, country, city, cbState, cbState1, cbState2);
		if(!validations?.isValid){
			if(validations?.invalidValues?.username){
				setUsernameError(validations?.invalidValues?.username?.errorMessage);
			}else{
				setUsernameError("");
			}
			if(validations?.invalidValues?.email){
				setEmailError(validations?.invalidValues?.email?.errorMessage);
			}else{
				setEmailError("");
			}
			if(validations?.invalidValues?.password){
				setPasswordError(validations?.invalidValues?.password?.errorMessage);
			}else{
				setPasswordError("");
			}
			if(validations?.invalidValues?.gender_at_birth){
				setGenderAtBirthError(validations?.invalidValues?.gender_at_birth?.errorMessage);
			}else{
				setGenderAtBirthError("");
			}
			if(validations?.invalidValues?.country){
				setCountryError(validations?.invalidValues?.country?.errorMessage);
			}else{
				setCountryError("");
			}
			if(validations?.invalidValues?.city){
				setCityError(validations?.invalidValues?.city?.errorMessage);
			}else{
				setCityError("");
			}
			if(validations?.invalidValues?.date_of_birth){
				setDateOfBirthError(validations?.invalidValues?.date_of_birth?.errorMessage);
			}else{
				setDateOfBirthError("");
			}
			if(validations?.invalidValues?.guidelineAgreement){
				setCbError(validations?.invalidValues?.guidelineAgreement?.errorMessage);
			}else{
				setCbError("");
			}
			if(validations?.invalidValues?.termsAgreement){
				setCb1Error(validations?.invalidValues?.termsAgreement?.errorMessage);
			}else{
				setCb1Error("");
			}
			if(validations?.invalidValues?.privacyAgreement){
				setCb2Error(validations?.invalidValues?.privacyAgreement?.errorMessage);
			}else{
				setCb2Error("");
			}
		}else{
			setUsernameError("");
			setEmailError("");
			setPasswordError("");
			setDateOfBirthError("");
			setGenderAtBirthError("");
			setCountryError("");
			setCityError("");
			setCbError("");
			setCb1Error("");
			setCb2Error("");
		}

		if(validations?.isValid){
			console.log(JSON.stringify(validations));
			const response = await fetch('http://13.244.179.175/users/signup/user',
										{	
											method: "POST",
											headers: {'Content-Type': 'application/json'}, 
											body: JSON.stringify(obj)
										});
			let res = await response.json();
			console.log(res);
			navigate('/checkout');
		}
	};

	useEffect(() => {
		if(dateValue){
			let timeStamp = dateToSeconds(dateValue);
			setDateOfBirth(timeStamp);
		}
	},[dateValue, setDateOfBirth]);

	useEffect(() => {
		if(valueArr[0] === true){
			setGenderAtBirth('male');
		}else if(valueArr[1] === true){
			setGenderAtBirth('female');
		}else if(valueArr[2] === true){
			setGenderAtBirth('other');
		}
	},[valueArr]);

	return 	<>
			<NavBar loggedin={true} showNavBtn={false} />
			<Container fluid className="d-flex" style={{backgroundColor:'black',marginTop:80,fontFamily:'Merriweather'}}>
				<Row>
					<Col xs={12} md={6}>
						<Container>
							<Row xs="auto" md="auto" lg="auto" xxl="auto" className="d-flex justify-content-center">
								<Card style={{backgroundColor:'black'}}>
									<Card.Body>
										<InputGroup>
									    	<div className="d-flex align-items-center">
												<MyCheckBox cbState={cbState} setCbState={setCbState} />
												<span style={{marginLeft:5,color:'white'}}>I affirm that I will abide by the<Link to="/text/guidelines" style={{marginLeft:3}}> interaction guidelines</Link> I aim to contribute positively to our collective experience.*</span>
											</div>									
					        			</InputGroup>
					        			{(cbError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {cbError}</div>}
										<InputGroup className="mt-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-calendar3"></i>
											</InputGroup.Text>
											<Form.Control   placeholder="Date of Birth"
													        aria-label="date_of_birth"
													        ref={dateOfBirthInputRef}
            												onFocus={handleDobFocus}
													        aria-describedby="basic-addon1"
															value={dateValue}
															onChange={(e) => setDateValue(e.target.value)}
													        />
									        {setShowCalendar&&<Modal  show={showCalendar} 
																	  onHide={handleCloseModal} centered>
								                <Modal.Header closeButton>
								                    <Modal.Title style={{color:'#033b39'}}>Calendar</Modal.Title>
								                </Modal.Header>
								                <Modal.Body>
								                    <CustomDatePicker dateValue="01/01/1999" setDateValue={(x) => setDateValue(x)} />
								                </Modal.Body>
								            </Modal>}
									    </InputGroup>
									    {(dateOfBirthError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {dateOfBirthError}</div>}
									    <AltMyRadioInputs valueArr={valueArr} onSelect={onSelect}/>
									    {(genderAtBirthError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {genderAtBirthError}</div>}
										<InputGroup className="mt-3">
											<Form.Control placeholder="Country" onChange={(e) => setCountry(e.target.value)} as="select" aria-label="Country" aria-describedby="basic-addon1">
												<option style={{ color: 'gray' }} value="" disabled selected>Select a country</option>
												{africanCountries.map((_country, index) => 
													<option key={index} value={_country}>{_country}</option>
												)}
											</Form.Control>
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-caret-down-fill" style={{ color: 'gray' }}></i>
											</InputGroup.Text>
										</InputGroup>
										{(countryError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {usernameError}</div>}
										<InputGroup className="mt-3">
											<div style={{ width: '100%' }}>
												<GooglePlacesAutocomplete
													containerProps={{
														style: { width:"100%" },
													}}
													inputProps={{
														style: { width: '100%' },
													}}
													placeholder='City'
													loadScript={false}
													apiKey="AIzaSyAKQ5Z_Z3Am958KGMOo4dx6pv9PUvht7I4"
													selectProps={{ onChange: (c) => setCity(c?.value?.terms[0]?.value), placeholder: 'City' }}
													autocompletionRequest={{
														types: ['(cities)'],
														componentRestrictions:{country:africanCountriesWithCodes[country]}}}
												/>
											</div>
										</InputGroup>
										{(cityError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {cityError}</div>}
										<InputGroup className="mt-3">
									    	<div className="d-flex align-items-center">
												<MyCheckBox cbState={cbState1} setCbState={setCbState1} />
												<span style={{alignItems:'center',wordWrap:'break-word',marginLeft:5,color:'white'}} id="basic-addon1">
													I certify that(1) I'm at least 25 years old and (2) I have read and consent to the<Link to="/text/terms" style={{marginLeft:3}}> terms of service</Link>
												</span>
											</div>									
					        			</InputGroup>
					        			{(cb1Error!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {cb1Error}</div>}
									    <InputGroup className="mt-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-person-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									          placeholder="Username"
									          aria-label="Username"
									          aria-describedby="basic-addon1"
									          value={username}
									          onChange={(e) => setUsername(e.target.value)}
									        />
									    </InputGroup>
									    {(usernameError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {usernameError}</div>}
									    <InputGroup className="mt-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-envelope-at-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									          placeholder="Email"
									          aria-label="Email"
									          aria-describedby="basic-addon1"
									          value={email}
									          onChange={(e) => setEmail(e.target.value)}
									        />
									    </InputGroup>
									    {(emailError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {emailError}</div>}
									    <InputGroup>
									    	<p style={{color:'white',fontSize:12}}>Passphrase example: "1ts 3@s!er th@n a passw0rd"*</p>
									    </InputGroup>
									    <InputGroup className="mt-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-key-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									        	type="password"
									          placeholder="Passphrase"
									          aria-label="Password"
									          aria-describedby="basic-addon1"
									          value={password}
									          onChange={(e) => setPassword(e.target.value)}
									        />
									        <InputGroup.Text id="basic-addon1" onClick={()=>setTogglePhraseView(!togglePassphaseView)}>
												{!togglePassphaseView?<i class="bi bi-eye"></i>:<i class="bi bi-eye-slash"></i>}
											</InputGroup.Text>
									    </InputGroup>
									    {(passwordError!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {passwordError}</div>}
									    <InputGroup className="mt-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-key-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									        	type="password"
									          	placeholder="Confirm passphrase"
									          	aria-label="Confirm Password"
									          	aria-describedby="basic-addon1"
									          	value={confirmPassword}
									          	onChange={(e) => setConfirmPassword(e.target.value)}
									        />
									        <InputGroup.Text id="basic-addon1" onClick={()=>setTogglePhraseView(!togglePassphaseView)}>
												{!togglePassphaseView?<i class="bi bi-eye"></i>:<i class="bi bi-eye-slash"></i>}
											</InputGroup.Text>
									    </InputGroup>
									    {(password!==confirmPassword)&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> Passphrase and confirm passphrase are different</div>}
									    <InputGroup className="mt-3">
									        <Form.Select aria-label="Default select example">
										      <option>How did you hear about us?</option>
										      <option value="1">Search Engine</option>
										      <option value="1">Word of mouth</option>
										      <option value="1">Social media</option>
										      <option value="1">Targeted Ads</option>
										    </Form.Select>
									    </InputGroup>
									    <InputGroup className="mt-3">
									    	<div className="d-flex align-items-center">
												<MyCheckBox cbState={cbState2} setCbState={setCbState2} />
												<span style={{alignItems:'center',wordWrap:'break-word',marginLeft:5,color:'white'}} id="basic-addon1">
													I affirm that I have read and consent to the<Link to="/text/privacyPolicy" style={{marginLeft:3}}> privacy policy</Link>
												</span>
											</div>									
					        			</InputGroup>
					        			{(cb2Error!=="")&&<div style={{ backgroundColor: 'rgba(255, 100, 100, 0.85)',
											              padding: '2px 10px', display:'inline-block',
											              color: 'white', maxWidth: 'fit-content',
										            	  borderRadius: 3,fontSize:12}}>
										    <i className="bi bi-info-circle bg-danger text-white"></i> {cb2Error}</div>}
									    <InputGroup className="d-grid gap-2 mt-3" style={{marginTop:10, marginBottom:20}}>
 											<Button className="gradient-border-button" onClick={()=> handleSignup()} style={{borderWidth:0, backgroundColor:'transparent', color:'#fed700',padding:'15px 40px 15px 40px'}}>
									          Signup
									        </Button>										
					        			</InputGroup>
									</Card.Body>
								</Card>
							</Row>
						</Container>
					</Col>
					<Col xs={12} md={6}>
						<Container>
							<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
								<Image src={signupImg} fluid/>
							</div>
							<p style={{color:'white'}}>Join Africa’s adult pleasure playground and explore a diverse array of both sexual and non-sexual conversations and activities. Build new skills, delve into new kinks, try on new erotic personas, or co-create moments of play, pleasure, and excitement with like-minded adults across Africa. Register to discover what brings you pleasure and joy, and find new ways to play!</p>
							<p style={{color:'white'}}>How you choose to relate, play, and explore does not define you. Your essence is more complex and cannot be solely determined by these aspects of yourself. Embrace your interests and desires—confidently express, explore, and experience.</p>
							<div style={{marginTop:20,marginBottom:30}}>
								<Row className="d-flex flex-rowflex-wrap" style={{color:'#feb700'}}>
									<Col xs={6}>
										<b>Membership</b>
									</Col>
									<Col xs={6}>
										<b>Per Month</b>
									</Col>
									<Col xs={6}>
										Sapphire
									</Col>
									<Col xs={6}>
										Monthly $25
									</Col>
									<Col xs={6}>
										Emerald(20% off)
									</Col>
									<Col xs={6}>
										*3 months $20
									</Col>
									<Col xs={6}>
										Ruby(40% off)
									</Col>
									<Col xs={6}>
										*6 months $15
									</Col>
									<Col xs={6}>
										Diamond(60% off)
									</Col>
									<Col xs={6}>
										*12 months $10
									</Col>
								</Row>
							</div>
						</Container>
					</Col>
				</Row>
			</Container>
			<Footer/>
			</>
}