import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack,Card,Button,Row,Col,Image} from 'react-bootstrap';
import {SubGroupCard} from '../../components/ModularComponents';
import openRelationships from '../../assets/openRelationships.png';
import polyamory from '../../assets/polyamory.png';
import relationshipAnarchy from '../../assets/relationshipAnarchy.png';
import ambiamory from '../../assets/ambiamory.png';
import asexuality from '../../assets/asexuality.png';
import fluidRelationship from '../../assets/fluidRelationship.png';
import rolePlay from '../../assets/rolePlay.png';
import powerExchangeRelationship from '../../assets/powerExchangeRelationship.png';

export const Lifestyle = () => {

	const groups = [{title:'Open Relationships', image:openRelationships},
					{title:'Polyamory', image:polyamory},
					{title:'Relationship Anarchy',image:relationshipAnarchy},
					{title:'Ambiamory',image:ambiamory},
					{title:'Asexuality',image:asexuality},
					{title:'Fluid Relationship',image:fluidRelationship},
					{title:'Role Play',image:rolePlay},
					{title:'Power Exchange Relationship',image:powerExchangeRelationship},]

	return 	<Row className="d-flex align-items-stretch justify-content-stretch">
				{groups.map(({title, image},index) => {
					return  <Col xs={12} md={6} xl={4}>
								<SubGroupCard label={title} podcastsImage={image}/>
							</Col>
				})}
			</Row>
}

