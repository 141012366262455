import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Row,Col,Card, Nav} from 'react-bootstrap';
import {useNavigate, useLocation,Outlet} from 'react-router-dom';
import {CreatePostInput, PostCard, MiniAvatar, Avatar, CardButton} from '../components/ModularComponents';
import {postData} from '../data';
import { useAuth } from '../hooks/useAuth';
import { useOutsideClick } from '../hooks/useOutsideClick';

export const UserItem = ({username, 
						 location,
						 gender,
						 age,
						 profileType}) => {
	return 	<div className="d-flex align-items-center mt-2 mb-2">
				<div className="d-flex">
					<MiniAvatar couple={profileType==='couple'?true:false} gender={gender}/>
				</div>
				<div style={{marginLeft:4}}>
					<div style={{fontWeight:'bold',fontSize:12}}>{(profileType==='couple')?username.replace('-',' & '):username}</div>
					<div style={{fontSize:11,color:'#555'}}>{location}</div>
				</div>
			</div>
}

export const Buzz = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
    const { pathname } = location;
    const onlineUsers = [{username:'johnreese',location:'Nairobi, Kenya',gender:'male',age:25,profileType:'single'},
						 {username:'jane',location:'Nakuru, Kenya',gender:'female',age:23,profileType:'single'},
						 {username:'olga',location:'Kiambu, Kenya',gender:'female',age:28,profileType:'single'},
						 {username:'johnathan-emily',location:'Mombasa, Kenya',gender:'male-female',age:'31-26',profileType:'couple'}];
	const members = [{username:'jenni',location:'Nairobi, Kenya',gender:'female',age:25,profileType:'single'},
					 {username:'julie',location:'Nakuru, Kenya',gender:'female',age:23,profileType:'single'},
					 {username:'oscar',location:'Kiambu, Kenya',gender:'male',age:28,profileType:'single'},
					 {username:'romio-juliette',location:'Mombasa, Kenya',gender:'male-female',age:'31-26',profileType:'couple'}];
	return  <Container>
				<Row className="d-flex justify-content-center p-1">
					<Col xs={12} md={3} className="d-none d-md-none d-lg-block p-1">
						<Card>
							<Card.Header>
								<Card.Text style={{fontWeight:'bold',
													fontSize:16}}>Online users</Card.Text>
							</Card.Header>
							<Card.Body>
								{false&&<Card.Text>No users online</Card.Text>}
								{false&&<Card.Text>No members</Card.Text>}
								{onlineUsers.map((value, index) => {
									let _username = value.username;
									let _location = value.location;
									let _gender = value.gender;
									let _age = value.age
									let _profile_type = value.profileType;
									return <UserItem username={_username} 
	        										 location={_location} 
	        										 gender={_gender} 
	        										 age={_age}
	        										 profileType={_profile_type} />
								})}
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} md={6}>
						<Container className="p-1" style={{marginTop:0}}>
				            <Nav className="d-block d-md-block d-lg-none border border-1 rounded-3 mb-1 pb-0" style={{borderRadius: '8px',overflow:'hidden'}}>
								<Row>	
									<Col onClick={() => navigate("/app/buzz")} 
												className="border-end text-center" 
												style={{fontSize: '0.7rem', 
														padding:'5px 0',
														color: (pathname === '/app/buzz' ? 'white' : '#0AC5BF'), 
														backgroundColor:(pathname === '/app/buzz' ? '#0AC5BF' : 'white') }}>
											<i className="bi bi-clock-history"></i> Newsfeed
									</Col>
									<Col onClick={() => navigate("/app/buzz/connectedUsers")} 
												className="border-end text-center" 
												style={{fontSize: '0.7rem',
														padding:'5px 0',
														color: (pathname === '/app/buzz/connectedUsers' ? 'white' : '#0AC5BF'), 
														backgroundColor:(pathname === '/app/buzz/connectedUsers' ? '#0AC5BF' : 'white') }}>
											<i className="bi bi-people"></i> Online users
									</Col>
									<Col onClick={() => navigate("/app/buzz/members")} 
												className="text-center" 
												style={{fontSize: '0.7rem',
														padding:'5px 0',
														color: (pathname === '/app/buzz/members' ? 'white' : '#0AC5BF'), 
														backgroundColor:(pathname === '/app/buzz/members' ? '#0AC5BF' : 'white') }}>
											<i className="bi bi-people"></i> Members
									</Col>
								</Row>
							</Nav>
							<div>
				            	<Outlet/>
				            </div>
				        </Container>
					</Col>
					<Col xs={12} md={3} className="d-none d-md-none d-lg-block p-1">
						<Card>
							<Card.Header>
								<Card.Text style={{fontWeight:'bold',
													fontSize:16}}>Suggested Profiles</Card.Text>
							</Card.Header>
							<Card.Body>
								{false&&<Card.Text>No members</Card.Text>}
								{members.map((value, index) => {
									let _username = value.username;
									let _location = value.location;
									let _gender = value.gender;
									let _age = value.age
									let _profile_type = value.profileType;
									return <UserItem username={_username} 
	        										 location={_location} 
	        										 gender={_gender} 
	        										 age={_age}
	        										 profileType={_profile_type} />
								})}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
}