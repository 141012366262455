import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack,Card,Button,Row,Col,Image} from 'react-bootstrap';
import {SubGroupCard} from '../../components/ModularComponents';
import sexualOrientation from '../../assets/sexualOrientation.png';
import genderIdentity from '../../assets/genderIdentity.png';
import sexualhealthAndWellbeing from '../../assets/sexualhealthAndWellbeing.png';
import emotionalAndRomantic from '../../assets/emotionalAndRomantic.png';
import biological from '../../assets/biological.png';

export const Sexuality = () => {

	const groups = [{title:'Sexual Orientation', image:sexualOrientation},
					{title:'Gender Identity', image:genderIdentity},
					{title:'Sexual Health And Wellbeing',image:sexualhealthAndWellbeing},
					{title:'Emotional And Romatic',image:emotionalAndRomantic},
					{title:'Biological',image:biological},]

	return 	<Row className="d-flex align-items-stretch justify-content-stretch">
				{groups.map(({title, image},index) => {
					return  <Col xs={12} md={6} xl={4}>
								<SubGroupCard label={title} podcastsImage={image}/>
							</Col>
				})}
			</Row>
}

