import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {MiniAvatar} from '../../components/ModularComponents';
import {useNavigate} from 'react-router-dom';
import {convertTimestampToDateTime} from '../../modules.js';

const inbox = [
	{previewText:'hello guys...!',author:{username:'audrey'},date:1720411000},
	{previewText:'hello!...',author:{username:'justin'},date:1720411050},
	{previewText:'hey!...',author:{username:'jane'},date:1720411150},
	{previewText:'Are you guys ready for the adventure...',author:{username:'jamie'},date:1720411600}
]

const InboxItem = ({previewText,author,date}) => {
	const navigate = useNavigate();

	return 	<div onClick={() => navigate('/app/chats/conversation')} className="d-flex align-items-center mb-1" style={{color:'#033b39'}}>
				<MiniAvatar />
				<div style={{marginLeft:4}}><b>{author?.username}</b></div>
				<div style={{padding:'0 4px',whiteSpace:'nowrap',overflow:'hidden',textOverflow: 'ellipsis'}}>{previewText}</div>
				<div style={{marginLeft:'auto'}}>{convertTimestampToDateTime(date).split('|')[1]}</div>
			</div>
}

export const Inbox = () => {

	return 	<Container>
				{inbox.map((inboxValue,index) => {
					return <InboxItem {...inboxValue}/>
				})}
			</Container>
}