import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack,Card,Button,Row,Col,Image} from 'react-bootstrap';
import {SubGroupCard} from '../../components/ModularComponents';
import kink from '../../assets/kink.png';
import bdsm from '../../assets/bdsm.png';
import sensual from '../../assets/sensual.png';
import energetic from '../../assets/energetic.png';
import fantasy from '../../assets/fantasy.png';
import sexual from '../../assets/sexual.png';
import fetishes from '../../assets/fetishes.png';
import swinging from '../../assets/swinging.png';

export const Eroticism = () => {

	const groups = [{title:'Kink', image:kink, privacyStatus:true},
					{title:'Bdsm', image:bdsm, privacyStatus:true},
					{title:'Sensual',image:sensual, privacyStatus:true},
					{title:'Energetic',image:energetic, privacyStatus:true},
					{title:'Fantasy',image:fantasy, privacyStatus:true},
					{title:'Sexual',image:sexual, privacyStatus:true},
					{title:'Fetishes',image:fetishes, privacyStatus:true},
					{title:'Swinging',image:swinging, privacyStatus:true}]

	return 	<Row className="d-flex align-items-stretch justify-content-stretch">
				{groups.map(({title, image, privacyStatus},index) => {
					return  <Col xs={12} md={6} xl={4}>
								<SubGroupCard label={title} podcastsImage={image} privacyStatus={privacyStatus}/>
							</Col>
				})}
			</Row>
}

