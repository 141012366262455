import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton, ProfileListItem} from '../components/ModularComponents';
import '../css/profile.css';

export const OnlineUsers = () => {
    const onlineUsers = [{username:'johnreese',location:'Nairobi, Kenya',gender:'male',age:25,profileType:'single'},
						 {username:'jane',location:'Nakuru, Kenya',gender:'female',age:23,profileType:'single'},
						 {username:'olga',location:'Kiambu, Kenya',gender:'female',age:28,profileType:'single'},
						 {username:'johnathan-emily',location:'Mombasa, Kenya',gender:'male-female',age:'31-26',profileType:'couple'}];

    return (
        <Container className="p-0">
            <div style={{paddingLeft:20,paddingRight:20,fontSize:11}}>
                {onlineUsers.map(({username, location, gender, age, profileType}, index) => {
                    return <ProfileListItem username={username} location={location} gender={gender} isCouple={profileType==="couple"?true:false}/>
                })}
            </div>
        </Container>
    );
}

