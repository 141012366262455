// GenderRatioChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

export const GenderRatioChart = ({ data }) => {
  // Define labels and data for the chart
  const chartData = {
    labels: ['Male', 'Female', 'Other'],
    datasets: [
      {
        label: 'Gender Ratio',
        data, // Array of gender counts
        backgroundColor: [
          'rgba(54, 162, 235, 0.5)', // Male color
          'rgba(255, 99, 132, 0.5)', // Female color
          'rgba(255, 206, 86, 0.5)', // Other color
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Gender Ratio',
      },
    },
  };

  return <Pie data={chartData} options={options} />;
};
