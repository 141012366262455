import React, {useState} from 'react';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, Image } from 'react-bootstrap';


export const TheErotic = () => {
    return  <Container> 
                <div className="pt-4">
                    <h2 style={{fontStyle:'italic',fontWeight:'bold',color: '#0ac5bf'}}>The Erotic as Power by Audre Lorde (Extracts)</h2>
                    <p><strong> <a style={{color:'#0AC5BF'}} href="https://uk.sagepub.com/sites/default/files/upm-binaries/11881_Chapter_5.pdf">The erotic</a>:</strong> is a measure between the beginnings of our sense of self and the chaos of our strongest feelings. It is an internal sense of satisfaction to which, once we have experienced it, we know we can aspire. For having experienced the fullness of this depth of feeling and recognizing its power, in honor and self-respect we can require no less of ourselves.</p>

                    <p>It is never easy to demand the most from ourselves, from our lives, from our work. To encourage excellence is to go beyond the encouraged mediocrity of our society. But giving in to the fear of feeling and working to capacity is a luxury only the unintentional can afford, and the unintentional are those who do not wish to guide their own destinies. This internal requirement toward excellence which we learn from the erotic must not be misconstrued as demanding the impossible from ourselves nor from others. Such a demand incapacitates everyone in the process. For the erotic is not a question only of what we do; it is a question of how acutely and fully we can feel in the doing. Once we know the extent to which we are capable of feeling that sense of satisfaction and completion, we can then observe which of our various life endeavors brings us closest to that fullness. </p>


                    <p>The aim of each thing which we do is to make our lives and the lives of our children richer and more possible. Within the celebration of the erotic in all our endeavors, our work becomes a conscious decision—a longed-for bed which we enter gratefully and from which we rise up empowered. The erotic functions for us in several ways, and the first is in providing the power which comes from sharing deeply any pursuit with another person. The sharing of joy, whether physical, emotional, psychic, or intellectual, forms a bridge between the sharers which can be the basis for understanding much of what is not shared between them, and lessens the threat of their difference.</p>


                    <p>Another important way in which the erotic connection functions is the open and fearless underlining of our capacity for joy. In the way my body stretches to music and opens into response, hearkening to its deepest rhythms, so every level upon which I sense also opens to the erotically satisfying experience, whether it is dancing, building a bookcase, writing a poem, or examining an idea. That self-connection shared is a measure of the joy which I know myself to be capable of feeling, a reminder of my capacity for feeling. And that deep and irreplaceable knowledge of my capacity for joy comes to demand from all of my life that it be lived within the knowledge that such satisfaction is possible, and does not have to be called marriage, nor god, nor an afterlife.</p>


                    <p>This is one reason why the erotic is so feared, and so often relegated to the bedroom alone, when it is recognized at all. For once we begin to feel deeply all the aspects of our lives, we begin to demand from ourselves and from our life-pursuits that they feel in accordance with that joy which we know ourselves to be capable of. Our erotic knowledge empowers us, becomes a lens through which we scrutinize all aspects of our existence, forcing us to evaluate those aspects honestly in terms of their relative meaning within our lives. And this is a grave responsibility, projected from within each of us, not to settle for the convenient, the shoddy, the conventionally expected, nor the merely safe.</p>


                    <p>We have been raised to fear the yes within ourselves, our deepest cravings. But, once recognized, those which do not enhance our future lose their power and can be altered. The fear of our desires keeps them suspect and indiscriminately powerful, for to suppress any truth is to give it strength beyond endurance. The fear that we cannot grow beyond whatever distortions we may find within ourselves keeps us docile and loyal and obedient, externally defined, and leads us to accept many facets of our oppression. When we live outside ourselves, and by that I mean on external directives only rather than from our internal knowledge and needs, when we live away from those erotic guides from within ourselves, then our lives are limited by external and alien forms, and we conform to the needs of a structure that is not based on human need, let alone an individual’s.</p>


                    <p>But when we begin to live from within outward, in touch with the power of the erotic within ourselves, and allowing that power to inform and illuminate our actions upon the world around us, then we begin to be responsible to ourselves in the deepest sense. For as we begin to recognize our deepest feelings, we begin to give up, of necessity, being satisfied with suffering and self-negation, and with the numbness which so often seems like their only alternative in our society. Our acts against oppression become integral with self, motivated and empowered from within. In touch with the erotic, we become less willing to accept powerlessness, or those other supplied states of being which are not native to us, such as resignation, despair, self-effacement, depression, and self-denial.</p>


                    <p>And yes, there is a hierarchy. There is a difference between painting a back fence and writing a poem, but only one of quantity. And there is, for me, no difference between writing a good poem and moving into sunlight against the body of a woman I love.</p>


                    <p>This brings me to the last consideration of the erotic. To share the power of each other’s feelings is different from using another’s feelings as we would use a Kleenex. When we look the other way from our experience, erotic or otherwise, we use rather than share the feelings of those others who participate in the experience with us. And use without consent of the used is abuse. In order to be utilized, our sensual feelings must be recognized. The need for sharing deep feeling is a human need. When we look away from the importance of the erotic in the development and sustenance of our power, or when we look away from ourselves as we satisfy our erotic needs in concert with others, we use each other as objects of satisfaction rather than share our joy in the satisfying, rather than make connection with our similarities and our differences.</p>


                    <p>To refuse to be conscious of what we are feeling at any time, however comfortable that might seem, is to deny a large part of the experience, and to allow ourselves to be reduced to the pornographic, the abused, and the absurd. Recognizing the power of the erotic within our lives can give us the energy to pursue genuine change within our world, rather than merely settling for a shift of characters in the same weary drama. For not only do we touch our most profoundly creative source, but we do that which is self-affirming in the face of an anti-erotic society.</p>              
                </div>
            </Container>
}