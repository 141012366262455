import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';

export const Chats = () => {
	const navigate = useNavigate();
	const location = useLocation();
    const { pathname } = location;

	return <Container className="pt-3">
				<Card style={{marginBottom:10}}>
	                <Card.Header className="d-flex align-items-center" style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
	                    <div style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Chats</div>
	                    {(pathname === '/app/chats')&&<div style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF',marginLeft:'auto'}} 
	                    			onClick={() => navigate('/app/chats/compose')}><i className="bi bi-pencil-square"></i></div>}
	                    {(pathname === '/app/chats/conversation')&&<div className="d-flex align-items-center" style={{marginLeft:'auto'}}>
		                    <div style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF',marginRight:10}}>
		                    	<i className="bi bi-camera-video"></i>
		                    </div>
		                   	<div style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>
		                   		<i className="bi bi-telephone"></i>
		                   	</div>
	                    </div>}
	                </Card.Header>
	                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:12,color:'#0AC5BF'}}>
	                    <Outlet/>
	                </Card.Body>
	            </Card>
			</Container>
}