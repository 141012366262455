import React, {useState, useEffect, useRef} from 'react';
import { Container, Card, Col, Row, DropdownButton, InputGroup, Dropdown, Button, Form, Modal } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton, CustomDatePicker} from '../../components/ModularComponents';
import '../../css/profile.css';
import { useAuth } from '../../hooks/useAuth';
import {calculateAge, convertTimestampToDate, dateToSeconds} from '../../modules.js';
import {MyRadioInputs} from '../../components/ModularComponents';

const InfoItem = ({label, defaultValue, showStatus, disablePrivacy=false, disableEdit=false}) => {
	const { user, updateUser } = useAuth();
	const [publik, setPublik] = useState(true);
	const [edit, setEdit] = useState(false);
	const [value, setValue] = useState(defaultValue);
	const [valueArr, onSelect] = useState([false, false, false]);
	const [valueArr1, onSelect1] = useState([false, false, false]);
	const [showCalendar, setShowCalendar] = useState(false);
	const dateOfBirthInputRef = useRef(null);

	const handleDobFocus = () => {
        setShowCalendar(true);
        dateOfBirthInputRef.current.blur();
    };

    const handleCloseModal = () => setShowCalendar(false);

	useEffect(() => {
		console.log(defaultValue);
		console.log(value);
	},[value, defaultValue]);

	useEffect(() => {
		if(label === 'gender_at_birth'){
			if(defaultValue === 'male'){
				onSelect([true, false, false]);
			}else if(defaultValue === 'female'){
				onSelect([false, true, false]);
			}else if(defaultValue === 'other'){
				onSelect([false, false, true]);
			}
		}
		if(label === 'current_gender'){
			if(defaultValue === 'male'){
				onSelect1([true, false, false]);
			}else if(defaultValue === 'female'){
				onSelect1([false, true, false]);
			}else if(defaultValue === 'other'){
				onSelect1([false, false, true]);
			}
		}
	},[defaultValue, label]);

	const onHandleSelect = (arr) => {
		onSelect([...arr]);
		if(arr[0] === true){
			setValue('male');
		}else if(arr[1] === true){
			setValue('female');
		}else if(arr[2] === true){
			setValue('other');
		}
	}

	const onHandleSelect1 = (arr) => {
		onSelect1([...arr]);
		if(arr[0] === true){
			setValue('male');
		}else if(arr[1] === true){
			setValue('female');
		}else if(arr[2] === true){
			setValue('other');
		}
	}

	const handleDateOfBirth = (e) => {
		let val = e.target.value;
		setValue(val);
	}

	const onEdit = async() => {
		console.log('value:',value);
		if(label === 'date_of_birth'){
			let timeStamp = dateToSeconds(value);
			console.log('timestamp: ',timeStamp);
			await fetch(`http://13.244.179.175/users/edit_profile?token=${encodeURIComponent(user.access_token)}`,
					{method: "PATCH",
					headers: {'Content-Type': 'application/json'},
					body:JSON.stringify({date_of_birth:timeStamp})});
			updateUser({'date_of_birth':timeStamp});
		}else{
			await fetch(`http://13.244.179.175/users/edit_profile?token=${encodeURIComponent(user.access_token)}`,
					{method: "PATCH",
					headers: {'Content-Type': 'application/json'},
					body:JSON.stringify({[label] : value })});
			updateUser({[label]:value});
		}
		setEdit(false);	
	}

	return 	<div className="d-flex align-items-center justify-content-center" style={{borderWidth:1,borderColor:'red'}}>
				{!edit ?<>
					{(label !== 'date_of_birth' && label !== 'gender_at_birth' && label !== 'current_gender')&&<Card.Text style={{fontSize:13,fontWeight:'bold',minWidth:80,marginBottom:0,marginTop:0}}>{label}</Card.Text>}
					{(label === 'date_of_birth')&&<Card.Text style={{fontSize:13,fontWeight:'bold',minWidth:80,marginBottom:0,marginTop:0}}>Date of Birth</Card.Text>}
					{(label === 'gender_at_birth')&&<Card.Text style={{fontSize:13,fontWeight:'bold',minWidth:80,marginBottom:0,marginTop:0}}>Gender at Birth</Card.Text>}
					{(label === 'current_gender')&&<Card.Text style={{fontSize:13,fontWeight:'bold',minWidth:80,marginBottom:0,marginTop:0}}>Current gender</Card.Text>}					
					<Card.Text style={{fontSize:13,width:'100%',marginLeft:10,marginBottom:0,marginTop:0}}>{value}</Card.Text>
					<DropdownButton className="privacyBtn" title={publik?<i class="bi bi-globe-europe-africa" 
																			style={{color:'#0AC5BF'}}></i>
																		:<i class="bi bi-lock"
																			style={{color:'#0AC5BF'}}></i>}
									id="input-group-dropdown-1"
									disabled={disablePrivacy}
									style={{color:'#0AC5BF',borderWidth:0,borderColor:'transparent',backgroundColor:'transparent'}}>
						<Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-globe-europe-africa"></i> All members</Dropdown.Item>
						<Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-people-fill"></i> Connections</Dropdown.Item>
						<Dropdown.Item onClick={() => setPublik(false)}><i class="bi bi-lock"></i> Only me</Dropdown.Item>
					</DropdownButton>
					<Button onClick={() => { if(!disableEdit){
													setEdit(true)
											} }} 
							style={{backgroundColor:'transparent',borderWidth:0}}>
						<i class="bi bi-pencil" style={{color:(!disableEdit?'#0AC5BF':'#b5edeb')}}></i>
					</Button>
				</>
				:<InputGroup style={{margin:'5px 0 5px 0'}}>
					{!(label === 'gender_at_birth' || label === 'current_gender')&&<InputGroup.Text>
					{label === 'username'&&<i class="bi bi-at" style={{color:'#444'}}></i>}
					{label === 'date_of_birth'&&<i class="bi bi-cake2" style={{color:'#444'}}></i>}
					{label === 'age'&&<i class="bi bi-calendar3" style={{color:'#444'}}></i>}
					{(label === 'gender_at_birth' || label === 'current_gender')&&<></>}
					{label === 'city'&&<i class="bi bi-buildings" style={{color:'#444'}}></i>}
					{label === 'country'&&<i class="bi bi-geo-alt" style={{color:'#444'}}></i>}
					{label === 'kinks'&&<i class="bi bi-arrow-through-heart" style={{color:'#444'}}></i>}
					</InputGroup.Text>}
					{!(label === 'gender_at_birth' || label === 'current_gender' || label === 'date_of_birth')&&<Form.Control
						          placeholder={label === 'date_of_birth'?'Date of Birth':label}
						          aria-label={label}
						          aria-describedby="basic-addon1"
								  value={value}
								  onChange={(e) => setValue(e.target.value)}
						        />}
					{label === 'date_of_birth'&&<>
													<Form.Control   placeholder="Date of Birth"
															        aria-label={label}
															        ref={dateOfBirthInputRef}
	                												onFocus={handleDobFocus}
															        aria-describedby="basic-addon1"
																	value={value}
																	onChange={(e) => handleDateOfBirth(e)}
															        />
													{setShowCalendar&&<Modal  show={showCalendar} 
																			  onHide={handleCloseModal} centered>
										                <Modal.Header closeButton>
										                    <Modal.Title style={{color:'#033b39'}}>Calendar</Modal.Title>
										                </Modal.Header>
										                <Modal.Body>
										                    <CustomDatePicker dateValue={value.replaceAll("-","/")} setDateValue={(x) => setValue(x)} />
										                </Modal.Body>
										            </Modal>}
									            </>}
					{label === 'gender_at_birth'&&<MyRadioInputs valueArr={valueArr} 
																onSelect={onHandleSelect}
																textStyle={{color:'black',fontSize:11}}
																labelStyle={{color:'#555',fontSize:11}}
																btnStyle={{width:15,height:15,borderRadius:7.5}}/>}
					{label === 'current_gender'&&<MyRadioInputs label="Current gender" 
																valueArr={valueArr1} 
																onSelect={onHandleSelect1}
																textStyle={{color:'black',fontSize:11}}
																labelStyle={{color:'#555',fontSize:11}}
																btnStyle={{width:15,height:15,borderRadius:7.5}}/>}
					<Button style={{backgroundColor:'transparent',borderColor:'#eee',color:'#444'}} onClick={() => setEdit(false)}>
						<i class="bi bi-close" style={{color:'#444',marginRIght:5}}></i> Cancel
					</Button>
					<Button style={{backgroundColor:'transparent',borderColor:'#eee',color:'#444'}} onClick={() => onEdit()}>
						<i class="bi bi-floppy" style={{color:'#444',marginRIght:5}}></i> Save
					</Button>
				</InputGroup>}
			</div>
}

export const Info = () => {
	const { user } = useAuth();
	return <Container className="p-0">
				<Card style={{marginBottom:10}}>
					<Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
						<Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Basic Info</Card.Text>
					</Card.Header>
					<Card.Body style={{paddingLeft:20,paddingRight:20}}>
						<InfoItem label="username" defaultValue={user?.username} disablePrivacy={true} showStatus="public"/>
						<InfoItem label="date_of_birth" defaultValue={convertTimestampToDate(user?.date_of_birth)}/>
						<InfoItem label="age" defaultValue={calculateAge(user?.date_of_birth)} disableEdit={true}/>
						<InfoItem label="gender_at_birth" defaultValue={user?.gender_at_birth}/>
						<InfoItem label="current_gender" defaultValue={user?.current_gender}/>
						<InfoItem label="city" defaultValue={user?.city}/>
						<InfoItem label="country" defaultValue={user?.country}/>
						<InfoItem label="kinks" defaultValue={user?.kinks}/>
					</Card.Body>
				</Card>
			</Container>

}