import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import '../css/linkEl.css';

export const RegistrationReq = () => {
	const navigate = useNavigate();

	return 	<>
				<Container>
					<div className="pt-4">
						<h2 style={{fontStyle:'italic',fontWeight:'bold',color: '#0ac5bf'}}>Registration requirements</h2>
						<p>Shap Social Club encourages the redefinition of norms around play, pleasure, sexuality, relationships and desire while fostering inclusivity and acceptance.</p>
						<p style={{color:'#0ac5bf',fontWeight:'bold'}}>A Social Club for the Adventurous:</p>
						<p><em><strong>The Curious</strong></em>: Those who have a strong desire to know or learn something. They are driven by inquisitiveness and are constantly seeking out new information and experiences.</p>
						<p><em><strong>The Experiential</strong></em>: The ones who prioritize direct experience over theoretical knowledge. They learn by doing and often seek hands-on activities, adventures, and practical engagement with the world. Their focus is on gaining insights through personal involvement and sensory experiences.</p>
						<p><em><strong>The Explorers</strong></em>: Those who actively seek out new places, ideas, or experiences. They often push the boundaries of what is known. Explorers are driven by a desire to uncover the unknown and expand their horizons.</p>
						<p style={{ color:'#0ac5bf' }}><strong>Eligibility Criteria:</strong></p>
						<p>We are delighted that you're considering joining our vibrant and inclusive social club. To ensure that we all share a common understanding and respect for our values, please take a moment to review our eligibility requirements:
						</p><p><strong>Age Requirement</strong>: We welcome applicants who are over 25 years old.</p>
						<p><strong>Erotic Freedom</strong>: Our social club is a space where liberated adults can freely explore and experiment with their sexuality and sexual relationships without judgment or discrimination. If you feel that expressions of erotic freedom may not align with your comfort levels, we kindly ask that you reflect on whether this social club is the right fit for you.</p>
						<p><strong>Self-Reflection</strong>: We encourage prospective members to consider their motivations for joining our social club. We believe in fostering a positive and enriching environment, and we invite you to join with the intention of enhancing your life and the lives of others.&nbsp;</p>
						<p><strong>Human-First Approach:</strong> We ask all members to treat one another with the utmost respect and understanding. Embrace the opportunity to see each individual as a unique and complex person, deserving of empathy and consideration.&nbsp;</p>
						<p><strong>Personal Agency and Mindfulness:</strong> Valuing each other's autonomy and practicing mindfulness in all interactions is essential. This involves being thoughtful and respectful in your interactions and connections with others, living in the present moment, and taking full responsibility for your actions and choices.</p>
						<p><strong>Valuing Human Connection: </strong>We seek individuals who value and prioritize authentic human connections. As social beings, we thrive on companionship and community, which significantly impact our mental health and overall happiness. We are looking for members who understand the importance of these relationships and are committed to building them through trust, respect, and mutual support.</p>
						<p><strong>Adherence to the <a style={{color:'#0AC5BF'}} href="https://www.theharveyinstitute.com/six-principles-of-sexual-health">six principles of sexual health</a>:</strong> Consent, Non-exploitation, Protection from STIs and unintended pregnancy, Honesty, Shared values and Mutual Pleasure.</p>
						<p><strong>Shared Values</strong>: To ensure harmony within our social club, we ask that you align with our core values, which are embodied in the acronym "CLOSER AF": These <span style={{color:'#0AC5BF',fontWeight:'bold',cursor:"pointer" }} onClick={()=>navigate('/our-story')}>values</span> include:</p>
						<p><strong>C</strong>-Consent, Communication &amp; Consideration<br/><strong>L</strong>-Look out for each other<br/><strong>O</strong>-Open-Minded<br/><strong>S</strong>-Safety<br/><strong>E</strong>-Empathy<br/><strong>R</strong>-Respect<br/><strong>A</strong>-Authentic<br/><strong>F</strong>-Feedback</p>
					<p>We look forward to welcoming members who resonate with these principles and values. If you feel aligned with our social club's ethos, please proceed by completing the <span style={{color:'#0AC5BF',fontWeight:'bold',cursor:"pointer"}} onClick={()=>navigate('/signup')}>registration form.</span></p>
					<p>Thank you for considering joining us, and we hope to embark on this journey together!</p>
					</div>
				</Container>
				<div className="pt-4 mb-0 pb-4" style={{color:'white', backgroundColor:'black'}}>
					<Container>
						<h2 style={{fontStyle:'italic',fontWeight:'bold',color:'#fed700'}}>Your membership unlocks the following benefits:</h2>
						<p style={{ color:'white' }}><strong style={{color:'#fed700'}}>Openness and Self-Expression</strong> : Express your true self freely and authentically in a space that encourages you to break free from societal expectations. Embrace who you are without hesitation.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Exciting Adventures</strong></em>: With a range of interests and passions catered to, our social club offers endless opportunities for enjoyment and exploration. </p>
                        <p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Sense of Belonging</strong></em>: Join a social club that celebrates your uniqueness. Our inclusive social club fosters a strong sense of belonging and acceptance.</p>
                        <p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Safer and Empowering Space</strong></em>:Explore your desires confidently in a judgment-free environment. Your safety and empowerment are our top priorities.</p>
                        <p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Diversity and Inclusion</strong></em>: Experience the richness of a truly inclusive social club that cultivates a sense of belonging and acceptance. Our diverse social club enhances your social interactions and broadens your horizons.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Sex-Positive and Pleasure-Positive</strong></em>: Embrace a culture that celebrates pleasure, erotic diversity, autonomy, freedom, and empowerment. Engage in fulfilling erotic experiences that enhance your overall well-being.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Meaningful Connections</strong></em>: Forge deep relationships with people who share your interests and values. Our supportive network strengthens social bonds and creates lasting friendships.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Enhanced Well-Being</strong></em>: Connect with like-minded individuals and engage in pleasurable activities that contribute to your happiness and mental health. Our social club is a haven for those seeking joy and fulfillment.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Personal Growth</strong></em>: Dive into opportunities for self-discovery and new experiences that support your personal development. We believe in nurturing your growth and helping you realize your potential.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Improved Quality of Life</strong></em>: Satisfy your curiosity and drive for new experiences with a social club that supports continuous learning and growth. Enrich your life by seeking out new information and adventures.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Increased Fulfillment</strong></em>:  Engage in activities aligned with your passions to lead a more satisfying and enriched life. Our social club offers countless ways to pursue what truly fulfills you.</p>
						<p style={{ color:'white' }}><em><strong style={{color:'#fed700',textAlign:'justify'}}>Supportive social club</strong></em>: Be part of a nurturing environment that prioritizes safety, respect, and empathy. Our social club is dedicated to creating a supportive and empowering space for all members.</p>
					</Container>
				</div>
			</>	
}