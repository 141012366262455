import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';


export const Settings = () => {

	return 	<Container className="pt-3">
				<Card style={{marginBottom:10}}>
	                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
	                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Settings</Card.Text>
	                </Card.Header>
	                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:12,color:'#0AC5BF'}}>
	                	<div className="p-2 mb-2 d-flex align-items-center" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer'}}>
            				<span><i className="bi bi-lock"></i> Reset Passphrase</span>
            				<span style={{marginLeft:'auto',color:'#999'}}><i className="bi bi-pencil"></i></span>
            			</div>
                    	<div className="p-2 mb-2 d-flex align-items-center" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer'}}>
            				<span><i className="bi bi-person-check"></i> Request Verification</span>
            				<span style={{marginLeft:'auto',color:'#999'}}><i className="bi bi-pencil"></i></span>
            			</div>
            			<div className="p-2 mb-2 d-flex align-items-center" style={{fontSize:12,borderBottom:'1px solid #eee',cursor:'pointer',color:'red'}}>
            				<span><i className="bi bi-x-circle"></i> Delete Account</span>
            			</div>
	                </Card.Body>
	            </Card>
			</Container>
}