export const calculateAge = (dateOfBirthUnix) => {
    // Convert the Unix timestamp from seconds to milliseconds
    const dateOfBirth = new Date(dateOfBirthUnix * 1000);
    
    // Get the current date
    const currentDate = new Date();
    
    // Calculate the difference in years
    let age = currentDate.getFullYear() - dateOfBirth.getFullYear();
    
    // Check if the current date is before the birth date in the current year
    // If so, subtract one year from the age
    const monthDiff = currentDate.getMonth() - dateOfBirth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < dateOfBirth.getDate())) {
        age--;
    }
    
    return age;
}

export const dateToSeconds = (dateStr) => {
    // Split the date string and rearrange it into a standard format
    const parts = dateStr.split('-');
    const months = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
        Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };
    const formattedDate = `${parts[2]}-${months[parts[0]]}-${parts[1]}`;

    // Create a new Date object using the standard format "YYYY-MM-DD"
    const date = new Date(formattedDate);
    return Math.floor(date.getTime() / 1000);
}

export const convertTimestampToDate = (unixTimeStamp, format = { fulldate: true }) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Convert the Unix timestamp from seconds to milliseconds
    const d = new Date(unixTimeStamp * 1000);

    // Extract date components
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate().toString().padStart(2, '0');

    // Determine the output format
    if (format.fulldate) {
        return `${months[month]}-${day}-${year}`;
    }
    if (format.monthYear) {
        return `${months[month]}-${year}`;
    }
    if (format.year) {
        return `${year}`;
    }

    // Default output if no format matched
    return `${months[month]}-${day}-${year}`;
};

export const convertTimestampToDateTime = (unixTimeStamp, format = { fulldate: true }) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Convert the Unix timestamp from seconds to milliseconds
    const d = new Date(unixTimeStamp * 1000);

    // Extract date components
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate().toString().padStart(2, '0');

    // Extract time components
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');

    // Determine the output format
    let dateStr = '';
    if (format.fulldate) {
        dateStr = `${months[month]}-${day}-${year}`;
    } else if (format.monthYear) {
        dateStr = `${months[month]}-${year}`;
    } else if (format.year) {
        dateStr = `${year}`;
    }

    // Add time to the date string
    const timeStr = `${hours}:${minutes}`;
    
    return `${dateStr}|${timeStr}`;
};


export const getRange = (num1, num2, increment) => {
    if (increment <= 0) {
        throw new Error("Increment must be greater than 0");
    }

    const result = [];
    for (let i = num1; i < num2; i += increment) {
        result.push(i);
    }
    return result;
}

export const getYear = (date) => {
    return new Date(date).getFullYear();
}

export const getMonth = (date) => {
    return new Date(date).getMonth();
}

export const getDate = (date) => {
    return new Date(date).getDate();
}

// Module for validating username
export const validateUsername = (username='') => {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    return alphanumericRegex.test(username) && username.length >= 3;
};

// Module for validating email
export const validateEmail = (email='') => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Module for validating password
export const validatePassword = (password='') => {
    const passwordRegex = /^(?=.*[a-z])(?=.*\d)(?=.*[\s!@#$%^&*()_+])[A-Za-z\d\s!@#$%^&*()_+]{16,}$/;
    return passwordRegex.test(password);
};

// Module for validating date of birth
export const validateDateOfBirth = (dateOfBirth=915148800) => {
    return Number(dateOfBirth) > 0;
};

// Module for validating gender at birth
export const validateGenderAtBirth = (gender='') => {
    if (typeof gender !== 'string') {
        return false;
    }
    const validGenders = ['male', 'female', 'other'];
    return validGenders.includes(gender.toLowerCase());
};

// Module for validating country string
export const validateCountry = (country='') => {
    if (typeof country !== 'string') {
        return false;
    }
    return country.trim() !== '';
};

// Module for validating city string
export const validateCity = (city='') => {
    if (typeof city !== 'string') {
        return false;
    }
    return city.trim() !== '';
};

// Module for generating validation objects
export const validateEntries = (username, email, password, dateOfBirth, genderAtBirth, country, city, guidelineAgreement, termsAgreement, privacyAgreement) => {
    const validations = [
        { label: 'username', value: username, isValid: validateUsername(username), errorMessage:'Username must be alphanumeric and at least 3 characters long.' },
        { label: 'email', value: email, isValid: validateEmail(email), errorMessage:'Invalid email address.' },
        { label: 'password', value: password, isValid: validatePassword(password), errorMessage:'Password must be at least 16 characters long, contain spaces, a special character, a numeric value, and an uppercase letter.' },
        { label: 'date_of_birth', value: dateOfBirth, isValid: validateDateOfBirth(dateOfBirth), errorMessage:'Invalid date of birth. This is a required field.' },
        { label: 'gender_at_birth', value: genderAtBirth, isValid: validateGenderAtBirth(genderAtBirth), errorMessage:'Gender at birth must be male, female, or other. This is a required field' },
        { label: 'country', value: country, isValid: validateCountry(country), errorMessage:'Country cannot be empty.' },
        { label: 'city', value: city, isValid: validateCity(city), errorMessage:'City cannot be empty.' },
        { label: 'guidelineAgreement', value: guidelineAgreement, isValid:(guidelineAgreement?true:false), errorMessage:'Please check the checkbox. Cheking this check box is a required step'},
        { label: 'termsAgreement', value: termsAgreement, isValid:(termsAgreement?true:false), errorMessage:'Please check the checkbox. Cheking this check box is a required step' },
        { label: 'privacyAgreement', value: privacyAgreement, isValid:(privacyAgreement?true:false), errorMessage:'Please check the checkbox. Cheking this check box is a required step' }    
    ];
    return validations;
};

export const validateAll = (username, email, password, dateOfBirth, genderAtBirth, country, city, guidelineAgreement, termsAgreement, privacyAgreement) => {
    let validations = validateEntries(username, email, password, dateOfBirth, genderAtBirth, country, city, guidelineAgreement, termsAgreement, privacyAgreement);

    let isValid = true;
    const invalidValues = {};

    for (let i = 0; i < validations.length; i++) {
        const { label, value, isValid: validation, errorMessage } = validations[i];

        if (!validation) {
            isValid = false;
            invalidValues[label] = {
                value,
                errorMessage
            };
        }
    }

    return { isValid, invalidValues };
};


