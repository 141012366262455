import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Container, Card, Col, Row, Button } from 'react-bootstrap';
import {convertTimestampToDateTime} from '../../modules.js';
import { useAuth } from '../../hooks/useAuth';

export const ChatInput = ({onSend}) => {
	const [value, setValue] = useState('');
	const send = useCallback(() => {
		onSend(value);
		setValue('');
	},[value, onSend]);

	return 	<div className="d-flex align-items-center">
				<textarea placeholder="Write your message..."
						  value={value}
						  onChange={(input) => setValue(input.target.value)}
						  className="w-100" 
						  style={{border:'1px solid #0AC5BF',
						  		  borderTopLeftRadius:4,borderBottomLeftRadius:4,height:80}}></textarea>
				<Button style={{backgroundColor:'#0AC5BF',
								borderColor:'#0AC5BF',
								color:'white',
								height:80,
								borderTopLeftRadius:0,
								borderBottomLeftRadius:0,}}
						onClick={send}>Send</Button>
			</div>
}

export const ChatBubble = ({author, text, date}) => {
	const { user } = useAuth();
	return  <div className="p-2 mb-1" style={{width:'100%',backgroundColor:(user.username === author.username)?'#f0fbfa':'#eee'}}>
				<div style={{color:(user.username === author.username)?'':'#777'}}><b>{(user.username === author.username)?'me':author.username}</b></div>
				<div style={{color:(user.username === author.username)?'':'#555'}}>{text}</div>
				<div style={{color:(user.username === author.username)?'':'#999',textAlign:'end'}}>{convertTimestampToDateTime(date).split('|')[1]}</div>
			</div>
}

export const Compose = () => {
	const { user } = useAuth();
	const divRef = useRef(null);
	const [chatArray, setChatArray] = useState([]);

	const onSend = (value) => {
		//add value to chat array
		let currentTimeInSeconds = Math.floor(Date.now() / 1000);
		let sendObj = {text:value,author:user,date:currentTimeInSeconds}
		setChatArray([...chatArray, sendObj]);
	}

	useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    }, [chatArray]);

	useEffect(() => {
		console.log(JSON.stringify(chatArray));
	},[chatArray])


	return 	<Container className="pt-3">
				<div ref={divRef} className="w-100 h-100" style={{minHeight:300,width:'100%'}}>
				{chatArray.map(({text, author, date},index) => {
					return <ChatBubble author={author} text={text} date={date}/>
				})}
				</div>
				<ChatInput onSend={onSend}/>
			</Container>
}