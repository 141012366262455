import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import {Container,Stack, DropdownButton, Dropdown, Nav, Row, Col, Image, Badge, Card, Button, Form, InputGroup} from 'react-bootstrap';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {PostCard, MiniAvatar, Avatar, CardButton, ProfileAvatar} from '../components/ModularComponents';
import '../css/profile.css';
import { useAuth } from '../hooks/useAuth';
import { useOutsideClick } from '../hooks/useOutsideClick';
import {calculateAge, convertTimestampToDate} from '../modules.js';

export const Profile = () => {
	const { user } = useAuth();
	const location = useLocation();
    const { pathname } = location;
	const navigate = useNavigate();
	const [imageUrl, setImageUrl] = useState(null);
	const [backgroundUrl, setBackgroundUrl] = useState(null);
	const [isOnline, setIsOnline] = useState(true);
	const [username, setUsername] = useState("eliezerkombe"); // User's username
	const [joinDate, setJoinDate] = useState("June 2024");
	const [showProfileOptionsDrowpdown, setShowProfileOptionsDropdown] = useState(false);
	const profileOptionsMenuRef = useRef(null);
	const couple = false;

	const exitProfileOptionsMenu = () => {
        console.log('You clicked outside of me!');
        setShowProfileOptionsDropdown(false);
    };

    useOutsideClick(profileOptionsMenuRef, exitProfileOptionsMenu);

	return <Container className="pt-2">
				<Row>
					<Col xs={12} md={4} className="d-none d-md-block p-2 pt-0">
						<Nav className="flex-column border border-1 rounded-3" style={{borderRadius: '8px',overflow:'hidden'}}>
							<Nav.Link onClick={() => navigate("/app/profile")} className="border-bottom" style={{ color: (pathname === '/app/profile' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-clock-history"></i> Timeline
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/info")} className="border-bottom" style={{ color: (pathname === '/app/profile/info' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/info' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-info-circle"></i> Info
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/followers")} className="border-bottom" style={{ color: (pathname === '/app/profile/followers' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/followers' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-people"></i> Followers
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/connections")} className="border-bottom" style={{ color: (pathname === '/app/profile/connections' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/connections' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-people"></i> Connections
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/circles")} className="border-bottom" style={{ color: (pathname === '/app/profile/circles' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/circles' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-circle"></i> Circles
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/groups")} className="border-bottom" style={{ color: (pathname === '/app/profile/groups' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/groups' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-people-fill"></i> Groups
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/forums")} className="border-bottom" style={{ color: (pathname === '/app/profile/forums' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/forums' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-chat-square-dots"></i> Forums
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/albums")} className="border-bottom" style={{ color: (pathname === '/app/profile/albums' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/albums' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-images"></i> Albums
							</Nav.Link>
							<Nav.Link onClick={() => navigate("/app/profile/docs")} style={{ color: (pathname === '/app/profile/docs' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/docs' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-file-earmark-text"></i> Documents
							</Nav.Link>
						</Nav>
					</Col>
					<Col xs={12} md={8}>
						<Container className="mosaic-background" style={{minHeight:180,
																		maxHeight:180,
																		backgroundColor:'#999',
																		borderTopRightRadius:8,
																		borderTopLeftRadius:8,
																		borderBottomRightRadius:0,
																		borderBottomLeftRadius:0,
																		overflow:'hidden'}}>
							{Array.from({ length: 100 },(value, index) => {
							    let angle = Math.random() * 360;
							    return <i key={index} className="bi bi-image" style={{transform:`rotate(${angle}deg)`,color:'#888'}}></i>
							 })}
							<div style={{position:'absolute',right:5,top:5,}}>
								<div onClick={() => setShowProfileOptionsDropdown(!showProfileOptionsDrowpdown)} style={{color:'white',border:'2px solid white',borderRadius:'50%',padding:'0 4px'}}>
									<i class="bi bi-three-dots-vertical" style={{color:'white'}}></i>
								</div>
								{showProfileOptionsDrowpdown&&<div ref={profileOptionsMenuRef} style={{padding:'5px 5px',backgroundColor:'white',width:140,position:'absolute',zIndex:888,marginLeft:-110,marginTop:5, borderRadius:8 }}>
				        			<div style={{cursor:'pointer'}}>
				        				<p className="m-2 p-0" style={{color:'#444',fontWeight:'bold',fontSize:13}}><i className="bi bi-flag-fill" style={{marginRight:4}}></i>Report</p>
				        			</div>
				        			<div style={{cursor:'pointer'}}>
				        				<p className="m-2 p-0" style={{color:'#444',fontWeight:'bold',fontSize:13}}><i className="bi bi-ban" style={{marginRight:4}}></i>Block</p>
				        			</div>
				        		</div>}
				        	</div>

						</Container>
						<Container className="profile-background d-flex align-items-start justify-content-start" 
									style={{backgroundColor:'#000',
											marginBottom:10, 
											padding: '0px 0',
											borderTopRightRadius:0,
											borderTopLeftRadius:0,
											borderBottomRightRadius:8,
											borderBottomLeftRadius:8}}>
							<Container className="m-0 pb-0">
								<div className="m-0 pb-0 d-flex align-items-start justify-content-start">
									<ProfileAvatar couple={couple}/>
									{!couple&&<div className="d-flex align-items-start justify-content-start w-100">
										<div style={{marginLeft:5,paddingTop:5,width:'100%',}}>
											<div style={{fontSize:14,fontWeight:'bold',color:'white',display:'flex',alignItems:'center'}}>
												<div>{user?.username}</div>
												<i class="bi bi-patch-check-fill" style={{color:'#fed700',marginLeft:4}}></i>
												<div style={{border:'2px solid #0d6efd',
															color:'#0d6efd',
															width:30,
															height:30,
															borderRadius:15,
															marginLeft:5,
															textAlign:'center',
															fontSize:14,padding:2}}>{calculateAge(user?.date_of_birth)}
												</div>
											</div>
										</div>
										<div style={{display:'flex',justifyContent:'end',width:'100%',paddingTop:5}}>
											<div style={{color:'white',border:'2px solid #fed700',borderRadius:'50%',padding:'0 4px',}}>
												<i class="bi bi-envelope" style={{color:'#fed700'}}></i>
											</div>
											<div style={{color:'white',border:'2px solid #fed700',borderRadius:'50%',padding:'0 2px',marginLeft:5}}>
												<i class="bi bi-person-plus-fill" style={{color:'#fed700',marginLeft:4}}></i>
											</div>
											<div style={{color:'#fed700',border:'2px solid #fed700',borderRadius:8,padding:'0 5px',marginLeft:5}}>
												Follow
											</div>
										</div>
									</div>}
									{couple&&<div className="w-100" style={{overflow:'hidden'}}>
										<div style={{marginLeft:0,paddingTop:5,width:'100%',}}>
											<div className="d-flex align-items-center flex-wrap" style={{fontSize:14,fontWeight:'bold',color:'white',width:'100%'}}>
												<div className="d-flex align-items-center flex-wrap" style={{marginRight:20}}>
													<div className="flex-shrink-0" style={{marginRight:4}}>{username} &</div>
													<div className="d-flex align-items-center flex-shrink-0">
														<div>ElizabethKombe</div>
														<i class="bi bi-patch-check-fill" style={{color:'#fed700',marginLeft:4}}></i>
														<div style={{border:'2px solid #0d6efd',
																	color:'#0d6efd',
																	width:30,
																	height:30,
																	borderRadius:15,
																	marginLeft:5,
																	textAlign:'center',
																	fontSize:14,padding:2}} className="flex-shrink-0">{calculateAge(user?.date_of_birth)}
														</div>
														<div style={{border:'2px solid purple',
																	color:'purple',
																	width:30,
																	height:30,
																	borderRadius:15,
																	marginLeft:5,
																	textAlign:'center',
																	fontSize:14,padding:2}} className="flex-shrink-0">{calculateAge(user?.date_of_birth)}
														</div>
													</div>
												</div>
												<div className="d-none d-md-none d-lg-flex" style={{display:'flex', alignItems:'center', marginLeft:'auto'}}>
													<div className="flex-shrink-0" style={{color:'white',border:'2px solid #fed700',borderRadius:'50%',padding:'0 4px',}}>
														<i class="bi bi-envelope" style={{color:'#fed700'}}></i>
													</div>
													<div className="flex-shrink-0" style={{color:'white',border:'2px solid #fed700',borderRadius:'50%',padding:'0 2px',marginLeft:5}}>
														<i class="bi bi-person-plus-fill" style={{color:'#fed700',marginLeft:4}}></i>
													</div>
													<div className="flex-shrink-0" style={{color:'#fed700',border:'2px solid #fed700',borderRadius:8,padding:'0 5px',marginLeft:5}}>
														Follow
													</div>
												</div>
												<div className="d-flex d-md-flex d-lg-none flex-shrink-0" style={{display:'flex', alignItems:'center'}}>
													<div className="flex-shrink-0" style={{color:'white',border:'2px solid #fed700',borderRadius:'50%',padding:'0 4px',}}>
														<i class="bi bi-envelope" style={{color:'#fed700'}}></i>
													</div>
													<div className="flex-shrink-0" style={{color:'white',border:'2px solid #fed700',borderRadius:'50%',padding:'0 2px',marginLeft:5}}>
														<i class="bi bi-person-plus-fill" style={{color:'#fed700',marginLeft:4}}></i>
													</div>
													<div className="flex-shrink-0" style={{color:'#fed700',border:'2px solid #fed700',borderRadius:8,padding:'0 5px',marginLeft:5}}>
														Follow
													</div>
												</div>
											</div>
										</div>
									</div>}
								</div>
								<Row className="m-0 pb-3 pt-1">
									<div style={{fontSize:14,color:'#ccc',fontWeight:'bold'}}><i class="bi bi-geo-alt-fill" style={{color:'#fed700',marginRight:4}}></i>{`${user?.city}, ${user?.country}`}</div>
									<div className="d-flex" style={{color:'#ccc',fontSize:11}}><p className="p-0 mt-0 mb-0 ml-0 mr-3" style={{color:'#fed700',marginRight:4,fontWeight:'bold'}}>First move</p><p className="p-0 m-0" style={{fontWeight:'bold'}}>open</p></div>
									<div className="d-flex" style={{color:'#ccc',fontSize:11}}><p className="p-0 mt-0 mb-0 ml-0 mr-3" style={{color:'#fed700',marginRight:4,fontWeight:'bold'}}>Interaction</p><p className="p-0 m-0" style={{fontWeight:'bold'}}>eroticism</p></div>
									<div style={{color:'#ccc',fontSize:11}}>Joined {convertTimestampToDate(user?.created_at,{monthYear:true})}</div>
								</Row>
							</Container>
						</Container>
						<Nav className="d-md-none flex-grow-1 d-flex flex-rowflex-wrap  justify-content-around border border-1 rounded-3 align-items-stretch mb-1 pt-0 pb-0" style={{borderRadius: '8px',overflow:'hidden'}}>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile")} className="border-end border-bottom text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile' ? '#0AC5BF' : 'white') }}>
									<i className="bi bi-clock-history"></i> Timeline
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/info")} className="border-end border-bottom text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/info' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/info' ? '#0AC5BF' : 'white') }}>
									<i className="bi bi-info-circle"></i> Info
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/followers")} className="border-bottom text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/followers' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/followers' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-people"></i> Followers
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/connections")} className="border-bottom border-end text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/connections' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/connections' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-people"></i> Connections
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/circles")} className="border-end border-bottom text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/circles' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/circles' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-circle"></i> Circles
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/groups")} className="border-bottom text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/groups' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/groups' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-people-fill"></i> Groups
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/forums")} className="border-end border-bottom text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/forums' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/forums' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-chat-square-dots"></i> Forums
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/albums")} className="border-end border-bottom text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/album' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/album' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-images"></i> Albums
								</Nav.Link>
							</Col>
							<Col xs={4} md={3} className="nav-col-custom">
								<Nav.Link onClick={() => navigate("/app/profile/docs")} className="text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/profile/docs' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/profile/docs' ? '#0AC5BF' : 'white') }}>
								<i className="bi bi-file-earmark-text"></i> Documents
								</Nav.Link>
							</Col>						
						</Nav>
						<Outlet />
					</Col>
				</Row>
			</Container>
}