import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack,Card,Row,Col} from 'react-bootstrap';
import {Outlet} from 'react-router-dom';
import '../css/forumSearch.css';
import {ForumCard} from '../components/ModularComponents';

export const Forums = () => {
	const forums = [{title:'Analysis',subtitle:'Analyse/dissect a topic or issue',description:'',discussions:[]},
				   {title:'News',subtitle:'Current events or updates',description:'',discussions:[]},
				   {title:'Myths',subtitle:'Debunk myths and misconception',description:'',discussions:[]},
				   {title:'Random',subtitle:'Diverse conversations',description:'For discussions that don\'t fit into specific forum categories but are still relevant and valuable to the circle.',discussions:[]},
				   {title:'Vent',subtitle:'Express frustration, anger or dissatisfaction',description:'',discussions:[]},
				   {title:'Feedback',subtitle:'Feedback or suggestions',description:'',discussions:[]},
				   {title:'Humor',subtitle:'Funny or lighthearted',description:'',discussions:[]},
				   {title:'Guidance',subtitle:'Guidance or instructions',description:'',discussions:[]},
				   {title:'Q & A',subtitle:'Seeking answers or advice',description:'',discussions:[]},
				   {title:'NSFW',subtitle:'Sexually explicit content',description:'',discussions:[]},
				   {title:'Stories',subtitle:'Stories',description:'',discussions:[]},
				   {title:'Resources',subtitle:'Useful resources or tools',description:'',discussions:[]},]

	return 	<Container>
				<Card className="mt-2" style={{backgroundColor:'black',minHeight:240,display:'flex',alignItems:'center',justifyContent:'center',paddingLeft:10,paddingRight:10}}>
					<div>
						<h3 style={{color:'#fed700',textAlign:'center'}}>Open Discussions</h3>
						<p style={{color:'#fed700',textAlign:'center',fontSize:11}}>
						This is a space for open discussion, free expression, and exploration of various topics that are not catered for in the groups.
						Discuss taboo or sensitive subjects that may be considered off-limits in mainstream spaces.
						Ask questions, share your stories and connect with other members around Africa.
						While no topic is off limit, lets engage within the confines of our interaction guidelines and
						terms of service to ensure a respectful and constructive environment for all members.</p>
					</div>
					<div style={{display:'flex', 
								alignItems:'center',
								border:'1px solid #888',
								borderRadius:5,
								width:330,
								padding:'2px 5px'}}>
						<i class="bi bi-search" style={{color:'#888'}}></i>
						<input className="forumSearch" placeholder="Search forums" style={{backgroundColor:'black',borderRadius:4,borderWidth:0}} />
					</div>
				</Card>
				<Row style={{paddingLeft:10,paddingRight:10}}>
					{forums.map(({title, subtitle, description, discussions},index) => {
						return  <Col xs={12} md={6} xl={4}>
									<ForumCard title={title} label={subtitle} description={description} discussions={discussions.length}/>
								</Col>
					})}
					<Outlet/>
				</Row>
			</Container>
}