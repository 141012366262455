import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, Image } from 'react-bootstrap';
import {ForumCard} from '../../components/ModularComponents';

export const ForumsJoined = ({label="forums"}) => {
	const forums = [{title:'Myths',subtitle:'Debunk myths and misconception',description:'',discussions:[]},
                  {title:'Random',subtitle:'Diverse conversations',description:'For discussions that don\'t fit into specific forum categories but are still relevant and valuable to the circle.',discussions:[]},
                  {title:'Humor',subtitle:'Funny or lighthearted',description:'',discussions:[]}];

	return  <Container>
				<Card style={{marginBottom:10}}>
	                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
	                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Forums you've participated in...</Card.Text>
	                </Card.Header>
	                <Card.Body>
		                <Row style={{minHeight:30}}>
			              {forums.map(({title, subtitle, description, discussions},index) => {
			                return  <Col xs={12} md={6}>
				                      <ForumCard title={title} label={subtitle} description={description} discussions={discussions.length}/>
				                    </Col>
			              })}
			            </Row>
	                </Card.Body>
                </Card>
			</Container>
}