import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {ScrollToTop} from '../components/ScrollToTop';

export const Guidelines = () => {

	return  <>
          <ScrollToTop/>
          <Container className="mt-4">
            <Row>
              <Col>
                <div className="text-justify">
                <h2 style={{fontStyle:'italic',fontWeight:'bold',color: '#0ac5bf'}}>Interaction Guidelines</h2>
                  <p>
                  Welcome to our adult social club! Here, we embrace the profound wisdom of Audre Lorde’s words: “The erotic is a measure between the beginnings of our sense of self and the chaos of
                    our strongest feelings. It is an internal sense of satisfaction to which, once we have experienced it, we know we can aspire. The erotic is not a question only of what we do; it is a question
                    of how acutely and fully we can feel in the doing.”
                  </p>
                  <p>
                    As W.H. Auden eloquently said, “To be a complete human being, to fully inhabit your own vitality, is to live undivided within your own nature. No part of us is more habitually exiled,
                    caged, and crushed under the weight of millennia of cultural baggage than Eros—the part that includes sexuality but transcends it to also include our capacity for spontaneity and
                    playfulness.”
                  </p>
                  <p>
                  The erotic provides the power that comes from sharing deeply any pursuit with another person. Sharing joy—whether physical, emotional, psychic, or intellectual—forms a bridge between the sharers, creating a foundation for understanding and reducing the threat of differences. Another essential function of the erotic connection is to openly and fearlessly underline our capacity for joy.
                  </p>
                  <p>
                  We have been raised to fear the “yes” within ourselves, our deepest cravings. But once recognized, those that do not enhance our future lose their power and can be altered. The fear of our desires keeps them suspect and indiscriminately powerful, for to suppress any truth is to give it strength beyond endurance. The fear that we cannot grow beyond whatever distortions we may find within ourselves keeps us docile, loyal, and obedient, leading us to accept many facets of our oppression.
                  </p>
                  <p>
                  When we begin to live from within outward, in touch with the power of the erotic within ourselves, and allow that power to inform and illuminate our actions upon the world around us, we start to be responsible to ourselves in the deepest sense. By recognizing our deepest feelings, we begin to reject suffering and self-negation, moving away from the numbness that often seems like the only alternative in our society.
                  </p>
                  <p>
                  Sharing the power of each other's feelings is different from using another's feelings as we would use a Kleenex. When we look away from our experience, erotic or otherwise, we use rather than share the feelings of those who participate with us. Use without the consent of the used is abuse. When we ignore ourselves while satisfying our erotic needs with others, we use each other as objects of satisfaction instead of sharing joy and making connections based on our similarities and differences. To refuse to be conscious of what we are feeling at any time, however comfortable that might seem, is to deny a large part of the experience and allow ourselves to be reduced to the abused and the absurd.
                  </p>
                  <p>
                  Embracing Audre Lorde’s insights on the erotic calls us to a deeper understanding of ourselves and our interactions within this adult social club. Lorde reminds us that the erotic is not merely about actions but about the depth of feeling and satisfaction we experience in our pursuits. It transcends mere sexuality, encompassing spontaneity, playfulness, and the capacity for joy.
                  </p>
                  <p>
                  As we embark on this journey together, guided by Lorde’s words and the sentiments echoed by W.H. Auden, it becomes clear that the erotic is not just about individual fulfillment but also about shared connection. It forms bridges between us, allowing for understanding and appreciation of our differences.
                  </p>
                  <p>
                  Shame, by its very nature, compels us to hide from others and even from ourselves. Brené Brown explains that “shame derives its power from being unspeakable,” meaning that shame loses its power when shared. When we speak about our shame, we start to dismantle its hold over us.
                  </p>
                  <p>
                  Similarly, our society often instills a fear of our deepest desires, compelling us to suppress them. This fear can make our desires appear suspicious and overly potent because hiding any truth amplifies its strength. However, when we acknowledge and explore these desires, we can discern which ones benefit us and which ones do not.
                  </p>
                  <p>
                  By embracing the opportunity to explore your desires with an open mind, adaptability, and flexibility, you create a path toward pleasure, joy, personal growth, and self-discovery. Acknowledging these desires allows for a happier, healthier, more balanced life, where you can retain what enhances your well-being and release what hinders it.
                  </p>
                  <p>
                  Crucially, we must approach our interactions with intention, awareness, and consent, understanding that using others for our own satisfaction is a form of abuse. Instead, we must strive to share in each other's joy and connection, recognizing and respecting the feelings and experiences of all involved.
                  </p>
                  <p>
                  As we navigate this space together, let us honor the wisdom of Lorde and Auden, cultivating a culture of mutual respect, authenticity, and empowerment. In doing so, we not only enrich our own lives but contribute to creating a social club founded on genuine connection and shared humanity.
                  </p>
                  <p>
                    <strong>Membership Expectations</strong>
                  </p>
                  <ul>
                    <li>
                    If the expression of nudity, alternative sexualities, non-traditional relationships, or erotic content of any nature offends you, or you believe that there is a potential for you to be offended, we kindly ask that you refrain from registering and instead respectfully leave this site.
                    </li>
                    <li>
                    Given that we still live in a society where eroticism is often taboo, we need to be mindful of this reality as we pursue our mission. Let’s work together to safeguard our social club and ensure
                    that everyone feels respected and supported in their journey towards sexual health and pleasure.
                    </li>
                    <li>
                    These interaction guidelines should be observed in conjunction with our Terms of Service. If you notice any member violating these guidelines, please report them to us promptly. We cannot keep our social club safer if we are unaware of issues, so please let us know.
                    </li>
                  </ul>
                  <p>
                    <strong>Consequences of Violation</strong>
                  </p>
                  <p>
                  We take the violation of our interaction guidelines seriously. Depending on the severity of the infraction and the member's past behavior, consequences may range from account termination to legal action.
                  </p>
                  <p>
                    <strong>Behavioral Principles</strong>
                  </p>
                  <p>
                  At Shap, we prioritize providing a non-judgmental, and pressure-free environment where members can freely be themselves without fear of discrimination, prejudice, or harm. We encourage members to be respectful and mindful of each other as they interact and use our Service.
                  </p>
                  <p>
                  We need to consider the motivations, feelings, and thought processes behind our behavior. Only when we can turn the mirror to ourselves and dig deep into understanding ourselves can we fully show up.
                  </p>
                  <p>
                    We adhere to the six principles of sexual health: Consent, Non-exploitation, Protection from STIs and unintended pregnancy, Honesty, Shared values and Mutual Pleasure.
                  </p>
                  <p>
                    <strong>Guiding Principles</strong>
                  </p>
                  <p>
                    Our interactions will be guided by BRAVING (an acronym for Building Trust) by Brené Brown.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Boundaries</strong></em>
                  </p>
                  <p>
                  Setting boundaries means making clear what's okay and what's not okay. You must be clear about your boundaries so others can understand and respect your limits. It's also important to understand the boundaries of others so trust can flow both ways.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Reliability</strong></em>
                  </p>
                  <p>
                  You do what you say you'll do. This means staying aware of your competencies and limitations, so you don't over promise and can deliver on commitments while balancing competing priorities. Enter and exit your engagements with careful thought and attention to the needs and feelings of everyone concerned. No ghosting (leaving people on read); instead, communicate openly and conclude matters respectfully.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Accountability</strong></em>
                  </p>
                  <p>
                  You trust people who own up to their mistakes, apologize, and make amends. But others can only do that if you allow them. If you immediately write someone off when they make a mistake or stop talking to them, you don’t give them a chance to step into their accountability. This goes both ways. When you make mistakes, you need the other person to allow you to acknowledge your mistakes, apologize, and make amends.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Vault</strong></em>
                  </p>
                  <p>
                  You don’t share information or experiences that are not yours to share. You need to know that your confidences are kept while also not sharing with others any information about other people. Confidentiality and privacy must be honored. Keep all information shared within the social club private.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Integrity</strong></em>
                  </p>
                  <p>
                  Integrity involves choosing courage over comfort and consistently doing what is right, even when it's difficult. Doing the right thing means aiming at a good result for all concerned — and being willing to change course as you learn more about the situation.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Non-Judgment</strong></em>
                  </p>
                  <p>
                  You can ask for what you need, and others can ask for what they need. You can talk about how you feel without judgment. This also includes being comfortable and willing to have open and sometimes uncomfortable conversations. It's important to remember that what feels good for you might not feel good for someone else. Everyone's interests, desires, and sources of satisfaction are different. Different is simply different, not better or worse.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Generosity</strong></em>
                  </p>
                  <p>
                  Extending the most generous interpretation to the intentions, words, and actions of others. This involves approaching situations with empathy, compassion, and understanding and making the kindest assumption about a person's intentions or behaviors, even in ambiguous or challenging circumstances.
                  </p>
                  <p>
                    In addition to this, you shall embody GGG by Dan Savage:
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Good</strong></em> means you put in effort and are present, responsive, communicative and receptive to input.
                  </p>
                  <p>
                  <em><strong style={{color:'#0ac5bf',}}>Gift of pleasure</strong></em>
                  </p>
                  <p>
                  Give pleasure without the expectation of an immediate return. Find joy in the happiness of others. We are creating a social club of generous, skilled givers and enthusiastic, delighted receivers.
                  </p>
                  <p>
                <em><strong style={{color:'#0ac5bf',}}>Game for anything within reason</strong></em>
                  </p>
                  <p>
                  Be willing to explore things that do not immediately turn you on, are new to you, or you are neutral about. But don’t do things that you know turn you off. Know your soft and hard limits and express them. Respect and uphold other people's limits. If something is not a solid YES or a solid NO, examining your uncertainty and considering what you can learn from it is healthy. Are you feeling shame? Is it hard to ask for? Or perhaps you need to do a little more homework! No matter what, there’s a lot of magic in the maybes - explore your maybes and see where that takes you.
                  </p>
                  <p>
                    <strong>Interaction Guide</strong>
                  </p>
                  <ol>
                    <li>
                      Fill out your profile when you are relaxed and feeling good about yourself. Be as specific and accurate as possible, ensuring the information you share is upfront and straightforward.
                      Avoid concealing any details that could influence someone’s decision. Let’s be honest and clear about our intentions, so we can give others the opportunity to make informed
                      decisions.
                    </li>
                    <li>
                      Read through members’ profiles carefully before requesting to connect. Only send connection requests to members who match your interests, needs, and desires.
                    </li>
                    <li>
                      Prioritize approaching and interacting with other members first and foremost as fellow human beings. Approach each interaction without internal biases, preconceived notions, and
                      stereotypes.
                    </li>
                    <li>
                      If you do not want to receive connection requests and would like to make the first move, ensure you fill in that profile field.
                    </li>
                    <li>
                      Pushy and unwelcome advances are not acceptable. Do not send any unsolicited messages of a sexual nature, and use SFW language until you have consent to use NSFW language.
                      Do not repeatedly contact someone who has expressed that they no longer want to communicate with you.
                    </li>
                    <li>
                      If someone isn’t interested in you or if you're not interested in another person, a polite ‘no thank you’ is sufficient and should be respected. Rejection is part of the process and should
                      be expected.
                    </li>
                    <li>
                      Do no harm to yourself or others. It is important to understand the difference between hurt and harm: hurt is temporary and often done for pleasure, while harm is lasting and done out
                      of malice or as a means of coping. You may personally give another person explicit prior permission to hurt you. To hurt someone is to cause pain that goes away on its own. Hurt might
                      need extra attention, like a band-aid, an ice pack, or some comforting snuggles, but it doesn't require professional services. To harm someone is to cause pain that does not go away on its
                      own. Harm needs professional assistance before it can heal.
                    </li>
                    <li>
                      If someone makes you uncomfortable, for any reason, let them know. If they persist, block and report them to us.
                    </li>
                    <li>
                      Do not yuck on anyone's yum. Do not judge others for how they choose to express and enjoy themselves. Desires are varied and exploration takes many shapes. We are all wired
                      differently to give, receive, and experience pleasure.
                    </li>
                    <li>
                      We encourage you to explore, but everyone has the right to do so at their own pace. Never pressure, force, coerce, or manipulate anyone to engage in anything.
                    </li>
                    <li>
                      Pleasure is the measure. It’s whether or not you like the activity you are engaging in, whether it’s genuinely pleasurable to you. Play is typified by spontaneity, joyfulness, and
                      inhibition and is done not as a means to an end but for its inherent pleasure.
                    </li>
                    <li>
                      When participating in pleasurable activities, be present and mindful. Being present-focused, intentional and nonjudgmental of ourselves and others can lead to improved experiences of
                      pleasure and joy.
                    </li>
                    <li>
                      Sex is learned behavior. While sexual instinct may be built-in, it does not come with built-in knowledge. Sexual drive might be innate, but sexual information, sexual ethics, and sexual
                      behavior are learned. Learning about sex can help you and your partners find more pleasure, comfort, and confidence. Informed consent requires a discussion of the risks involved in the
                      activity and the steps needed to reduce those risks, including study, training, technique, and practice. Be a life-long learner!
                    </li>
                    <li>
                      Fantasy is different from reality. Be realistic and manage your expectations.
                    </li>
                    <li>
                      Consent <span style={{color:'#0AC5BF',fontWeight:'bold', cursor:'pointer'}} onClick={() => window.location.assign('https://ncsfreedom.org/wp-content/uploads/2022/01/Best-Practices-for-Consent-to-Kink.pdf') }> (see best practices for consent)</span> is absolutely essential. Authentic consent emphasizes the sincerity and genuineness of the consent provided. It extends beyond mere agreement
                      and enthusiasm, ensuring that consent is given willingly, without any external or internal pressures, manipulation, or coercion.
                    </li>
                    <li>
                      Be responsible and disclose your STI status to potential partners. Practicing consent entails being honest about any potential risks you may pose. If you receive this information, it's
                      crucial not to respond negatively. STIs are common, and stigmatizing them is unwarranted. It's important to get tested regularly and practice safer sex.
                    </li>
                    <li>
                      Members’ lives outside of our social club are off-limits unless you have been explicitly invited into these parts of their lives. Do not “out” anyone as kinky, non-monogamous, or any
                      form of alt-sex to anyone. Do not use or share anyone's Personally Identifiable Information in any form or format without their consent.
                    </li>
                    <li>
                      Conflict resolution should be approached with empathy, patience, and understanding. You will strive to resolve conflicts in a respectful manner.Be hard on issues but soft on people.
                    </li>
                    <li>
                      The profile fields are designed to facilitate your interactions rather than serve as strict labels. We encourage you to explore and experiment with different aspects. Your identity,
                      sexuality, and desires are fluid and multifaceted, so feel free to embrace new experiences and aspects of yourself as you evolve.
                    </li>
                    <li>We believe that everyone has the right to any gender identity, sexual orientation or erotic orientation, but the reality is that the world is full of places where people with non-traditional views are in danger for demonstrating their beliefs, and in some countries such people are prosecuted by law.In such countries, you need to be very careful, especially when meeting new people: there have been cases where law enforcement officers have used apps and sites to find and detain people with non-traditional views.Always check the <span style={{color:'#0AC5BF',fontWeight:'bold', cursor:'pointer'}} onClick={() => window.location.assign('https://database.ilga.org/criminalisation-consensual-same-sex-sexual-acts') }>ILGA World website</span>  regularly,as it publishes updates on sexual orientation laws by country.</li>
                    </ol>

                  <p><strong>When Connecting Online</strong></p>
                  <p> What you choose to share online is always your decision, but what others choose to do with your information may not always be in your control. Consider taking the following safety precautions: </p>
                  <ul>
                  <li>Use different photos for your profile. It’s easy to do a reverse image search with Google. If your profile has a photo that also shows up on your social media account, it will be easier for someone to find you. </li>
                  <li>Avoid sharing live or motion photos. Photos taken in “live” mode include geolocation information that can be passed on along with the photo. Exercise caution when sharing these images.</li>
                  <li>Block and report suspicious members. You can block and report another user if you feel their profile is suspicious or if they have acted inappropriately toward you. As with any personal interaction, it is always possible for people to misrepresent themselves. Trust your instincts about whether you feel someone is representing themself truthfully or not.</li>
                  <li>Personalize your privacy settings. Adjust your privacy settings on the site to your comfort level, and select options that limit who can view your information. </li>
                  <li>Pause before you post. Before you post, ask yourself if you are comfortable sharing this information with everyone who might see it.It’s a safer bet to always assume that anything you share online could be used maliciously.  </li>
                  <li>Look before you click. If you get a link, it’s best not to automatically click it.Verify that the link is secure by looking for “https” at the beginning of the URL and that its relevant to the topic of discussion.</li>
                  <li>Guard your personal information. Be wary if a new connection asks you to communicate off of the site and don’t let anyone rush you into making any sort of decision.</li>
                 </ul>
                 <p><strong>When Meeting in Person</strong></p>
                 <ul>
                  <li>Meet people you are interested in at our meetups first, or video chat with them before meeting up in-person for the first time.</li>
                  <li>Log your dates. Share the details of where you're going and who you're meeting with us, a trusted friend or family member , so as to check in with you.</li>
                  <li>Meet in reputable establishments. Use your own form of transportation to and from your meet up.</li>
                  <li>Stick to what you’re most comfortable with. There’s nothing wrong with having a few drinks. Try to keep your limits in mind and do not feel pressured to drink just because your date is drinking. It can also be a good idea to avoid taking drugs before or during a date. </li>
                  <li>For those engaging in erotic activity, practice pre-care and aftercare. Discuss with all involved and agree on what you are going to do, your limits, safewords, and care plan.See sample <span style={{color:'#0AC5BF',fontWeight:'bold', cursor:'pointer'}} onClick={() => window.location.assign('https://ncsfreedom.org/wp-content/uploads/2023/06/Negotiation-Guide.pdf') }>negotiation guide</span>  </li>
                  </ul>
                   <p> <strong>Media</strong></p>
                  
                  <p>
                    You must not post images or videos that:
                  </p>
                  <ul>
                    <li>Contain another person on your profile and cover,with the exception of couple or group profiles</li>
                    <li>Contain any form of contact information.</li>
                    <li>Display minors (children) in any photograph or video. A minor is defined as anyone appearing to be under the age of 18 years.</li>
                    <li>
                      Feature close-up pictures solely of genitalia. Tasteful nude poses are acceptable only within NSFW zones and in your albums . Other explicit media must be
                      confined to NSFW zones exclusively. If you choose to post nudes, ensure your face and other identifying features, such as tattoos or marks, are covered.
                    </li>
                  </ul>
                  <p>
                    <strong>Account safety</strong>
                  </p>
                  <ul>
                    <li>You must always operate your account safely and securely.</li>
                    <li>
                      Get a new email account specifically for use on our Service. It may be preferable to use the same email provider to avoid any apparent changes. If this isn’t a concern, consider using
                      Protonmail.
                    </li>
                    <li>
                      Choose a username that you've never used elsewhere. Avoid any references to your name or nickname that could potentially trace back to you. Remember, usernames may be used in
                      real-life interactions, so choose wisely. Once a username is selected, it cannot be altered.
                    </li>
                  </ul>
                  <p>
                    * Never reuse passwords or use a password from another website. Change your password frequently.
                  </p>
                  <p>
                    * Consider using the Brave browser, which offers an option to lock private tabs. If this isn’t feasible, ensure to regularly delete all temporary files, cookies, history, forms, and passwords
                    after each session. Though it may be tedious, it’s crucial.
                  </p>
                  <p>
                    * Never share your login details or allow anyone else access to your account. Avoid any actions that may compromise the security of your account.
                  </p>
                  <p><strong>Financial Safety</strong></p>
                  <p>
                    You must refrain from sending money or disclosing your financial information to any individual you've communicated with or met through our Service, even if they claim to be facing an
                    emergency. If anyone attempts to solicit money or acquire your financial details, please report them to us immediately.
                  </p>
                  <p><strong>Solicitation</strong></p>
                  <p>
                    Solicitation or involvement in prostitution of any kind is strictly prohibited. There shall be no exchange of money or gifts between members for sex, play, companionship, or any other
                    interaction.
                  </p>
                  <p><strong>Reporting abuse or violation of our Guidelines</strong></p>
                  <p>
                    You may report any abuse or violation of our Guidelines by emailing report@shap.buzz or utilizing the report button on someone's profile.
                  </p>
                  <p><strong>Disclaimer</strong></p>
                  <p>
                    This Service provides and hosts information intended to be correct and up-to-date, though it is not guaranteed. Shap does not assume responsibility for actions or non-actions taken by
                    individuals who visit this website, and no one shall be entitled to claim detrimental reliance on any information provided or expressed.
                  </p>
                  <p>
                    Our Guidelines are continuously evolving, and we value your suggestions. You can share your ideas with us at suggestions@shap.buzz.
                  </p>
                  <p>
                  In the pursuit of safer and more pleasurable experiences, your cooperation and adherence to these guidelines will help create an environment that is both respectful and enjoyable. We entrust you with contributing not only to an inviting and friendly atmosphere but also to a space where we can explore and express ourselves safely and discreetly.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          </>
}
export const Terms = () => {
  return (<>
    <ScrollToTop/>
    <Container className="pt-4">
      <Row>
        <Col>
          <div className="text-justify">
          <h2 style={{fontStyle:'italic',fontWeight:'bold',color: '#0ac5bf'}}>Terms of Service</h2>
            <p>
      				By your use of the website and services, you acknowledge that you have familiarized with, understood and agree to be legally bound by these Terms of Service.
      			</p>
                  <h2 style={{fontWeight:'bold'}}>
      				1. Definition
      			</h2>
                  <p>
      				In this Agreement, the following terms shall have the following meanings:
      			</p>
            <p><b>"Account":</b> means collectively the personal information, payment information and credentials used by Members to access Verified Member Content, Member Content, Member Profiles and/or any System on the Service.</p>
            <p><b>"Content":</b> means any text, graphics, images, audio, video, software, data compilations and any other form of information capable of being store in a computer that appears on or forms part of this website.</p>
            <p><b>"Member Content":</b> means any content submitted to the Service by members and accessible only upon the creation of an Account and the payment of Membership Fees</p>
            <p><b>"Membership fee":</b> means the sum of money paid by Members at per month to keep their account active and enable them to be Member as detailed on the Service.</p>
            <p><b>"Membership period":</b> means the period for which a Membership has been purchased.</p>
            <p><b>"Member Profile":</b> means the profile created by a Member on the Service.</p>
            <p><b>"Verified Member Content":</b> means Content accessible only upon the verification of an Account</p>
            <p><b>"Service":</b> means Shap providing Members a Social Club (a platform for members to socialize, connect, engage in activities, and build a sense of community both Online and In-Person) either now or in the future.</p>
            <p><b>"System":</b> means any online communications infrastructure that Shap makes available through the Service either now or in the future. This includes, but is not limited to, web-based email; forums, live chat facilities and email links.</p>
            <p><b>"User/Members/You":</b> means any third party or Member that accesses the Service.</p>
            <p><b>"Website":</b> means the Website that your are currently using shap.buzz</p>
            <p><b>"We/Us/Our":</b> means Milu Tilleli Limited trading as Shap.</p>
            <p>
      				<h2>2. Account</h2>
      			</p>
                  <p>
      				1. An Account is require to use parts of Our Service.
      			</p>
                  <p>
      				2. You may not create an Account if you are under 25 years of age. If we discover or have any reason to suspect that you are not over 25 years of age , then We reserse Our right to suspend or terminate your membership and terminate your Account immediately and without notice. To attend our events you are still required to be 25 years or older.</p>
      			
                  <p>
      				3. In order to create an account and use the Service, you must meet the following additional requirements:
      			</p>
            <p> a. You are able to form a legally binding contract . </p>
             <p> b. You have never been convicted of a violent or sexually related criminal or civil offence in any country and/or are not required to register as a sex offender with any government entity, and </p>
              <p> c. You have never been banned from using this Service.</p>
              <p>
      				4. When creating an Account, the information you provide must be accurate, truthful and complete. If any of your information changes at a later date, it is your responsibility to ensure that your Account is kept up-to-date.
      			</p>
            <p>
      				5. You must not share your account with anyone else. If you believe your Account is being used without your permission, please contact Us immediately. We will not be liable for any unauthorised use of your Account.
      			</p>
            <p>
      				6. You must not use anyone else's Account. Members are fully responsible for all activities that occur under their username and password.
      			</p>
                  <p>
      				7. You must not share your Account details with anyone. Accounts are not transferable. Any account you create is to be used exclusively by and for yourself, and should be a truthful and accurate representation of yourself.
      			</p>
                  <p>
      				8. If you wish to close and delete your Account. You may do so at any time by email (subscriptions@shap.buzz) or deleting from your account. Closing your Account will result in the removal of your information from Our System. Once you close your Account you will not be able to access your information or Account details again. If you have an active Membership, your Account will remain active for the duration of the remainder of the Membership Period.
      			</p>
                  <p>
      				<h2>3. Payment</h2>
      			</p>
                  <p>
      				1. Payment for Membership will be due at the time of purchase. Ensure that you only make payment for your own account.
      			</p>
                  <p>
      				2. Member subscription payment can be made in USD. Payments may be subject to a fee. 
      			</p>
                  <p>
      				<h2>4. Membership Rules and Disclaimers</h2>
      			</p>
                  <p>
      				Please note that any Member subscription paid by you is subject to a minimum contractual period of one month.
      			</p>
            <p>
              1. You may apply to become a Member of our Social Club by completing the Registration Form and agreeing to these Terms of Service, our Privacy Policy, Registration Requirements and Interaction Guidelines.
            </p>
            <p> a. You agree that other notices and policies found elsewhere on the Service (e.g. "group rules", "forum guidelines", "event policy", "listing rules", etc) are hereby incorporated into this agreement.
            </p>
            <p>
              2. The details that You provide and confirm in the Registration Form must be complete and correct, including Your confirmation that You are 25 years or over.
            </p>
            <p>
              3. You will become a Member only if and when You have paid the Membership Fee for Your Membership.
            </p>
            <p>
              4. We reserve the right to cancel, withdraw, or refuse to renew Your Membership, or suspend it for a specific period, ban entirely or take legal action, if Your conduct in Our reasonable opinion is unacceptable, or is or may be in Our reasonable opinion, harmful to the reputation of Us, the Website, Our Members, or amounts to Your breach of these Terms of Service or Our other policies and guidelines, or where in Our reasonable opinion such expulsion, withdrawal, refusal to renew Your Membership or legal action, is otherwise in the interests of the other Members of the Service. For more details, please refer to Our Acceptable Use Policy.
            </p>
            <p>
              5. It is your responsibility to ensure that you are happy with the information and media that you add to the Website when you sign up for an Account and when you create a Member Profile. We recommend that you do not disclose any personal details about yourself such as telephone numbers, home address, or email address to any other Members on our Service. We are not liable for your disclosure of personal information to any Members through Our Service. You are disclosing any such information at your own risk. We are not responsible for any media that you post on the Website.
            </p>
            <p>
              6. We request that you communicate only in English through our Website.
            </p>
            <p>
              7. We request that you treat other Members with respect and dignity.
            </p>
            <p>
              8. We will not be liable for the behavior of you and/or any other Members on our Website and/or at our events. You are urged at all times to take care and be sensible in your interactions with other Members,  in particular as regards to your safety and erotic activity.
            </p>
            <p>
              9. Shap does not conduct background checks or verification of member details. However, Shap reserves the right to verify member details at any time using available public records and self-submitted details. Shap will not be liable for any loss you suffer should any member identities be false or incorrect. By agreeing to these terms, you hereby authorize Shap to verify your details.
            </p>
            <p>
              10. We may organize special events or post events hosted by other members for our Members to attend. You must ensure that you are responsible for your own safety at events. We are not responsible for your safety at any events you attend and you attend any such events at your own risk.
            </p>
            <p>
              11. You agree that your Member Profile will be viewed by other Members on the Website.
            </p>
            <p>
              12. You are solely responsible for all communications and interactions with other Members through the Website and outside of the Website.
            </p>
            <p> a. Your use of the Service, whether publishing or viewing anything thereon, as well as any communications and/or interactions you have with others, is entirely voluntary; you can stop your use of the Service at any time, and there is nothing forcing you to participate.
              </p>
              <p> b. If you or anyone else using the Service is in any way damaged by anything arising from your use of the Service, with such damage(s) being financial, psychological, physical, or otherwise you agree to indemnify Shap, as well as all officers, directors, employees, representatives and agents, and hold them harmless from any such damage(s).
                </p>
            <p>
              13. We do not endorse or support any Content uploaded or opinions expressed by our Members on the Service.
            </p>
            <p> 
              14. We ask that all Content you upload to Our Service and communications that you make to Members are civil, tasteful, constructive and polite.
            </p>
            <p>
             15. You agree not to disclose any information about Our Members, employees, guests, participants or performers to third parties including the media.
           </p>
            <p>
            16. You agree not to use any photography or video recordings at our website and events.
           </p>
            <p>
             17. We will not tolerate any disruptive, offensive, abusive behaviour on our Website and/or at any of Our events.
           </p>
            <p>
            18. We are not responsible for the contraction of sexually transmitted diseases, and encourage the practice of safer sex at all times.
           </p>
           <p>
            19. We will not be held responsible for loss or damage to any property/luggage at any time. We accept no responsibility for lost/stolen items at any time and left/lost property. Items found will be displayed on the website and held for a maximum of 14 days before being disposed.
           </p>
           <p>
            20. If you are attending any of our events, please drink responsibly at all times. If We feel your drinking is a cause for concern, you may be asked to refrain from consuming further alcohol or leave the premises at any events we are holding. Please do not be offended by this and You will not be offered a refund for your ticket.
          </p>
          <p>
            21. No Member, employee, guest, participant or performer shall use, ingest, possess or distribute drugs while attending any of Our events. Anyone breaking this rule will be asked to leave the event without exception.
          </p>
          <p>
            22. We reserve the right to alter, cancel, at any time, any offers, promotions, member privileges or events.
          </p>
          
            <p>
            <h2> 5. Membership and Cancellation</h2>
            </p>
            <p>
            1. Your payment method will be billed on activation and for an additional equivalent period thereafter until you cancel your recurring Membership.
            </p>
            <p>
            2. Your payment will be at the price shown on the checkout page. Shap reserves the right to change Membership Fees from time to time and any such changes may affect your Membership rate:
            </p>
            <p>
            a. Increases in price will be reflected in your Membership on the date of the immediately subsequent renewal; and
            </p>
            <p>
            b. Decreases in price will be reflected in your Membership on the date of the immediately subsequent renewal.
            </p>
            <p>
            3. No part of this Service constitutes a contractual offer capable of acceptance. Your order for a Membership constitutes a contractual offer that We may, at Our sole discretion, accept. Our acceptance is indicated by Us confirming your Membership payment. Only once We have sent you a payment confirmation, will there be a binding contract between Shap and you.
            </p>
            <p>
            4. You may cancel at any time after subscribing by emailing subscriptions@shap.buzz or through your account page, however subject to sub-Clause 5.7, no refunds can be provided and you will continue to have access to the Member Content for the remainder of the Membership Period you are currently in up until the renewal date whereupon access will cease unless you choose to pay the Membership Fee and reactivate your Membership.
            </p>
            <p>
            5. Provision of and access to the system shall commence immediately upon Our confirmation of your Membership. By agreeing to these terms, You acknowledge that you wish the Membership to be available immediately and that by doing so, You will lose your statutory right to cancel your contract with Shap as detailed below in sub-Clause 5.6.
            </p>
            <p>
            6. You may cancel at any time after subscribing, however subject to sub-Clause 5.7, no refunds can be provided and you will continue to have access to the Member Content for the remainder of the Membership Period you are currently in up until the renewal date whereupon access will cease unless you choose to pay the Membership Fee and reactivate your Membership.
            </p>
            <p>
            7. If you subscribe in error you must inform Us within 3 days of subscribing and must not use the Website during that time. This provision also applies to automatic renewals of Memberships which you may have forgotten to cancel. If any use of Website during this time period can be traced to your Account, however, no refund can be provided and you will continue to have access to the Website for the duration of the relevant Membership Period.
            </p>
            <p>
            8. Fees around events are subject to separate terms outlined in each Event Terms
            </p>


            <h2> 6. Termination of Accounts and Membership </h2>

            <p>
              1. Either Shap or You may terminate your Account and (where relevant) your Membership. If We terminate your Account or Membership, you will be notified by email and an explanation for the termination will be provided. Notwithstanding the foregoing, We reserve the right to terminate without giving reasons. </p>
            <p> 2. If We terminate your Account as a result of your breach of these Terms of Service, our Privacy Policy, Interaction Guidelines, Registration Requirements and/or Events Policy you will not be entitled to any refund. </p>
            <p> 3. If We terminate your Account or Membership for any other reason, you will be refunded any remaining balance of your Membership Fee. Such a refund will be calculated based upon the Membership Fee being divided by the number of days in the applicable Membership Period and multiplied by the number of days remaining until the end of the Membership Period. </p>
            <p> 4. If We terminate your Account or Membership, you will cease to have access to Member Content and Member Profiles from the date of termination.</p>
            <p> 5. If you terminate your Account or Membership, you will continue to have access to Member Content and Member Profiles for the remainder of your Membership Period. </p>

            <h2> 7. Intellectual Property</h2>
            <p>
              1. All Content included on the Website, unless uploaded by Members, including but not limited to, text, graphics, logos, icons, images, sound clips, video clips, data compilations, page layout, underlying code, and software is the property of Shap, or Our affiliates. By continuing to use the Website, you acknowledge that such material is protected by applicable intellectual property and other laws.
            </p>
            <p>
              2. Your use of the Website grants no rights to you in relation to copyright, trademarks, or other of Our intellectual property rights or the intellectual property rights of third parties.
            </p>
            <p>
              3. You may not, without limitation, copy, reproduce, republish, download, post, broadcast, record, transmit, commercially exploit, communicate to the public, or otherwise use the Content included in or provided via the Service.
            </p>
            <p>
              4. You may not monitor, data-mine, or copy Our Website page, any content on the Website, nor collect, archive, trade, or sell any personal data or communications about the Members.
            </p>
            <p>
              5. You must not use any robot, spider, site search or retrieval application, or any other method or device to copy, retrieve, archive, or index any portion of the Website or the Service or the System.
            </p>
            <p>
              6. You must not distribute, display, publish, print in any form electronic or otherwise any Content from the Website, the Service, the System, Member Profiles, the names of any Members, and/or any personal details of Members. You must not share, transmit, or sell any Content on Our Service with anyone who is not a Member.
            </p>

            <h2> 8. Content</h2>
            
            <b>  Use of Verified Member Content </b>
            <p>
              1. Verification grants you a license to use all Verified Member Content on the Service for the duration of your Membership. You may use such Content for personal purposes only.
            </p>
            <p>
              2. Commercial use of Verified Member Content is not permitted.
            </p>
            <p>
              3. Under such a license, you agree that:
            </p>
            <ul>
              <li>you will not use the Verfied Member Content of the Service for commercial purposes; and</li>
              <li>you will not systematically copy the Verified Member Content from the Service with a view to creating or compiling any form of comprehensive collection, compilation, directory, or database.</li>
            </ul>
            <b> Member Content </b>

            <p> Payment of a Membership Fee grants you a license to use all Member Content on the Service for the duration of your Membership. You may use such Content for personal purposes only.</p>
            <p> 4. You agree that you will be solely responsible for your Member Content. Specifically, you agree, represent and warrant that you have the right to submit the Member Content and that all such Member Content will comply with Our Acceptable Usage Policy, detailed below in Clause 12.</p>
            <p> 5. You agree that you will be liable to Us and will, to the fullest extent permissible by law, indemnify Us for any breach of the warranties given by you under sub-Clause 8.4. You will be responsible for any loss or damage suffered by Us as a result of such breach.</p>
            <p> 6. If you wish to remove Content, you may do so. Your Content will be deleted. Please note that caching or references to your Content may not be made immediately unavailable (or may not be made unavailable at all where they are outside of Our reasonable control).</p>
            <p> 7. We may reject, reclassify, or remove any Content submitted to Our Service where, in Our sole opinion, the content violates Our Acceptable Usage Policy, or if We receive a complaint from a third party and determine that the Content in question should be removed as a result. For more details, please refer to our Acceptable Use Policy.</p>
            <p> 8. We may at our discretion, and without notice, access your account to review content even those which are unavailable to other members to check for compliance with the standards stated herein and take appropriate action.</p>

            <h2>9. Availability of the Service and Modifications</h2>
            <p>
              1. We accept no liability for any disruption or non-availability of the Service resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, power failure, natural events, acts of war, or legal restrictions and state-mandated censorship.
            </p>
            <p>
              2. We reserve the right to modify, suspend, or discontinue any part of the Service, System, or Website, or the entirety of them. These Terms of Service will remain in effect for any modified version of the Service, System, or Website unless explicitly stated otherwise.
            </p>

            <h2>10. Limitation of Liability</h2>
            <p>
              1. To the fullest extent permissible by law, We accept no liability to any user for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) the Service or the use of or reliance upon any content included on the Service.
            </p>
            <p>
              2. To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to the Service or any content included on the Service.
            </p>
            <p>
              3. We exercise all reasonable skill and care to ensure that the Website is free from viruses and other malware. Subject to sub-Clause 10.5, We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of Website attack, or other harmful material or event that may adversely affect your hardware, software, data, or other material that occurs as a result of your use of the Website or any other site referred to on the Website.
            </p>
            <p>
              4. We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of the Service resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.
            </p>
            <p>
              5. Nothing in these Terms of Service excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law.
            </p>
            <p>
              6. We neither assume nor accept responsibility or liability arising out of the hacking or similar of the Service.
            </p>
            <p>
              7. In the event that any of these terms are found to be unlawful, invalid, or otherwise unenforceable, that term is to be deemed severed from the rest of these Terms of Service and shall not affect the validity and enforceability of the remaining terms.
            </p>

            <h2>11. Advertising</h2>
            <p>
              1. We may feature advertising within the Service.
            </p>
            <p>
              2. You agree that you will not attempt to remove or hide any advertising using HTML/CSS or by any other method.
            </p>
            <p>
              3. We are not responsible for the content of any advertising in the Service. Each advertiser is responsible for the content of their own advertising material. We will not be responsible for any advertising in the Website including, but not limited to, any errors,

 inaccuracies, or omissions.
            </p>

            <h2>12. Privacy</h2>
            <p>
              1. Use of the Website is also governed by Our <a href="/privacyPolicy">Privacy Policy</a> which is incorporated into these Terms of Service by this reference. To view the Privacy Policy, please click on the link above.
            </p>

            <h2>How We Use Your Personal Information (Data Protection)</h2>
            <p>
              1. All personal information that We may collect will be collected, used, and held in accordance with Our <a href="/privacyPolicy">Privacy Policy</a> which is incorporated into these Terms of Service by this reference. To view the Privacy Policy, please click on the link above.
            </p>

            <h2>13. Acceptable Usage Policy</h2>
            <p>
              1. You may only use Our Service in a manner that is lawful and that complies with the provisions of this Clause 13. Specifically:
            </p>
            <ul>
              <li>You must be aged 25 or over to use Our Service;</li>
              <li>you must ensure that you comply fully with any and all local, national or international laws and/or regulations;</li>
              <li>You must not use Our Service in any way, or for any purpose, that is unlawful or fraudulent;</li>
              <li>You must not use Our Service to knowingly transmit data, send, or upload any material that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind; and</li>
              <li>You must not use Our Service in any way, or for any purpose, that is intended to harm any person or persons in any way.</li>
            </ul>
            <p>
              2. When submitting Member Content (or communicating in any other way using Our Website) or using Our Service or System, you must not submit, communicate or otherwise do anything that:
            </p>
            <ul>
              <li>is obscene, deliberately offensive, hateful or otherwise inflammatory;</li>
              <li>promotes violence;</li>
              <li>promotes or assists in any form of unlawful activity;</li>
              <li>discriminates against, or is in any way defamatory of, any person, group, or class of persons, race, gender, religion, nationality, disability, sexual orientation, or age;</li>
              <li>is intended or otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</li>
              <li>is calculated or is otherwise likely to deceive;</li>
              <li>is intended or otherwise likely to infringe (or threaten to infringe) another person's right to privacy;</li>
              <li>misleadingly impersonates any person or otherwise misrepresents your identity or affiliation in a way that is calculated to deceive (obvious parodies are not included within this definition provided that they do not fall within any of the other provisions of this sub-Clause 12.2);</li>
              <li>implies any form of affiliation with Us where none exists;</li>
              <li>infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, patents, trade marks and database rights) of any other party;</li>
              <li>is in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence;</li>
              <li>is unlawful or otherwise objectionable;</li>
              <li>is any form of commercial advertising;</li>
              <li>promotes or solicits for any competing business to Shap;</li>
              <li>impersonates other people, particularly employees and representatives of Shap or Our affiliates; and</li>
              <li>involves using Our System for unauthorised mass-communication such as "spam" or "junk mail".</li>
            </ul>

            <h2>14. Legal Rights and Disclaimers</h2>
            <p>
              1. We make no warranty or representation that the Website will be compatible with all systems, or that it will be secure.
            </p>
            <p>
              2. Whilst We exercise all reasonable skill and care to ensure that the Website is secure and free of errors, viruses, and other malware, you are strongly advised to take responsibility for your own internet security, that of your personal details and your gadgets.
            </p>
            <p> 3. In the event that any party to these Terms of Service fails to exercise any right or remedy contained herein, this shall not be construed as a waiver of that right or remedy.</p>
            <p> 4. In the event of any conflict between these Terms of Service and any prior versions thereof, the provisions of these Terms of Service shall prevail unless it is expressly stated otherwise.</p>
            <p> 5. Nothing in these Terms and Conditions shall confer any rights upon any third party. The agreement created by these Terms of Service is between you and Shap.</p>

            <h2>15. Links to Other Websites</h2>
            <p>
              This Website may contain links to other sites. Unless expressly stated, these sites are not under the control of Shap or that of Our affiliates. We assume no responsibility for the content of such websites and disclaim liability for any and all forms of loss or damage arising out of the use of them. The inclusion of a link to another site on this Website does not imply any endorsement of the sites themselves or of those in control of them.
            </p>

            <h2>16. Links to this Website</h2>
            <p>
              Those wishing to place a link to this Website on other sites may do so with Our prior permission. To find out more please contact Us by email at discover@shap.buzz.
            </p>

            <h2>17. Communications</h2>
            <p>
              1. All notices/communications shall be given to Us either by email to subscriptions@shap.buzz. Such notice will be deemed received the day of sending if the email is received in full on a business day and on the next business day if the email is sent on a weekend or public holiday.
            </p>
            <p>
              2. We may from time to time, if you opt to receive it, send you information about Our products and/or services. If you do not wish to receive such information, please click the unsubscribe link.
            </p>

            <h2>18. Law and Jurisdiction</h2>
            <p>
              1. These Terms of Service, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with, Kenyan law.
            </p>
            <p>
              2. Any disputes concerning these Terms of Service, the relationship between you and us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be resolved through consultation held in good faith between you and us. Such consultation shall begin immediately after one party has delivered to the other a written request for consultation. If, within fifteen (15) days following the date on which such notice is given, the claim cannot be resolved amicably, the claim shall be submitted for mediation.
            </p>
            <p>3. If a dispute, controversy, or claim cannot be settled through consultation, the parties shall refer the dispute to a mediator agreed upon by both parties. If, within fifteen (15) days of one party requesting mediation, the parties do not agree on a mediator or fail to settle the dispute through mediation, the provisions of the following paragraph shall apply.
            </p>
            <p> 4. Should any claim arise between the parties that cannot be resolved through consultation or mediation, the claim shall be referred to arbitration upon application by any party. The arbitration shall be conducted by a person acceptable to both parties, or if the parties cannot agree on the appointment of such person within thirty (30) days from the date of the application, the claim shall be referred to arbitration in accordance with the Chartered Institute of Arbitrators, Kenya Branch, as then in effect, except as modified herein. The arbitration shall be held, and the award issued, in Kenya. The arbitration proceedings shall be conducted, and the award rendered, in the English language.
            </p>
            <p> 5. The award issued pursuant to this clause shall be final and binding on the parties and shall be the sole and exclusive remedy between them regarding any claim. Judgment upon any award may be entered in any court having jurisdiction.
            </p>
            <p> 6. Notwithstanding the foregoing, a party is entitled to seek preliminary injunctive relief or interim or conservatory measures from a court of competent jurisdiction pending the final award.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </>);
}