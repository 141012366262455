import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton, ProfileListItem} from '../../components/ModularComponents';
import '../../css/profile.css';

export const Followers = () => {
    const followers = [{username:'jenni',location:'Nairobi, Kenya',gender:'female',age:25,profileType:'single'},
                         {username:'julie',location:'Nakuru, Kenya',gender:'female',age:23,profileType:'single'},
                         {username:'oscar',location:'Kiambu, Kenya',gender:'male',age:28,profileType:'single'}]

    return (
        <Container className="p-0">
            <Card style={{marginBottom:10}}>
                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Followers ({followers.length})</Card.Text>
                </Card.Header>
                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:11}}>
                    {followers.map(({username, location, gender, age, profileType}, index) => {
                        return <ProfileListItem username={username} location={location} gender={gender} isCouple={profileType==="couple"?true:false}/>
                    })}
                </Card.Body>
            </Card>
        </Container>
    );
}

