import { useContext, useCallback } from "react";
import { AuthContext } from "../context/AuthContext";
import { useLocalStorage } from "./useLocalStorage";

export let User:{
  access_token: string;
  token_type: string;
  user_id: number;
  username: string;
  avatar: string;
  location: string;
  date_of_birth: number;
  gender_at_birth: string;
  current_gender: string;
  kinks: string;
  first_move: string;
  interaction: string;
  role: string;
  verified_account: boolean;
  verified_user: boolean;
  subscription: string;  // Replace 'any' with appropriate type if known
  subscription_type: string;
  subscription_status: string;  // Replace 'any' with appropriate type if known
  created_at: number;
  last_login: number;
} = {};

export const useUser = () => {
  const {_user, _loggedIn} = useContext(AuthContext);
  const [user, setUser] = _user;
  const [loggedIn, setLoggedIn] = _loggedIn;
  const { setItem } = useLocalStorage();

  const addUser = useCallback((user: User) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  },[setItem, setUser]);

  const removeUser = useCallback(() => {
    setUser(null);
    setItem("user", "");
  },[setItem, setUser]);

  return { user, addUser, removeUser, setUser, loggedIn, setLoggedIn };
};