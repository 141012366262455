import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo.png';
import { Link, useNavigate } from "react-router-dom";
import {BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';
import {MyCheckBox} from '../components/ModularComponents';

export const Login = () => {
	const { login } = useAuth();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("")
	const [togglePassphaseView, setTogglePhraseView] = useState(false);
	const [cbState, setCbState] = useState(false);

	const handleLogin = async() => {
		// login({
		// 	id: '1',
		// 	username: 'eli',
		// 	email: 'eliezerkombe@gmail.com',
		// 	age:'32',
		// 	gender:'male',
		// 	verified:true,
		// 	joined:'June 2024',
		// 	online:true,
		// 	dob:'April 9th, 1992',
		// 	location:'Nairobi, Kenya',
		// 	kink:'Reverse gangbang'
		// });
		// navigate('/app/profile');
		//setEmail()
		// const response1 = await fetch('http://13.244.179.175/users/login',
		// 							{	
		// 								method: "POST",
		// 								headers: {'Content-Type': 'application/x-www-form-urlencoded'}, 
		// 								body: new URLSearchParams({username: email,password: password}).toString()
		// 							});
		// let res = await response1.json();
		// console.log(res);
		// login(res);
		login({"access_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJlbGkifQ.4Ihwo4-9nmGL8YuXPtVPrFM_SvUpyYLQjt6KRzHwcu0","token_type":"bearer","user_id":4,"username":"eli","avatar":"avatar_linkacb123","city":"Mombasa","country":"Kenya","date_of_birth":703382400,"gender_at_birth":"male","current_gender":"male","kinks":"Reverse Gangbang, etc","first_move":"true","interaction":"eroticism","role":"admin","verified_account":true,"verified_user":true,"subscription":null,"subscription_type":"sappire","subscription_status":null,"created_at":1720669011,"last_login":1720669011})
		navigate('/app/profile');
	};

	const screenHeight = window.innerHeight;
	const minHeight = screenHeight - 230;

	return 	<><Container style={{fontFamily:'Merriweather'}}>
			<BlackNavBar loggedIn={false} showNavBtn={false}/>
			<div style={{flex:'1',minHeight:minHeight,width:'100%',position:'relative',marginTop:80}} className="d-flex justify-content-center align-items-center">
				<Row xs="auto" md="auto" lg="auto" xxl="auto" className="d-flex justify-content-center align-items-center">
					<Card>
						<div style={{overflow:'hidden',borderRadius:35,width:70,height:70,alignSelf:'center',marginTop:15}}>
				          <img
				            src={logo}
				            width="70"
				            height="70"
				            className="d-inline-block align-top"
				            alt="Logo"
				          />
				        </div>
						<Card.Body>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">
									<i class="bi bi-envelope-at-fill"></i>
								</InputGroup.Text>
						        <Form.Control
						          placeholder="Email"
						          aria-label="Email"
						          value={email}
						          onChange={(e) => setEmail(e.target.value)}
						          aria-describedby="basic-addon1"
						        />
						    </InputGroup>
						    <InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">
									<i class="bi bi-key-fill"></i>
								</InputGroup.Text>
						        <Form.Control
						          type="password"
						          placeholder="Passphrase"
						          value={password}
						          onChange={(e) => setPassword(e.target.value)}
						          aria-label="Passphrase"
						          aria-describedby="basic-addon1"
						        />
						        <InputGroup.Text id="basic-addon1" onClick={()=>setTogglePhraseView(!togglePassphaseView)}>
									{!togglePassphaseView?<i class="bi bi-eye"></i>:<i class="bi bi-eye-slash"></i>}
								</InputGroup.Text>
						    </InputGroup>
						    <InputGroup className="mb-3">
						    	<div className="d-flex align-items-center">
									<MyCheckBox cbState={cbState} setCbState={setCbState} />
									<span style={{marginLeft:5}}>Remember me </span>
								</div>									
		        			</InputGroup>
						    <InputGroup className="d-grid gap-2" style={{marginTop:10, marginBottom:20}}>
								<Button onClick={() => handleLogin()} style={{backgroundColor:'#0AC5BF', color:'white', borderColor:'#0AC5BF'}} size="lg">Login</Button>
							</InputGroup>
							<Card.Text><Link to="/text/terms">Forgot password?</Link></Card.Text>
							<Card.Text>Don't have an account? <Link to="/signup">Register now!</Link></Card.Text>
							<Card.Text><Link to="/text/terms">Terms of services</Link> and <Link to="/text/privacyPolicy">Privacy Policy</Link></Card.Text>
						</Card.Body>
					</Card>
				</Row>
			</div>
		</Container>
		<Footer/>
		</>
}