import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton, ProfileListItem,SubGroupCard} from '../../components/ModularComponents';
import '../../css/profile.css';
import relationshipAnarchy from '../../assets/relationshipAnarchy.png';
import bdsm from '../../assets/bdsm.png';
import healthAndWellbeing from '../../assets/healthAndWellbeing.png';

export const GroupsJoined = () => {
    const groups = [{title:'Health And Wellbeing',image:healthAndWellbeing},
                    {title:'Relationship Anarchy',image:relationshipAnarchy},
                    {title:'Bdsm', image:bdsm, privacyStatus:true}];

    return (
        <Container className="p-0">
            <Card style={{marginBottom:10}}>
                <Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
                    <Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Groups Joined ({groups.length})</Card.Text>
                </Card.Header>
                <Card.Body style={{paddingLeft:20,paddingRight:20,fontSize:12}}>
                    <Row>
                        {groups.map(({title, image, privacyStatus}, index) => {
                        	return 	<Col xs={12} md={6}>
                                        <SubGroupCard label={title} podcastsImage={image} privacyStatus={privacyStatus} />
                                    </Col>
                        })}
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

