import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {useNavigate,useLocation} from 'react-router-dom';
import '../css/bottomToolbar.css'

export const BottomToolbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
    const { pathname } = location;
  return (
    <Navbar className="d-md-none" fixed="bottom" bg="dark" variant="dark">
      <Nav className="mx-auto">
        <Nav.Link class="tabLink" onClick={() => navigate('/app/discover')} style={{color:(pathname === '/app/discover'?'#0ac5bf':'')}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:14}}>
            <i className="bi bi-house"></i>
          </div>
          <div class="tabbarText" style={{fontSize:11}}>Discover</div>
        </Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/buzz')} style={{color:(pathname === '/app/buzz'?'#0ac5bf':'')}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:14}}>
            <i class="bi bi-list-columns-reverse"></i>
          </div>
          <div class="tabbarText" style={{fontSize:11}}>Buzz</div>
        </Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/groups')} style={{color:(pathname === '/app/groups'?'#0ac5bf':'')}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:14}}>
            <i className="bi bi-people"></i>
          </div>
          <div class="tabbarText" style={{fontSize:11}}>Groups</div>
        </Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/forums')} style={{color:(pathname === '/app/forums'?'#0ac5bf':'')}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:14}}>
            <i class="bi bi-chat-square-quote"></i>
          </div>
          <div class="tabbarText" style={{fontSize:11}}>Forums</div>
        </Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/members')} style={{color:(pathname === '/app/members'?'#0ac5bf':'')}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:14}}>
            <i className="bi bi-people"></i>
          </div>
          <div class="tabbarText" style={{fontSize:11}}>Members</div>
        </Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/profile')} style={{color:(pathname === '/app/profile'?'#0ac5bf':'')}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:14}}>
            <i className="bi bi-person"></i>
          </div>
          <div class="tabbarText" style={{fontSize:11}}>Profile</div>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}
