import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack,Card,Button,Row,Col,Image} from 'react-bootstrap';
import {SubGroupCard} from '../../components/ModularComponents';
import recreation from '../../assets/recreation.jpg';
import sexuality from '../../assets/sexuality.jpg';
import lifestyle from '../../assets/lifestyle.jpg';
import eroticism from '../../assets/eroticism.jpg';
import podcastsImage from '../../assets/podcastsImage.png';
import charity from '../../assets/charity.png';
import hobbiesAndCrafts from '../../assets/hobbiesAndCrafts.png';
import travel from '../../assets/travel.png';
import technology from '../../assets/technology.png';
import sports from '../../assets/sports.png';
import petsAndAnimals from '../../assets/petsAndAnimals.png';
import outdoorsAndAdventures from '../../assets/outdoorsAndAdventures.png';
import music from '../../assets/music.png';
import newAge from '../../assets/newAge.png';
import moviesComediesFilmsPlays from '../../assets/moviesComediesFilmsPlays.png';
import healthAndWellbeing from '../../assets/healthAndWellbeing.png';
import gaming from '../../assets/gaming.png';
import games from '../../assets/games.png';
import foodAndDrinks from '../../assets/foodAndDrinks.png';
import fitness from '../../assets/fitness.png';
import fashion from '../../assets/fashion.png';
import environmentAndSustainability from '../../assets/environmentAndSustainability.png';
import dance from '../../assets/dance.png';
import bookClub from '../../assets/bookClub.png';
import artAndCulture from '../../assets/artAndCulture.png';
import anime from '../../assets/anime.png';

export const Recreation = () => {

	const groups = [{title:'Podcasts/Vlogs', image:podcastsImage},
					{title:'Charity', image:charity},
					{title:'Hobbies And Crafts',image:hobbiesAndCrafts},
					{title:'Travel',image:travel},
					{title:'Technology',image:technology},
					{title:'Sports',image:sports},
					{title:'Outdoors And Adventures',image:outdoorsAndAdventures},
					{title:'Music',image:music},
					{title:'Movies/Comedies/Film/Plays',image:moviesComediesFilmsPlays},
					{title:'Health And Wellbeing',image:healthAndWellbeing},
					{title:'Gaming',image:gaming},
					{title:'Games',image:games},
					{title:'Food And Drinks',image:foodAndDrinks},
					{title:'Fitness',image:fitness},
					{title:'Fashion',image:fashion},
					{title:'Environment And Sustainability',image:environmentAndSustainability},
					{title:'dance',image:dance},
					{title:'Book Club',image:bookClub},
					{title:'Art And Culture',image:artAndCulture},
					{title:'Anime',image:anime}]

	return 	<Row className="d-flex align-items-stretch justify-content-stretch">
				{groups.map(({title, image},index) => {
					return  <Col xs={12} md={6} xl={4}>
								<SubGroupCard label={title} podcastsImage={image}/>
							</Col>
				})}
			</Row>
}

