import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {ChatInput, ChatBubble} from './compose';
import { useAuth } from '../../hooks/useAuth';

const conversation = [
	{text:'hello guys!',author:{username:'audrey'},date:1720411000},
	{text:'hello!',author:{username:'justin'},date:1720411050},
	{text:'hey!',author:{username:'jane'},date:1720411150},
	{text:'Are you guys ready for the adventure',author:{username:'audrey'},date:1720411600},
	{text:'Hey guys! Im a bit late! However ready indeed!',author:{username:'julio'},date:1720412600},
	{text:'Im waiting for you guys outside the venue. Make sure you dont forget anything important!',author:{username:'audrey'},date:1720413600},
]

export const Conversation = () => {
	const { user } = useAuth();
	const divRef = useRef(null);
	const [chatArray, setChatArray] = useState(conversation);

	const onSend = (value) => {
		//add value to chat array
		let currentTimeInSeconds = Math.floor(Date.now() / 1000);
		let sendObj = {text:value,author:user,date:currentTimeInSeconds}
		setChatArray([...chatArray, sendObj]);
	}

	useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    }, [chatArray]);

	useEffect(() => {
		console.log(JSON.stringify(chatArray));
	},[chatArray])

	return 	<Container className="pt-3">
				<div ref={divRef} className="w-100 h-100" style={{minHeight:300,maxHeight:500,width:'100%',overflow:'scroll'}}>
				{chatArray.map(({text, author, date},index) => {
					return <ChatBubble author={author} text={text} date={date}/>
				})}
				</div>
				<ChatInput onSend={onSend}/>
			</Container>
}