import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../css/footer.css';
import '../css/cover.css';

export const AltFooter = () => {
	return 	<footer className="bg-dark text-white" style={{fontFamily:'Merriweather'}}>
				<Container>
					<Row xs="auto" md="auto" lg="auto" xxl="auto" style={{marginTop:30}}>
					    <Col>
					    	<p className="p-1" style={{fontSize:13}}>&copy; Shap 2024 All right reserved</p>
					    </Col>
					    <Col>
						    <Link to="/" style={{fontSize:13,textDecoration:'none', color:'white'}}>About</Link>
						</Col>
						<Col>
						    <Link to="/text/terms" style={{fontSize:13,textDecoration:'none', color:'white'}}>Terms of Service</Link>
						</Col>
						<Col>
						    <Link to="/text/guidelines" style={{fontSize:13,textDecoration:'none', color:'white'}}>Interaction Guidelines</Link>
						</Col>
						<Col>
						    <Link to="/text/privacyPolicy" style={{fontSize:13,textDecoration:'none', color:'white'}}>Privacy Policy</Link>
						</Col>
						<Col>
						    <Link to="/text/theErotic" style={{fontSize:13,textDecoration:'none', color:'white'}}>The Erotic by Audre Lorde</Link>
						</Col>
					</Row>
				</Container>
			</footer>

}

export const Footer = () => {

	return 	<footer className="text-white" style={{backgroundColor:'black'}}>
				<Container style={{paddingLeft:30,paddingRight:30}}>
					<Row xs={1} md={1} lg={2} xxl={3}>
				        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}}>
				          <Card style={{backgroundColor:'transparent'}}>
				          	<Card.Body>
					          	<Card.Text><Link className="footerlink" to="/">Home</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/our-story">Our Story</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/text/registrationReq">Registration requirement</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/text/terms">Terms of Services</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/text/guidelines">Interaction Guidelines</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/text/privacyPolicy">Privacy Policy</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/signup">Registration Form</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/text/workWithUs">Work with us</Link></Card.Text>
					          	<Card.Text><Link className="footerlink" to="/text/theErotic">The Erotic by Audre Lorde</Link></Card.Text>						    
							</Card.Body>
				          </Card>
				        </Col>
				        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}}>
				          <Card style={{backgroundColor:'black'}}>
				          	<Card.Body>
					          	<Card.Title style={{color:'#fed700',fontWeight:'bold'}}>Contact</Card.Title>
					          	<Card.Text>
						        	<>
								      <InputGroup className="mb-3">
								       	<Form.Control
								          required
								          placeholder="Email"
								          aria-label="Email"
								          aria-describedby="basic-addon1"
								        />
								      </InputGroup>
								      <InputGroup className="mb-3">
								        <Form.Control placeholder="Subject" id="basic-url" aria-describedby="basic-addon3" />
								      </InputGroup>
								      <InputGroup>
								        <Form.Control style={{minHeight:105,resize:'none'}} placeholder="Your message..." as="textarea" aria-label="With textarea" />
								      </InputGroup>
								      <InputGroup className="d-grid gap-2" style={{marginTop:10}}>
								      	<Button className="gradient-border-button" style={{borderWidth:0, color:'#fed300', borderColor:'#fed700',backgroundColor:'transparent'}} size="lg">Send</Button>
								      </InputGroup>
								    </>
						        </Card.Text>
						    </Card.Body>
				          </Card>
				        </Col>
				    </Row>
				</Container>
				<div className="d-flex align-items-center justify-content-center" style={{marginTop:30}}>
				    <p>&copy; Shap 2024 All right reserved</p>
				</div>
      		</footer>
}